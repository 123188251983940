<template>
  <v-container fluid>
    <AdminMenu />
    <v-data-iterator :items="sections" hide-default-footer :items-per-page="100">
      <template v-slot:default="props">
        <transition name="fade" appear>
          <v-row dense>
            <v-col cols="6" sm="4" v-for="(item,idx) in props.items" :key="item.name">
              <v-card :to="{name:item.name}" :style="'transition-delay: ' + ((idx+1)/50) +'s'" v-if="(isEditor && item.rights.includes('editor')) || (isAdmin && item.rights.includes('admin'))">
                <v-img dark :src="item.image" aspect-ratio="1.5">
                  <v-row align="center" justify="center" class="fill-height translucent-bg-dark">
                    <v-col class="text-center translucent-bg-dark" cols="12">
                      <div
                        :class="$vuetify.breakpoint.smAndDown ? 'subtitle' :'title'"
                      >{{ item.title }}</div>
                    </v-col>
                  </v-row>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </transition>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import AdminMenu from "../components/AdminMenu.vue";

import { admsections } from "@/admsections";

export default {
  name: "AdminDashboard",
  mixins: [defaultMixin],
  components: {
    AdminMenu
  },
  data: () => ({
    sections: admsections
  })
};
</script>