<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{ path: $store.state.route.from.fullPath }" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1" v-if="!isnew">
          EDIT RELEASE
          <br />
        </div>
        <div class="font-weight-thin display-1" v-if="isnew">
          NEW RELEASE
          <br />
        </div>
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="release"
      schemaName="Release"
      :formData="releaseData"
      namespace="releases"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row justify="end" class="pa-2">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn class="ma-1" v-on="on">Search Stores</v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn
              :href="'https://open.spotify.com/search/' + releaseData.name + ' Buzz Bass'"
              target="searchr"
            >Spotify</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              :href="'https://music.apple.com/us/search?term=' + releaseData.name + ' Buzz Bass'"
              target="searchr"
            >Itunes</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              :href="'https://www.amazon.com/s?k='+ releaseData.name + ' Buzz Bass&i=digital-music'"
              target="searchr"
            >Amazon</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              :href="'https://play.google.com/store/search?q=' + releaseData.name + ' Buzz Bass'"
              target="searchr"
            >Play Store</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              :href="'https://bandcamp.com/search?q=' + releaseData.name + ' Buzz Bass'"
              target="searchr"
            >Band Camp</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn target="release" class="ma-1" @click="ac.copyToClipboard(getLinkTree())">
        Links
        <v-icon right>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn
        :to="{name:'release', params:{id:releaseData.id}, target:'release'}"
        target="release"
        class="ma-1"
      >
        View Release
        <v-icon right>mdi-eye</v-icon>
      </v-btn>
      <v-btn :to="{name:'admreleases'}" class="ma-1">
        Cancel
        <v-icon right>mdi-close-circle</v-icon>
      </v-btn>
      <v-btn @click="dialog=true" v-if="$route.params.id" class="ma-1">
        Delete
        <v-icon right>mdi-trash-can</v-icon>
      </v-btn>
      <v-btn @click="saveRelease(false)" class="ma-1">
        Save
        <v-icon right>mdi-content-save</v-icon>
      </v-btn>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>Release {{releaseData.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="saveRelease(true);dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases,
  mapGetters: mapGettersReleases
} = createNamespacedHelpers("releases");

const {
  mapState: mapStateTracks,
  mapActions: mapActionsTracks,
  mapGetters: mapGettersTracks
} = createNamespacedHelpers("tracks");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmRelease",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    releaseData: {
      catNo: null
    },
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaRelease: SchemasConst.Release,
    refObjects: {}
  }),
  async created() {
    await this.fetchTracks();
    this.refObjects["refTrack"] = this.tracks;
    await this.fetchReleases();
    if (this.$route.params.id) {
      const ev = await this.getReleaseById(this.$route.params.id);
      if (ev.id === undefined) {
        this.releaseData.id = this.$route.params.id;
        this.isnew = true;
        const lastRelease = await this.getLastRelease;
        console.log("get last lastRelease " +  JSON.stringify(lastRelease,null,2));
        if (lastRelease != null) {
          this.releaseData.catNo = lastRelease.catNo;
        }
      } else {
        //Object.assign( this.releaseData, this.getReleaseById(this.$route.params.id) );
        this.releaseData = this.getReleaseById(this.$route.params.id);
      }
    }
  },
  computed: {
    ...mapGettersReleases(["getReleaseById", "getLastRelease"]),
    ...mapStateReleases(["releases"]),
    ...mapStateTracks(["tracks"]),
    ...mapGettersTracks(["getTrackById"])
  },
  methods: {
    ...mapActionsTracks(["fetchTracks"]),
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsReleases(["fetchReleases", "updateRelease"]),
    getLinkTree() {
      const keys = Object.keys(this.releaseData).filter(
        n => n.includes("Link") === true && this.releaseData[n]
      );
      const ref = this;
      const links = keys.map(n => {
        return ref.ac.buildLink(n, this.releaseData[n]);
      });

      const jbbLink =
        "https://buzzbass.com/release/" +
        this.normalizeUrl(this.releaseData.name) +
        "/" +
        this.releaseData.id;

      links.unshift(jbbLink);

      return links.join("\n");
    },
    getKeyArray(id) {
      return Object.keys(this.getTrackById(id));
    },
    getvModel(name) {
      return this.releaseData[name];
    },
    uploadFiles() {
      this.schemaRelease.forEach(field => {
        if (field.itemType === "files") {
          if (this.releaseData[field.name]) {
            this.releaseData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "releases/" + this.$route.params.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveRelease(deleteRelease) {
      console.log("saveRelease");
      let cmd = this.isnew ? "CREATE" : "UPDATE";
      cmd = deleteRelease ? "DELETE" : cmd;

      const success = await this.updateRelease({
        release: this.releaseData,
        cmd: cmd
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admreleases" });
      }
    }
  }
};
</script>