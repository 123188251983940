export const admsections = [{
        title: "Manage Tracks",
        name: "admtracks",
        image: require("@/assets/imgs/tunes.jpg"),
        rights:['admin','editor']

    },
    {
        title: "Manage Releases",
        name: "admreleases",
        image: require("@/assets/imgs/releases.jpg"),
        rights:['admin','editor']
    },
    {
        title: "Campaigns",
        name: "admmailings",
        image: require("@/assets/imgs/mailings.jpg"),
        rights:['admin']
    },
    {
        title: "Demos",
        name: "admdemos",
        image: require("@/assets/imgs/demos.jpg"),
        rights:['admin','editor']
    },
    {
        title: "Submissions",
        name: "admsubmissions",
        image: require("@/assets/imgs/submissions.jpg"),
        rights:['admin','editor']
    },
    {
        title: "Stats",
        name: "admstats",
        image: require("@/assets/imgs/stats.jpg"),
        rights:['admin','editor']
    },
    {
        title: "Reviews",
        name: "admreviews",
        image: require("@/assets/imgs/reviews.jpg"),
        rights:['admin','editor'],
    },
    {
        title: "Manage Artists",
        name: "admartists",
        image: require("@/assets/imgs/artists.jpg"),
        rights:['admin','editor'],
    },
    {
        title: "Manage Users",
        name: "admusers",
        image: require("@/assets/imgs/users.png"),
        rights:['admin']
    },
    {
        title: "Edit MailingsLists",
        name: "admmailinglists",
        image: require("@/assets/imgs/mailinglists.jpg"),
        rights:['admin']
    },
    {
        title: "Settings",
        name: "admsettings",
        image: require("@/assets/imgs/settings.png"),
        rights:['admin']
    },
    {
        title: "Tools",
        name: "admtools",
        image: require("@/assets/imgs/tools.png"),
        rights:['admin']
    },
    {
        title: "Site Content",
        name: "admsitecontent",
        image: require("@/assets/imgs/content.png"),
        rights:['admin']
    },
    {
        title: "Edit Events",
        name: "admevents",
        image: require("@/assets/imgs/events.jpg"),
        rights:['admin']
    },
    {
        title: "Manage MixTapes",
        name: "admmixtapes",
        image: require("@/assets/imgs/mixtapes2.jpg"),
        rights:['admin']
    },
]