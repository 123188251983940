<template>
  <v-container class="pa-0">
    <div v-for="artist in artists" :key="artist.id">
      <template v-if="artist.publish">
      <v-img dark :src="getFileUrl(artist)" contain>
        <v-row align="center" justify="center" class="fill-height">
          <v-col class="text-center" cols="12">
            <h1 class="display-2 font-weight-thin">{{artist.name}}</h1>
          </v-col>
        </v-row>
      </v-img>
      <div class="font-weight-light ma-4 mb-8" style="white-space: pre-line;">
        {{artist.profileTextArea}}
      </div>
    </template>
    </div>
  </v-container>
</template>
<script>
import defaultMixin from '@/mixins/defaultMixin'
import { createNamespacedHelpers } from "vuex";
const {
  mapState: mapStateArtists,
  mapActions: mapActionsArtists
} = createNamespacedHelpers("artists");

export default {
  name: "Artists",
  mixins: [defaultMixin], 
  data: () => ({}),
  created() {
    this.fetchArtists();
  },
  computed: {
    ...mapStateArtists(["artists"])
  },
  methods: {
    ...mapActionsArtists(["fetchArtists"]),
      getFileUrl(artist) {
      let fileEntity = artist.filesImageArtist[0];
      if (!fileEntity.url) {
        return (
          this.ac.apiBaseURL +
          "res/" +
          "artists" +
          "/" +
          artist.id +
          "/" +
          fileEntity.id +
          fileEntity.ext
        );
      } else {
        return fileEntity.url;
      }
    },

  }
};
</script>