import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_MAILINGS_PENDING = 'FETCH_MAILINGS_PENDING';
const FETCH_MAILINGS_FULFILLED = 'FETCH_MAILINGS_FULFILLED';
const FETCH_MAILINGS_ERROR = 'FETCH_MAILINGS_ERROR';

const UPDATE_MAILING_PENDING = 'UPDATE_MAILING_PENDING';
const UPDATE_MAILING_FULFILLED = 'UPDATE_MAILING_FULFILLED';
const UPDATE_MAILING_ERROR = 'UPDATE_MAILING_ERROR';



const state = {

    fetchMailingsPending: false,
    updateMailingPending: false,
    mailings: [],
    newMailing : {}
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_MAILINGS_PENDING](state) { state.fetchMailingsPending = true; },
    [FETCH_MAILINGS_FULFILLED](state, mailings) {
        state.mailings = mailings;
        state.fetchMailingsPending = false;
    },
    [FETCH_MAILINGS_ERROR](state) { state.fetchMailingsPending = false; },

    [UPDATE_MAILING_PENDING](state) { state.updateMailingPending = true; },
    [UPDATE_MAILING_FULFILLED](state) { state.updateMailingPending = false; },
    [UPDATE_MAILING_ERROR](state) { state.updateMailingPending = false; },

};
const actions = {
    async updateMailing({ commit, dispatch }, args) {
        commit(UPDATE_MAILING_PENDING);
        try {
            const url = 'mailings';

            const postdata = {
                apiCommand: args.cmd,
                mailing: args.mailing
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('mailing/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchMailings({ commit, dispatch }) {
        commit(FETCH_MAILINGS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_MAILINGS");
        try {
            const url = 'mailings';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_MAILINGS_FULFILLED, response.data.mailings);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getMailingById: (state) => (id) => {
        
        return state.mailings.find(mailings => mailings.id === id)
    },
    getLastMailing: (state) => {
        if(state.mailings.length == 0)
        {
            return null;
        }else{

            return state.mailings[state.mailings.length -1]
        }
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};