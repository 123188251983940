<template>
  <v-container class="pa-1">
    <v-row no-gutters class="mb-2">
      <v-col class="glass3 pl-0">Demos for {{forUser}}</v-col>
    </v-row>

    <v-data-iterator
      :items="demos"
      class="pa-0"
      must-sort
      :sort-by.sync="myTableState.sortBy"
      :sort-desc.sync="myTableState.sortDesc"
      :page.sync="myTableState.currentPage"
      :items-per-page.sync="myTableState.itemsPerPage"
      :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
      :hide-default-footer="!demos || demos.length <= myTableState.itemsPerPage"
    >
      <template v-slot:default="props">
        <v-row no-gutters v-for="track in props.items" :key="track.id" class="pa-0 mb-2">
          <v-col class="pa-0">
            <v-card dense class="pa-0 mb-3">
              <v-card-text class="pa-0 glass4">{{track.artists}} - {{track.name}}</v-card-text>
              <!-- <v-card-text class="pa-1 pt-0 caption">Artists: {{track.artists}}</v-card-text> -->
              <!-- <v-card-text class="pa-1 pt-0 caption">Release Date: {{release.dateRelease}}</v-card-text>
              <v-card-text class="pa-1 pt-0 caption">Cat-No: {{release.catNo}}</v-card-text>-->
              <!-- <v-card-text
                          class="pa-1 pt-0 caption"
                          v-if="release.upcEan"
              >UPC: {{release.upcEan}}</v-card-text>-->
              <!-- <v-card-text class="pa-1 pt-0 caption">ISRC: {{track.ISRC}}</v-card-text> -->
              <!-- <v-card-text
                          class="pa-1 font-weight-thin"
                          style="white-space: pre-line;"
              >{{track.descriptionTextArea}}</v-card-text>-->
              <v-card-text class="pa-0 mb-2">
                <AudioPlayer
                  :ref="'audio'+track.id"
                  :name="track.name"
                  :id="track.id"
                  :heightRatio="$vuetify.breakpoint.smAndDown ? .3 :0.16"
                  :audioUrl="ac.apiBaseURL +'res/tracks/' + track.id +'/' +track.filesAudioPrewiew[0].id + track.filesAudioPrewiew[0].ext"
                  :waveFormUrl="ac.apiBaseURL +'res/tracks/' + track.id +'/' +track.filesAudioPrewiew[0].id + '.png'"
                  :waveFormUrl2="ac.apiBaseURL +'res/tracks/' + track.id +'/' +track.filesAudioPrewiew[0].id + '2.png'"
                  @play="stopOthers(track.id);playStats($event,track,false)"
                  @playing="playStats($event,track,true)"
                />
              </v-card-text>
              <v-card-text class="pa-0 mb-2" v-if="track.showComment === false">
                <v-row>
                  <v-col class="flex-grow-1 flex-shrink-0">
                   </v-col> 
                  <v-col class="flex-grow-0 flex-shrink-1 pr-0">
                    <v-btn
                      :small="$vuetify.breakpoint.smAndDown"
                      class="ma-1"
                      @click="track.showComment = true"
                    >{{track.comments.length === 0 ? 'Add Comment' : 'Edit Comment'}}</v-btn>
                  </v-col>
                  <v-col class="flex-grow-0 flex-shrink-1 pl-0">
                    <v-btn
                      :small="$vuetify.breakpoint.smAndDown"
                      class="ma-1"
                      @click="track.interested = !track.interested ; submitFeedback(track)"
                      :color="track.interested?'success':''"
                    >
                      Interested
                      <v-icon right v-if="track.interested">mdi-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="track.comments.length !== 0">
                  <v-col class="flex-grow-1 flex-shrink-0 pt-0">
                    <div class="body-2 ml-2 mr-2">{{track.comments}}</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pa-0" v-if="track.showComment === true">
                <v-row class="ma-0 pa-1">
                  <v-textarea
                    :small="$vuetify.breakpoint.smAndDown"
                    outlined
                    hide-details
                    counter
                    v-model="track.comments"
                  ></v-textarea>
                </v-row>
                <v-row justify="end" class="ma-0">
                  <v-btn
                    :small="$vuetify.breakpoint.smAndDown"
                    class="ma-1"
                    @click="submitFeedback(track);track.showComment = false"
                  >Submit</v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <!-- <pre>{{demos}}</pre> -->
  </v-container>
</template>

<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import AudioPlayer from "@/components/AudioPlayer.vue";
import axios from "axios";

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("demos");

export default {
  name: "Releases",
  mixins: [defaultMixin],
  components: {
    AudioPlayer
  },
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Release date", value: "dateRelease" },
      { text: "", value: "action", sortable: false, align: "right" }
    ],
    search: "",
    dialog: false,
    myTableState: {},
    imagezoom: false,
    imgsrc: null,
    showHideComment: {}
  }),
  created() {
    this.myTableState = this.getTableState("demoTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "ISRC";
      this.myTableState.sortDesc = true;
    }
    this.fetchDemos();
  },
  computed: {
    ...mapStateReleases(["demos", "forUser"]),
    getAllTracks: {
      get: function() {
        return this.demos.flatMap(p => p.tracks);
      }
    }
  },
  methods: {
    ...mapActionsReleases(["fetchDemos", "submitDemoFeedBack"]),
    async submitFeedback(track) {
      const args = {
        demoTrackProps: {
          trackId: track.id,
          interested: track.interested,
          comments: track.comments
        }
      };
      await this.submitDemoFeedBack(args);
      await this.fetchDemos();
    },

    getFileUrl(release) {
      if (release.filesImageArtWork) {
        let fileEntity = release.filesImageArtWork[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "releases" +
            "/" +
            release.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },


    stopOthers(id) {
      console.log("stop except : " + id);
      let thisId = id;

      this.demos.forEach(track => {
        if (track.id !== thisId) {
          this.$refs["audio" + track.id][0].stop();
        }
      });
    }
  }
};
</script>