<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{name:'admuser', params:{id:$route.params.id}}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateToken" class="mr-2">
          Add Token
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-data-table
          :items="tokens"
          :search="search"
          :headers="headers"
          class="transparent-bg"
          :dense="!this.$vuetify.breakpoint.xs"
          must-sort
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
        >

          <template v-slot:item.action="{ item }" color="secondary">
            <v-btn
              :to="{path:'/admin/token/'+ item.id}"
              target="fb"
              text
              depressed
              icon
              color="secondary"
              v-if="isAdmin"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
          <template v-slot:item.isSubscribed="{ item }">
            <v-icon v-if="!item.isSubscribed == '' && isAdmin" small color="accent">mdi-check</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogToken">
      <v-card>

      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";


const {
  mapState: mapStateTokens,
  mapActions: mapActionsTokens,
} = createNamespacedHelpers("tokens");

const {
  mapState: mapStateTracks,
  mapActions: mapActionsTracks,
} = createNamespacedHelpers("tracks");


export default {
  name: "AdminTokens",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "token_type", value: "token_type" },
      { text: "ta", value: "timesAccessed" },
      { text: "dateCreated", value: "dateCreated" },
      { text: "resourceId", value: "resourceId" },
      { text: "resource", value: "resource" },
      { text: "", value: "action", sortable: false, align: "right" }
    ],
    search: "",
    toImport : '',
    addToList : [],
    dialogToken: false,
    tokenType : 'PUBLIC',
    tokenTypes : [
        'PUBLIC',
        'VIP',
    ],
    myTableState: {}
  }),
  async created() {
    this.myTableState = this.getTableState("tableTokens");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "tokenType";
      this.myTableState.sortDesc = true;
    }
    await this.fetchTracks();
    this.fetchTokens(this.$route.params.id);
  },
  computed: {
    ...mapStateTokens(["tokens"]),
    ...mapStateTracks(["tracks"]),
  },
  methods: {
    ...mapActionsTokens(["fetchTokens", "createToken"]),
    ...mapActionsTracks(["fetchTracks"]),
    async doCreateToken() {
      const newTokenID = await this.getObjectId();
      console.log("newTokenID " + newTokenID);
      this.dialogToken = !this.dialogToken;
    }
  }
};
</script>