<template>
  <div class="maxw">
    <v-app dark class="transparent-bg">
      <MessageBound />
      <div class="maxt">
        <v-app-bar app fixed dense color="secondary">
          <router-link :to="{name:'home'}">
            <v-avatar :tile="true" style="margin-left:-12px;height:40px">
              <v-img :src="logo" contain></v-img>
            </v-avatar>
          </router-link>
          <!-- <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Just-Buzz-Bass Records</v-toolbar-title> -->

          <v-spacer></v-spacer>

          <v-btn
            :to="{name:'admin'}"
            class="ml-2"
            text
            depressed
            tile
            v-if="isSignedIn && (isAdmin || isEditor)"
          >
            <v-icon>mdi-shield-account</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Admin</span>
          </v-btn>
          <v-menu offset-y v-if="isSignedIn && (isAdmin || isEditor)" left dense>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" class="ml-2" v-bind="attrs">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-group :value="true" @click.stop.prevent dense>
                <template v-slot:activator>
                  <v-list-item-title dense>Public Site</v-list-item-title>
                </template>
                <v-list dense>
                  <v-list-item dense :to="{name:'releases'}"  class="pl-7">
                    <v-list-item-title dense >Releases</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense :to="{name:'artists'}" class="pl-7">
                    <v-list-item-title dense>Artists</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense :to="{name:'submitdemo'}" class="pl-7">
                    <v-list-item-title dense>Submit Demo</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense :to="{name:'signup'}" class="pl-7">
                    <v-list-item-title dense>Sign Up</v-list-item-title>
                  </v-list-item>
                </v-list>

              </v-list-group>
                <v-divider></v-divider>              
              <div v-for="(item, index) in sections" :key="index" :to="{name:item.name}">
                <v-list-item
                  v-if="(isEditor && item.rights.includes('editor')) || (isAdmin && item.rights.includes('admin'))"
                  dense
                  :to="{name:item.name}"
                >
                  <v-list-item-title dense>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-btn
            :to="{name:'releases'}"
            class="ml-2 mr-0"
            text
            depressed
            tile
            v-if="!isAdmin && pubreleases.length > 0"
          >
            <v-icon>mdi-album</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Releases</span>
          </v-btn>
          <v-btn :to="{name:'artists'}" class="ml-2" text depressed tile v-if="!isAdmin">
            <v-icon>mdi-account-music</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Artists</span>
          </v-btn>
          <v-btn
            :to="{name:'submitdemo'}"
            class="ml-2"
            text
            depressed
            tile
            v-if="!isAdmin && !isEditor && isMember"
          >
            <v-icon>mdi-contactless-payment-circle-outline</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Submission</span>
          </v-btn>
          <v-btn
            :to="{name:'profile'}"
            class="ml-2"
            text
            depressed
            tile
            v-if="!isAdmin && !isEditor && isMember"
          >
            <v-icon>mdi-account</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Profile</span>
          </v-btn>
          <v-btn
            :to="{name:'mixtapes'}"
            class="ml-2"
            text
            depressed
            tile
            v-if="!isAdmin && !isEditor && !isMember"
          >
            <v-icon>mdi-music-circle-outline</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Mixtapes</span>
          </v-btn>
          <v-btn :to="{name:'cart'}" class="ml-2" text depressed tile v-if="cart">
            <v-icon>mdi-cart</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Cart</span>
          </v-btn>
          <v-btn
            :to="{name:'order'}"
            class="ml-2"
            text
            depressed
            tile
            v-if="orders.length > 0 || isShopper"
          >
            <v-icon>mdi-account-heart</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">goods</span>
          </v-btn>
          <!-- <v-btn :to="{name:'events'}" class="ml-2" text depressed tile v-if="!isAdmin && !isMember" >
            <v-icon>mdi-calendar-star</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Events</span>
          </v-btn>-->
          <v-btn v-if="isSignedIn" @click="doSignOut()" text depressed tile>
            <v-icon>mdi-power</v-icon>
            <span class="ml-2" v-if="$vuetify.breakpoint.smAndUp">Sign out</span>
          </v-btn>
        </v-app-bar>
      </div>

      <v-main class="translucent-bg">
        <div v-for="ulp in uploadProgress" :key="ulp.id">
          <v-progress-linear :value="ulp.progress" color="accent" v-if="ulp.progress > 0"></v-progress-linear>
        </div>
        <router-view :key="$route.fullPath" />
      </v-main>
      <v-bottom-sheet v-model="sheet" persistent>
        <v-sheet>
          <v-container class="pt-0 pb-0">
            <v-row>
              <v-col
                style="min-width: 100px; max-width: 100%;"
                class="flex-grow-1 flex-shrink-0 align-self-center"
                align="right"
              >
                This Site is using Cookies, read more about it
                <router-link :to="{name:'privacy'}" text small>
                  <span color="accent">here</span>
                </router-link>
              </v-col>
              <v-col class="flex-grow-0 flex-shrink-1 align-self-center">
                <v-btn color="accent" @click="acceptCookies" class="ml-1">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-bottom-sheet>
      <div class="caption" v-if="false && isDev">
        isAdmin : {{isAdmin}} | isSignedIn : {{isSignedIn}} | isMember : {{isMember}} |
        | accessToken : {{accessToken}}
        | redirectAfterLogin : {{redirectAfterLogin !== null}}
        | userId : {{userId}} | host : {{host}}
      </div>
      <v-footer padless class="translucent-bg-dark mt-0">
        <v-divider></v-divider>
        <v-card dense width="100%" class="translucent-bg-dark text-center">
          <v-card-text v-if="!isAdmin" class="pb-0">
            <v-btn class="mx-2" icon target="social" href="https://soundcloud.com/buzzbass">
              <v-icon size="24px">mdi-soundcloud</v-icon>
            </v-btn>
            <v-btn class="mx-2" icon target="social" href="https://justbuzzbass.bandcamp.com/">
              <v-icon size="24px">mdi-bandcamp</v-icon>
            </v-btn>
            <v-btn class="mx-2" icon target="social" href="https://www.facebook.com/JustBuzzBass/">
              <v-icon size="24px">mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              icon
              target="social"
              href="https://www.youtube.com/user/justbuzzbass"
            >
              <v-icon size="24px">mdi-youtube</v-icon>
            </v-btn>
            <v-btn class="mx-2" icon target="social" href="https://www.instagram.com/justbuzzbass/">
              <v-icon size="24px">mdi-instagram</v-icon>
            </v-btn>
            <v-btn class="mx-2" icon target="social" href="https://www.mixcloud.com/buzzbass/">
              <v-icon size="24px">mdi-pot-mix-outline</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text class="pt-1">
            <router-link :to="{name:'contact'}">Contact us</router-link>—
            <router-link :to="{name:'privacy'}">Privacy</router-link>—
            <router-link :to="{name:'tos'}">Tos</router-link>—
            <a @click="unSubscribeDialog = true" href="#">Unsubscribe</a> —
            <router-link :to="{name:'order'}" class="text-no-wrap">My Orders</router-link>
          </v-card-text>
          <v-card-text class="pt-0">
            © {{ new Date().getFullYear() }}
            Just Buzz Bass Recordings
          </v-card-text>
        </v-card>
      </v-footer>
      <v-dialog
        v-model="unSubscribeDialog"
        max-width="350px"
        transition="dialog-transition"
        :overlay-opacity="0.9"
        overlay-color="#000000"
      >
        <v-card>
          <v-card-title class="font-weight-thin">Unsubscribe</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4 pb-2">
            <v-text-field
              v-model="subscription.email"
              name="name"
              label="E-Mail"
              :rules="emailRules"
              ref="emailField"
              color="accent"
            ></v-text-field>
          </v-card-text>
          <v-card-actions align="right">
            <v-spacer></v-spacer>
            <v-btn @click="unSubscribeDialog = false">Cancel</v-btn>
            <v-btn @click="submitUnsubscribe">unsubscribe</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>
<style>
.rounded {
  border-radius: 50px;
}
.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: #ffc40031;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #ffc60cb7 !important;
}

.theme--dark.v-list-item.primary--text.v-list-item--active {
  color: white !important;
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

/* .theme--dark.v-list-item--link:before {
  background: #ffffff !important;
  opacity: .1 !important;
}

.theme--dark.v-list .v-list-item--active {
    color: #ffffff !important;
} */

/* .v-data-table tr:hover {
  background-color: rgba(136, 136, 136, 0.014) !important;
}
.v-data-table__expanded:hover {
  background-color: rgba(0, 4, 5, 0.7) !important;
} */
.maxw {
  max-width: 1024px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.maxt header {
  max-width: 1024px !important;
  min-width: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.translucent-bg-dark {
  background-color: rgba(10, 10, 10, 0.363) !important;
}
.translucent-bg {
  background-color: rgba(27, 27, 27, 0.5) !important;
}
.translucent-bg-1 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.transparent-bg {
  background-color: transparent !important;
}
.dialog-bg {
  background-color: #252525 !important;
}
.glass1 {
  font-family: glassregular;
  font-size: 40px !important;
  color: rgba(255, 255, 255, 0.8);
}
.glass2 {
  font-family: glassregular;
  font-size: 25px !important;
  color: rgba(255, 255, 255, 0.8);
}
.glass3 {
  font-family: glassregular;
  font-size: 18px !important;
  color: rgba(255, 255, 255, 0.8);
}
.glass4 {
  font-family: glassregular;
  font-size: 13px !important;
  color: rgba(255, 255, 255, 0.8);
}
.divider0 {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.12);
}
</style>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import MessageBound from "@/components/MessageBound.vue";
import axios from "axios";
import { admsections } from "@/admsections";


const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");

export default {
  name: "App",
  mixins: [defaultMixin],
  components: {
    MessageBound
  },
  data: () => ({
    logo: require("@/assets/logo.svg"),
    sheet: false,
    unSubscribeDialog: false,
    subscription: {
      email: ""
    },
    sections: admsections,
    emailRules: [
      v => !!v || "E-mail is required",
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid"
    ]
  }),
  created() {
    // eslint-disable-next-line
    console.log("APP created " + process.env.NODE_ENV);
    this.fetchPubReleases();
    let cc = localStorage.getItem("cookie-consent");
    if (!cc) {
      this.sheet = false;
    }
  },
  computed: {
    ...mapStateReleases(["pubreleases"])
  },
  mounted() {},
  methods: {
    getMaxStyle() {
      if (this.isSignedIn) {
        return "max-width:1200px";
      }
    },
    ...mapActionsReleases(["fetchPubReleases"]),
    acceptCookies() {
      this.sheet = false;
      localStorage.setItem("cookie-consent", true);
    },
    checkEmail() {
      this.$refs.emailField.focus();
      if (this.$refs.emailField.validate()) {
        return true;
      }
      return false;
    },
    async submitUnsubscribe() {
      if (this.checkEmail()) {
        console.log("post UNSUBSCRIBE subscription ");
        // post rating
        try {
          const url = "subscription";

          let topics = [];

          const postdata = {
            apiCommand: "UNSUBSCRIBE",
            subscription: {
              email: this.subscription.email
            }
          };

          const response = await axios.post(url, postdata);

          this.unSubscribeDialog = false;

          this.setMessage({
            message: "Sucessfully unsubscribed!",
            dialog: true,
            type: "success"
          });
          this.subscription.email = "";
          this.$refs.emailField.resetValidation();
        } catch (error) {
          this.unSubscribeDialog = false;
          console.log("subscription review " + error);
        }
      }
    },
    async doSignOut() {
      const success = await this.signOut();

      if (success) {
        this.$router.push({ name: "home" });
      }
    }
  }
};
</script>
