import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_EMAILPROVIDERS_PENDING = 'FETCH_EMAILPROVIDERS_PENDING';
const FETCH_EMAILPROVIDERS_FULFILLED = 'FETCH_EMAILPROVIDERS_FULFILLED';
const FETCH_EMAILPROVIDERS_ERROR = 'FETCH_EMAILPROVIDERS_ERROR';

const UPDATE_EMAILPROVIDER_PENDING = 'UPDATE_EMAILPROVIDER_PENDING';
const UPDATE_EMAILPROVIDER_FULFILLED = 'UPDATE_EMAILPROVIDER_FULFILLED';
const UPDATE_EMAILPROVIDER_ERROR = 'UPDATE_EMAILPROVIDER_ERROR';



const state = {

    fetchEmailProvidersPending: false,
    updateEmailProviderPending: false,
    emailproviders: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_EMAILPROVIDERS_PENDING](state) { state.fetchEmailProvidersPending = true; },
    [FETCH_EMAILPROVIDERS_FULFILLED](state, emailproviders) {
        state.emailproviders = emailproviders;
        state.fetchEmailProvidersPending = false;
    },
    [FETCH_EMAILPROVIDERS_ERROR](state) { state.fetchEmailProvidersPending = false; },

    [UPDATE_EMAILPROVIDER_PENDING](state) { state.updateEmailProviderPending = true; },
    [UPDATE_EMAILPROVIDER_FULFILLED](state) { state.updateEmailProviderPending = false; },
    [UPDATE_EMAILPROVIDER_ERROR](state) { state.updateEmailProviderPending = false; },

};
const actions = {
    async updateEmailProvider({ commit, dispatch }, args) {
        commit(UPDATE_EMAILPROVIDER_PENDING);
        try {
            const url = 'emailproviders';

            const postdata = {
                apiCommand: args.cmd,
                emailprovider: args.emailprovider
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('emailprovider/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchEmailProviders({ commit, dispatch }) {
        commit(FETCH_EMAILPROVIDERS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_EMAILPROVIDERS");
        try {
            const url = 'emailproviders';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_EMAILPROVIDERS_FULFILLED, response.data.emailproviders);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getEmailProviderById: (state) => (id) => {
        return state.emailproviders.find(emailproviders => emailproviders.id === id)
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};