<template>
  <v-container>
    <v-row no-gutters class="mb-2">
      <v-col class="glass2 pl-0">Your Profile</v-col>
    </v-row>
    <v-row no-gutters dense>
      <v-col>
        <v-card>
            <v-card-text>Email: {{user.email}}</v-card-text>
            <v-card-text>Artist name: {{user.artistsName}}</v-card-text>
            <v-card-text>WebSite: {{user.website}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateUsers,
  mapActions: mapActionsUsers
} = createNamespacedHelpers("users");

export default {
    data: () => ({
    
    }),
  async created() {
      this.fetchUser("");


  },
  computed: {
    ...mapStateUsers(["user"])
  },
  methods: {
    ...mapActionsUsers(["fetchUser"])
  }
};
</script>         