import axios from 'axios';
import vuemoment from 'vue-moment';
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router.js';
import store from './store/store';
import { appconfig } from './config';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VuexRouterSync from 'vuex-router-sync';
VuexRouterSync.sync(store, router);


Vue.use(vuemoment);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)



// import myPlugin from './plugins/OldHelperPlugin';
// Vue.use(myPlugin);


axios.defaults.withCredentials = true
axios.interceptors.request.use((config) => {
    const axiosConfig = config;
    axiosConfig.baseURL = appconfig.apiBaseURL;


    axiosConfig.onUploadProgress = progressEvent => {

        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader("content-length") || progressEvent.target.getResponseHeader("x-decompressed-content-length");
        if (totalLength !== null) {
            const progressData = Math.round((progressEvent.loaded * 100) / totalLength);
            store.commit('global/SET_UPLOAD_PROGRESS', { id: totalLength.toString(), progress: progressData });
            if (progressData === 100) {
                //store.state.uploadProgress = 0;
                store.commit('global/SET_UPLOAD_PROGRESS', { id: totalLength.toString(), progress: -1 });
            }
            console.log("Global onUploadProgress ", totalLength + " / " + progressEvent.loaded + " / " + progressData);
        }
    }

    // add new progress

    // store.commit('global/SET_DOWNLOAD_PROGRESS', { id: rqid, loaded: 0, total: 100 });

    // axiosConfig.onDownloadProgress = (progressEvent) => {

    //     const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader("x-decompressed-content-length") ? progressEvent.target.getResponseHeader("x-decompressed-content-length") : progressEvent.target.getResponseHeader("content-length");

    //     if (totalLength !== null) {

    //         console.log("global download progressEvent.loaded : " + progressEvent.loaded);

    //         store.commit('global/SET_DOWNLOAD_PROGRESS', { id: rqid, loaded: progressEvent.loaded, total: totalLength });

    //         // if (progressEvent.loaded >= totalLength) {
    //         //     //store.state.uploadProgress = 0;
    //         //     store.dispatch('user/setDownloadProgress', { id: rqid, loaded: -1, total: totalLength });
    //         // }
    //         // else
    //         // {
    //         //     store.dispatch('user/setDownloadProgress', { id: rqid, loaded: progressEvent.loaded, total: totalLength });
    //         // }

    //     }

    // }

    return axiosConfig;
});


(async () => {
    // eslint-disable-next-line no-console
    console.log(await store.dispatch('user/signInState'));
    startVue();
})();


Vue.config.productionTip = false

function startVue() {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
        mounted() {
            document.dispatchEvent(new Event('render-event'))
        }
    }).$mount('#app')
}