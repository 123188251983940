<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn @click="$router.push({name:'admusers'})" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1" v-if="!isnew">
          EDIT USER
          <br />
        </div>
        <div class="font-weight-thin display-1" v-if="isnew">
          NEW USER
          <br />
        </div>
      </v-col>
    </v-row>
    <v-tabs background-color="transparent" v-model="selTab" @change="changeTab">
      <v-tab key="edit">Edit</v-tab>
      <v-tab key="subs">Subs</v-tab>
      <v-tab key="logs">Logs</v-tab>

      <v-tab-item key="edit">
        <DynamicFormComponent
          name="user"
          schemaName="User"
          :formData="userData"
          namespace="users"
          :ac="ac"
          :getObjectId="getObjectId"
          :extractFileExtension="extractFileExtension"
          :refObjects="refObjects"
        />

        <v-row justify="end" class="pa-2">
          <v-btn :to="{name:'admusertokens', params:{id:$route.params.id} }" class="ma-1">
            Manage Tokens
            <v-icon right>mdi-database</v-icon>
          </v-btn>

          <v-btn  :to="{name:'admusers'}" class="ma-1">
            Cancel
            <v-icon right>mdi-close-circle</v-icon>
          </v-btn>

          <v-btn  @click="dialog=true" v-if="$route.params.id" class="ma-1">
            Delete
            <v-icon right>mdi-trash-can</v-icon>
          </v-btn>

          <v-btn  @click="saveUser(false)" class="ma-1">
            Save
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-row>
      </v-tab-item>
      <v-tab-item key="subs">
        <v-data-table
          v-model="subscribedTo"
          :headers="headersSubs"
          :items="subcriptions"
          item-key="id"
          class="transparent-bg"
          must-sort
          :sort-by.sync="tableStateSubs.sortBy"
          :sort-desc.sync="tableStateSubs.sortDesc"
          :page.sync="tableStateSubs.currentPage"
          :items-per-page.sync="tableStateSubs.itemsPerPage"
          :footer-props="{ 'items-per-page-options':tableStateSubs.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          show-select
          @item-selected="updateSubcriptions"
        ></v-data-table>
      </v-tab-item>

      <v-tab-item key="logs">
        <v-data-table
          :headers="headersLogs"
          :items="logItems"
          item-key="id"
          class="transparent-bg"
          must-sort
          :sort-by.sync="tableStateLogs.sortBy"
          :sort-desc.sync="tableStateLogs.sortDesc"
          :page.sync="tableStateLogs.currentPage"
          :items-per-page.sync="tableStateLogs.itemsPerPage"
          :footer-props="{ 'items-per-page-options':tableStateLogs.itemsPerPageOptions }"
          :mobile-breakpoint="200"
        ></v-data-table>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>User {{userData.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="saveUser(true);dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapActions: mapActionsUsers,
} = createNamespacedHelpers("users");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmUser",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    userData: {},
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    headersLogs: [
      { text: "action", value: "action" },
      { text: "date", value: "date" },
      { text: "resourceName", value: "resourceName" },
      { text: "playTime", value: "playTime" },
      { text: "reomoteHost", value: "reomoteHost" }
    ],
    headersSubs: [
      { text: "Name", value: "name" },
      { text: "Subscr", value: "hiddenSubscribed" },
      { text: "Date Created", value: "dateCreated" }
    ],
    schemaUser: SchemasConst.User,
    refObjects: {},
    logItems: [],
    subcriptions: [],
    tableStateLogs: {},
    tableStateSubs: {},
    subscribedTo: [],
    selTab : 0,
    ismounted : false
  }),
  async created() {
    console.log("admuser created tabid: " + this.$route.params.tabid);
    if(this.$route.query.tabid)
    {
      this.selTab = this.$route.query.tabid;
    }

    if (this.$route.params.id) {
      const ev = await this.fetchUser(this.$route.params.id);
      if (ev === undefined) {
        this.userData.id = this.$route.params.id;
        this.isnew = true;
      } else {
        this.userData = ev;
      }
    }

    this.tableStateLogs = this.getTableState("tableLogs");
    if (!this.tableStateLogs.sortBy) {
      this.tableStateLogs.sortBy = "date";
      this.tableStateLogs.sortDesc = true;
      this.tableStateLogs.itemsPerPage = 12;
    }
    this.tableStateSubs = this.getTableState("tableStateSubs");
    if (!this.tableStateSubs.sortBy) {
      this.tableStateSubs.sortBy = "hiddenSubscribed";
      this.tableStateSubs.sortDesc = true;
      this.tableStateSubs.itemsPerPage = 12;
    }

    if (!this.isnew) {
      await this.fetchLogItems(this.userData.id);
      await this.fetchSubcriptions();
      this.subscribedTo = this.subcriptions.filter(
        s => s.hiddenSubscribed === true
      );
    }
    this.ismounted = true;
  },
  computed: {},
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsUsers(["fetchUsers", "updateUser", "fetchUser"]),
    getvModel(name) {
      return this.userData[name];
    },
    async changeTab(ev)
    {
      
      console.log('Change Tab: ' + JSON.stringify(ev));

      //this.$router.replace({ query: {tabid: ev}})

      // this.$router.push({ query: Object.assign(this.$route.query, { tabid: ev }) })

      // const query = Object.assign({}, this.$route.query);
      // query.tabid = ev;
      // await this.$router.push({ query });
      //   //this.$router.replace({name:this.$route.name,params:{id:this.$route.params.id},query:{tabid:ev}})

    },
    getSelection() {
      let selection = [];
    },
    goBack() {
      this.$router.go(-1);
    },
    async updateSubcriptions(args) {
      const url = "users";

      console.log(JSON.stringify(args));

      const subs = [];

      this.subcriptions.forEach(x => {
        if (x.id === args.item.id) {
          x.hiddenSubscribed = args.value;
        }
        subs.push({ id: x.id, hiddenSubscribed: x.hiddenSubscribed });
      });

      console.log("UPDATE_SUBSCRIPTIONS " + JSON.stringify(subs));

      const postdata = {
        apiCommand: "UPDATE_SUBSCRIPTIONS",
        user: {
          id: this.userData.id
        },
        mailinglists: subs
      };
      const response = await axios.post(url, postdata);

      if (response.data.status[0] === "OK") {
        console.log("UPDATE_SUBSCRIPTIONS UPDATED");
      }
    },
    async fetchSubcriptions() {
      const url = "users";
      const postdata = {
        apiCommand: "GET_SUBSCRIPTIONS",
        user: this.userData
      };
      const response = await axios.post(url, postdata);

      if (response.data.status[0] === "OK") {
        this.subcriptions = response.data.mailinglists;
      }
    },
    async fetchLogItems(id) {
      const url = "userlogs";
      const postdata = {
        id: id
      };
      const response = await axios.post(url, postdata);

      if (response.data.status[0] === "OK") {
        this.logItems = response.data.logItems;
      }
    },
    uploadFiles() {
      this.schemaUser.forEach(field => {
        if (field.itemType === "files") {
          if (this.userData[field.name]) {
            this.userData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "users/" + this.$route.params.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveUser(deleteUser) {
      console.log("saveUser");
      let cmd = this.isnew ? "CREATE" : "UPDATE";
      cmd = deleteUser ? "DELETE" : cmd;

      const success = await this.updateUser({
        user: this.userData,
        cmd: cmd
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admusers" });
      }
    }
  }
};
</script>