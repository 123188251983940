<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{ path: $store.state.route.from.fullPath }" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>SEND MAIL
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <v-alert
          dense
          class="mb-0"
        >Select Mailing List {{tableStateMailingLists.selectedItems.length}}</v-alert>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="searchMailingLists"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!searchMailingLists">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          :items="mailinglists"
          :search="searchMailingLists"
          :headers="headersMailingLists"
          v-model="tableStateMailingLists.selectedItems"
          class="transparent-bg"
          show-select
          must-sort
          :sort-by.sync="tableStateMailingLists.sortBy"
          :sort-desc.sync="tableStateMailingLists.sortDesc"
          :page.sync="tableStateMailingLists.currentPage"
          :items-per-page.sync="tableStateMailingLists.itemsPerPage"
          :footer-props="{ 'items-per-page-options':tableStateMailingLists.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          style="cursor:pointer"
        >
          <template v-slot:item.action="{ item }" color="secondary">{{item.refUser.length}}</template>

          <template v-slot:item.hiddenSent="{ item }">
            <v-icon v-if="item.hiddenSent" small  class="ma-0">mdi-check</v-icon>
            <v-icon v-else small  class="ma-0">mdi-lightbulb-on-outline</v-icon>
          </template>

          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        Mail Preview
        <iframe
          :src="ac.apiBaseURL + 'mailpreview/' + $route.params.id"
          style="width:100%;height:300px;background-color:white"
          frameborder="0"
        ></iframe>
      </v-col>
      <v-col cols="12" sm="6">
        <v-row>
          <v-col>Total Reciepients: {{totalRecipients}}</v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="accessLimit"
              outlined
              single-line
              type="number"
              dense
              label="Access Limit per Track"
              color="accent"
              :rules="[rules.required, rules.loanMin, rules.loanMax]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>Generate Download Tokens for:</v-col>
        </v-row>
        <div v-for="id in mailing.refRelease" :key="id">
          <v-card dense class="transparent-bg" outlined>
            <v-card-title>{{getReleaseById(id).name}}</v-card-title>
            <v-card-text
              v-for="(trackid, idx) in getReleaseById(id).refTrack"
              :key="trackid"
              class="pt-0"
            >
              <v-row no-gutters>
                <v-checkbox
                  v-model="tokensForRecources[idx]"
                  :value="trackid"
                  dense
                  :label="getTrackById(trackid).name"
                  class="mt-0"
                  hide-details
                ></v-checkbox>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>


    <v-row justify="end" class="pa-2">
        <v-btn @click="dialogToken = true" class="ma-1">Create Campaign Link</v-btn>
        <v-btn @click="sendMailing" class="ma-1">Send Mailing</v-btn>
    </v-row>

    <v-dialog v-model="dialogSend" :max-width="400" persistent>
      <v-card class="dialog-bg">
        <v-card-title>Sending Mails please wait ...</v-card-title>
        <v-card-text align="center">
          <v-progress-circular
            :value="sendProgress"
            :size="140"
            :width="15"
            color="accent"
          >{{sendProgress}} % - {{counter}}</v-progress-circular>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col align="right">
              <v-btn @click="doSendMailFlag = false">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogToken" :max-width="600" transition="dialog-transition">
      <v-card class="dialog-bg">
        <v-card-title>Create Campaing Link for Social media</v-card-title>
        <v-card-subtitle>Only use for one User per Campaign</v-card-subtitle>
        <v-card-text>
          <v-autocomplete
            :items="users"
            v-model="selectedUserForToken"
            label="Select User by Artist name"
            item-text="artistsName"
            item-value="id"
            @change="campaignLink=''"
          ></v-autocomplete>
          <v-autocomplete
            :items="users"
            v-model="selectedUserForToken"
            label="Select User by Email"
            item-text="email"
            item-value="id"
            @change="campaignLink=''"
          ></v-autocomplete>
        </v-card-text>
        <v-card-text>
          <div v-for="id in mailing.refRelease" :key="id">
            <v-card dense class="transparent-bg" outlined>
              <v-card-title>{{getReleaseById(id).name}}</v-card-title>
              <v-card-text
                v-for="(trackid, idx) in getReleaseById(id).refTrack"
                :key="trackid"
                class="pt-0"
              >
                <v-row no-gutters>
                  <v-checkbox
                    v-model="tokensForRecources[idx]"
                    :value="trackid"
                    dense
                    :label="getTrackById(trackid).name"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-card-text>
          <v-text-field ref="textToCopy" v-model="campaignLink" label="Campaign Link" readonly></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col align="right">
              <v-btn @click="copyText">Copy</v-btn>
              <v-btn @click="getCampaignLink" class="ml-2">Create Link</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";

const {
  mapState: mapStateMailingLists,
  mapActions: mapActionsMailingLists,
} = createNamespacedHelpers("mailinglists");

const {
  mapActions: mapActionsMailings,
  mapGetters: mapGettersMailings
} = createNamespacedHelpers("mailings");

const {
  mapState: mapStateUsers,
  mapActions: mapActionsUsers,
} = createNamespacedHelpers("users");

const {
  mapActions: mapActionsReleases,
  mapGetters: mapGettersReleases
} = createNamespacedHelpers("releases");

const {
  mapActions: mapActionsTracks,
  mapGetters: mapGettersTracks
} = createNamespacedHelpers("tracks");

export default {
  name: "AdminMailingLists",
  mixins: [defaultMixin],
  data: () => ({
    headersMailingLists: [
      { text: "Name", value: "name" },
      { text: "MailingList date", value: "dateCreated" },
      { text: "cnt", value: "action", sortable: false, align: "right" },
      { text: "Sent", value: "hiddenSent", sortable: false, align: "right" }
    ],
    rules: {
      required: value => !!value || "Required.",
      loanMin: value => value >= 0 || "Value should be above 0",
      loanMax: value => value <= 10 || "Max Value should not be above 10"
    },
    searchMailingLists: "",
    dialogSend: false,
    dialogToken: false,
    campaignLink: "",
    selectedUserForToken: {},
    tableStateMailingLists: {},
    tableStateUsers: {},
    mailing: {},
    accessLimit: 2,
    tokensForRecources: [],
    sendProgress: 0,
    doSendMailFlag: false,
    counter : 0
  }),
  async created() {
    this.tableStateMailingLists = this.getTableState("mailinglistTable");
    if (!this.tableStateMailingLists.sortBy) {
      this.tableStateMailingLists.sortBy = "dateCreated";
      this.tableStateMailingLists.itemsPerPage = 8
      this.tableStateMailingLists.sortDesc = true;
    }
    await this.fetchMailingLists({force:true,mailingId:this.$route.params.id});
    await this.fetchMailings();
    await this.fetchReleases();
    await this.fetchTracks();
    await this.fetchUsers();
    this.mailing = this.getMailingById(this.$route.params.id);
  },
  computed: {
    ...mapStateUsers(["users"]),
    ...mapStateMailingLists(["mailinglists"]),
    ...mapGettersMailings(["getMailingById"]),
    ...mapGettersReleases(["getReleaseById"]),
    ...mapGettersTracks(["getTrackById"]),
    totalRecipients: {
      get: function() {
        return this.tableStateMailingLists.selectedItems.reduce(
          (a, ml) => a + ml.refUser.length,
          0
        );
      }
    },
    allRecipients: {
      get: function() {
        // get all userids in selected list and put them in one array then remove dupes
        let result = this.tableStateMailingLists.selectedItems
          .map(ml => ml.refUser)
          .reduce(function(a, b) {
            return a.concat(b);
          }, []);
        return Array.from(new Set(result));
      }
    }
  },
  methods: {
    ...mapActionsMailingLists(["fetchMailingLists", "createMailingList"]),
    ...mapActionsMailings(["fetchMailings", "updateMailing"]),
    ...mapActionsUsers(["fetchUsers"]),
    ...mapActionsReleases(["fetchReleases"]),
    ...mapActionsTracks(["fetchTracks"]),
    async getCampaignLink() {
      console.log("getCampaignLink");
      try {
        const url = "getCampaignLink";

        const postdata = {
          apiCommand: "NONE",
          mailing: {
            id: this.mailing.id
          },
          user: {
            id: this.selectedUserForToken
          },
          accessLimit: this.accessLimit,
          resourceIds: this.tokensForRecources
        };

        console.log(JSON.stringify(postdata));

        const response = await axios.post(url, postdata);

        console.log(JSON.stringify(response.data));

        if (response.data.status[0] === "OK") {
          this.campaignLink = response.data.campaignLink;
          return true;
        }
      } catch (error) {
        console.log("error getCampaignLink " + error);
      }
    },
    async sendMailing() {
      // do checks
      this.dialogSend = true;
      this.doSendMailFlag = true;
      this.sendProgress = 0;
      this.counter = 0;
      let total = this.allRecipients.length;
      for (const recipient of this.allRecipients) {
        if(this.doSendMailFlag === false)
        {
          break;
        }
        const success = await this.doSendMail(
          recipient,
          this.mailing.id,
          this.tokensForRecources
        );
        if(!success)
        {
          this.dialogSend = false;
          console.log("Send mail error")
          break;
        }
        this.counter++;
        this.sendProgress = ((100 / total) * this.counter).toFixed();
        console.log(recipient + " " + this.sendProgress);
      }

      this.sendProgress = 100;

      await this.sleep(200);

      this.dialogSend = false;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
      this.dialogToken = false;
      this.campaignLink = '';
    },
    async doSendMail(userId, mailingId, resourceIds) {
      console.log("doSend Mail");
      try {
        const url = "sendmail";

        const postdata = {
          apiCommand: "SEND_MAIL",
          mailing: {
            id: mailingId
          },
          user: {
            id: userId
          },
          accessLimit: this.accessLimit,
          resourceIds: resourceIds
        };

        const response = await axios.post(url, postdata);

        


        if (response.data.status[0] === "OK" ) {
          return true;
        }else
        {
          console.log("response status " + response.data.status[0] );
          return false;
        }
        
      } catch (error) {
        console.log("error send mail " + error);
      }
    }
  }
};
</script>