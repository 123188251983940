<template>
  <v-container>
    <v-row no-gutters>
            <v-col class="pl-0 flex-grow-0 flex-shrink-1 d-flex">
                <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact >
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col class="glass3 pl-0 flex-grow-1 flex-shrink-0 d-flex align-center">Tracks</v-col>
      <v-col class="pl-0 flex-grow-0 flex-shrink-1 d-flex">
        <v-btn @click="doCreateTrack"  icon>
          <v-icon >mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col >
        <v-text-field v-model="search" outlined color="accent" dark label="Search" single-line hide-details dense clearable >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="tracks"
          :search="search"
          :headers="headers"
          must-sort
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          class="transparent-bg"
          :hide-default-header="!isAdmin"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          style="cursor:pointer"
          dense
        >
          <template v-slot:item.action="{ item }" color="secondary">
            <v-btn :to="{path:'/admin/track/'+ item.id}" text depressed icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
          <template
            v-slot:item.ISRC="{ item }"
          >{{item.ISRC ? $vuetify.breakpoint.smAndDown ? item.ISRC.slice(-4) : item.ISRC : "-"}}</template>
          <template v-slot:item.purchaseEnabled="{ item }">
            <v-icon v-if="item.purchaseEnabled" small color="accent">mdi-check</v-icon>
          </template>
          <template v-slot:item.descriptionTextArea="{ item }">
            <v-icon
              v-if="item.descriptionTextArea && item.descriptionTextArea.length > 10"
              small
              color="accent"
            >mdi-check</v-icon>
          </template>
          <template v-slot:item.isInRel="{ item }">
            <v-icon
              v-if="isTrackInRelease(item.id)"
              small
              color="accent"
            >mdi-check</v-icon>
          </template>
          <template v-slot:item.isInRelAndPubl="{ item }">
            <v-icon
              v-if="isTrackInReleaseAndPublished(item.id)"
              small
              color="accent"
            >mdi-check</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateTracks,
  mapActions: mapActionsTracks
} = createNamespacedHelpers("tracks");

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases,
} = createNamespacedHelpers("releases");

export default {
  name: "AdminTracks",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "ISRC", value: "ISRC" },
      { text: "Shop", value: "purchaseEnabled" },
      { text: "Txt", value: "descriptionTextArea" },
      { text: "Rel", value: "isInRel" },
      { text: "Publ", value: "isInRelAndPubl" }
    ],
    search: "",
    dialog: false,
    myTableState: {}
  }),
  async created() {
    this.myTableState = this.getTableState("tracksTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "ISRC";
      this.myTableState.sortDesc = true;
    }
    await this.fetchReleases();
    this.fetchTracks();
  },
  computed: {
    ...mapStateTracks(["tracks"]),
    ...mapStateReleases(["releases"]),
    
  },
  methods: {
    ...mapActionsTracks(["fetchTracks", "createTrack"]),
    ...mapActionsReleases(["fetchReleases"]),
    async doCreateTrack() {
      const newTrackID = await this.getObjectId();
      console.log("newTrackID " + newTrackID);
      this.$router.push({ path: "/admin/track/" + newTrackID });
    },
    isTrackInRelease(id)
    {
        let ids = this.releases.find(r => r.refTrack.find(t => t === id ))
        //console.log("found rel " + JSON.stringify(ids,null,2))
        return ids ? ids.id:null;
    },
    isTrackInReleaseAndPublished(id)
    {
        let ids = this.releases.find(r => r.publish && r.refTrack.find(t => t === id ))
        //console.log("found rel " + JSON.stringify(ids,null,2))
        return ids ? ids.id:null;
    },

    rowClickDetail(e) {
      console.log("rowClickDetail " + e.id);
      this.$router.push({ path: "/admin/track/" + e.id });
    }
  }
};
</script>