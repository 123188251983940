<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{name:'admevents'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1" v-if="!isnew">
          EDIT EVENT
          <br />
        </div>
        <div class="font-weight-thin display-1" v-if="isnew">
          NEW EVENT
          <br />
        </div>
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="event"
      schemaName="Event"
      :formData="eventData"
      namespace="events"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row justify="end" class="pa-2">

        <v-btn :to="{name:'admevents'}" class="ma-1">
          Cancel
          <v-icon right>mdi-close-circle</v-icon>
        </v-btn>
        <v-btn  @click="dialog=true" v-if="$route.params.id" class="ma-1">
          Delete
          <v-icon right>mdi-trash-can</v-icon>
        </v-btn>
        <v-btn @click="saveEvent(false)" class="ma-1">
          Save
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>

    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>Event {{eventData.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="saveEvent(true);dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapActions: mapActionsEvents,
  mapGetters: mapGettersEvents
} = createNamespacedHelpers("events");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmEvent",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    eventData: {},
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaEvent: SchemasConst.Event,
    refObjects: {}
  }),
  async created() {
    await this.fetchEvents();
    if (this.$route.params.id) {
      const ev = await this.getEventById(this.$route.params.id);
      if (ev === undefined) {
        this.eventData.id = this.$route.params.id;
        this.isnew = true;
      } else {
        this.eventData = this.getEventById(this.$route.params.id);
      }
    }
  },
  computed: {
    ...mapGettersEvents(["getEventById"])
  },
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsEvents(["fetchEvents", "updateEvent"]),
    getvModel(name) {
      return this.eventData[name];
    },
    uploadFiles() {
      this.schemaEvent.forEach(field => {
        if (field.itemType === "files") {
          if (this.eventData[field.name]) {
            this.eventData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "events/" + this.$route.params.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveEvent(deleteEvent) {
      console.log("saveEvent");
      let cmd = this.isnew ? "CREATE" : "UPDATE";
      cmd = deleteEvent ? "DELETE" : cmd;

      const success = await this.updateEvent({
        event: this.eventData,
        cmd: cmd
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admevents" });
      }
    }
  }
};
</script>