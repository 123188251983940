<template>
  <v-container>
    <v-row no-gutters dense>
      <v-col>
        <div class="glass2 mb-2">Manage Submissions</div>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="submissions"
      class="pa-0"
      must-sort
      :sort-by.sync="myTableState.sortBy"
      :sort-desc.sync="myTableState.sortDesc"
      :page.sync="myTableState.currentPage"
      :items-per-page.sync="myTableState.itemsPerPage"
      :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
      :hide-default-footer="!submissions || submissions.length <= myTableState.itemsPerPage"
    >
      <template v-slot:default="props">
        <div v-for="(sub,i) in props.items" :key="sub.id" class="pb-8">
          <v-row no-gutters>
            <v-card class="transparent-bg" dense>
              <v-card-text class="pa-0 pt-1 glass4">
                <v-row dense class="pb-0 pt-0">
                  <v-col
                    class="flex-grow-1 flex-shrink-0"
                  >{{i + 1 }}. {{sub.trackName.replace(".mp3","")}}</v-col>
                  <v-col class="pa-4 pb-0 pt-0 flex-grow-0 flex-shrink-1 d-flex align-center">
                    <v-chip
                      x-small
                      class="m-0"
                      :color="getColor(sub.accepted)"
                    >Status: {{sub.accepted == undefined ? 'In Review' : sub.accepted ? 'Accepted' : 'Declined'}}</v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pa-0 pb-0">
                <AudioPlayer
                  :heightRatio="$vuetify.breakpoint.smAndDown ? .25 :0.16"
                  :ref="'audio'+sub.id"
                  :name="sub.trackName"
                  :id="sub.id"
                  :audioUrl="ac.apiBaseURL +'pres/submissions/' + sub.userId +'/' +sub.trackId +'.mp3'"
                  :waveFormUrl="ac.apiBaseURL +'pres/submissions/' + sub.userId +'/' +sub.trackId + '.png'"
                  :waveFormUrl2="ac.apiBaseURL +'pres/submissions/' + sub.userId +'/' +sub.trackId + '2.png'"
                  @play="stopOthers(sub.id)"
                />
                <v-sheet class="pl-2">
                  <v-row dense class="pb-0 pt-0">
                    <v-col class="flex-grow-1 flex-shrink-0 caption">
                      <div>
                      Artist: {{getUserById(sub.userId).artistsName}}
                      </div>
                      <div>
                      Date Submitted: {{ moment(sub.dateSubmitted).format('lll')}}
                      </div>
                      <div v-if="sub.notes">{{sub.notes ? 'My Notes:':''}} {{sub.notes}}</div>
                      <v-divider class="mt-2 mb-1"></v-divider>
                      <div v-if="sub.dateReviewed" >
                        Date Reviewed: {{ moment(sub.dateReviewed).format('llll')}}</div>
                      <div v-if="sub.reason">
                        
                        {{ sub.reason}}
                      </div>
                    </v-col>
                    <v-col class="pa-4 pb-0 pt-0 flex-grow-0 flex-shrink-1 d-flex align-center">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark v-bind="attrs" v-on="on" icon>
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item link @click="doAction('accept',sub)">
                            <v-list-item-title>Accept</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="doAction('decline',sub)">
                            <v-list-item-title>Decline</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="doAction('inreview',sub)">
                            <v-list-item-title>In Review</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="doAction('reason',sub)">
                            <v-list-item-title>Set Reason</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="doAction('delete',sub)">
                            <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-card-text>
              <v-card-text class="pb-0 pt-0 caption"></v-card-text>
            </v-card>
          </v-row>
        </div>
      </template>
    </v-data-iterator>
    <v-dialog
      v-model="reasonDialog"
      dark
      :max-width="600"
      name="feedback"
      :overlay-opacity="0.9"
      overlay-color="#222222"
    >
      <v-card>
        <v-card-title class="font-weight-thin">Set reason for {{dialogItem.trackName}}</v-card-title>
        <v-card-text>
          <v-textarea color="accent" label="Reason" outlined v-model="dialogItem.reason"></v-textarea>

          <v-row no-gutters>
            <v-col align="right">
              <v-btn
                small
                class="mr-2"
                v-if="dialogItem.reason"
                @click="dialogItem.reason= '';doAction('submitreason',dialogItem);reasonDialog = false"
              >
                Clear
                <v-icon right>mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn small class="mr-2" @click="reasonDialog = false">
                Cancel
                <v-icon right>mdi-close-outline</v-icon>
              </v-btn>
              <v-btn small @click="doAction('submitreason',dialogItem);reasonDialog = false">
                Save
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import AudioPlayer from "@/components/AudioPlayer.vue";
import moment from "moment";

const {
  mapActions: mapActionsSubmissions,
  mapState: mapStateSubmissions
} = createNamespacedHelpers("submissions");

const {
  mapState: mapStateUsers,
  mapActions: mapActionsUsers,
  mapGetters: mapGettersUsers
} = createNamespacedHelpers("users");

export default {
  name: "SignIn",
  mixins: [defaultMixin],
  components: {
    AudioPlayer
  },
  data() {
    return {
      show: false,
      notes: "",
      file: null,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match"
      },
      myTableState: {},
      reasonDialog: false,
      dialogItem: {}
    };
  },
  async created() {
    this.myTableState = this.getTableState("releaseTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateRelease";
      this.myTableState.sortDesc = true;
    }
    await this.fetchUsers();
    await this.fetchSubmissions();
  },
  computed: {
    ...mapStateSubmissions(["submissions"]),
    ...mapStateUsers(["users"]),
    ...mapGettersUsers(["getUserById"])
  },
  methods: {
    ...mapActionsSubmissions(["fetchSubmissions", "updateSubmissions"]),
    ...mapActionsUsers(["fetchUsers"]),
    getColor(inp) {
      return inp == undefined ? "orange" : inp ? "green" : "red";
    },
    async doAction(action, sub) {
      console.log("setState " + action + " " + sub.id);

      if (action === "delete") {
        await this.updateSubmissions({
          command: "DELETE",
          submission: sub
        });

        await this.fetchSubmissions();
      }

      if (action === "accept") {
        sub.accepted = true;
        sub.dateReviewed = new Date();
        await this.updateSubmissions({
          command: "UPDATE",
          submission: sub
        });

        await this.fetchSubmissions();
      }

      if (action === "decline") {
        sub.accepted = false;
        sub.dateReviewed = new Date();
        await this.updateSubmissions({
          command: "UPDATE",
          submission: sub
        });

        await this.fetchSubmissions();
      }

      if (action === "inreview") {
        sub.accepted = null;
        sub.dateReviewed = null;
        await this.updateSubmissions({
          command: "UPDATE",
          submission: sub
        });

        await this.fetchSubmissions();
      }

      if (action === "reason") {
        this.reasonDialog = true;
        this.dialogItem = sub;
      }

      if (action === "submitreason") {
        await this.updateSubmissions({
          command: "UPDATE",
          submission: sub
        });

        await this.fetchSubmissions();
      }
    },
    stopOthers(id) {
      console.log("stop except : " + id);
      let thisId = id;

      this.submissions.forEach(sub => {
        if (sub.id != thisId) {
          this.$refs["audio" + sub.id][0].stop();
        }
      });
    }
  }
};
</script>