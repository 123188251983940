<template>
  <v-container class="pa-0">
    <div v-if="!resetSent">
      <v-form class="ma-4">
        <v-row no-gutters>
          <v-col>
            <div class="glass2 mb-4">
              RESET PASSOWRD
              <br />
            </div>

            <v-text-field label="User email" v-model="username" autofocus outlined color="accent"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense>
          <v-col>
            <div class="font-weight-light body-2 mb-2">
              <router-link :to="{name:'signin'}">Sign in</router-link>
            </div>
          </v-col>
          <v-col align="right">
            <v-btn @click="sendResetLinkAction">
              Send Reset Link
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-if="resetSent">
      <v-form class="ma-4">
        <v-row no-gutters>
          <v-col>
            <div class="glass2 mb-4">
              RESET PASSOWRD
              <br />
            </div>

            <v-text-field
              label="Challenge Code"
              v-model="challengeCode"
              autofocus
              outlined
              color="accent"
              autocomplete="off"
              ref="challengeCode"
              dense
            ></v-text-field>

            <v-text-field
              autocomplete="new-password"
              v-model="password"
              label="Password"
              outlined
              color="accent"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              ref="passwordField"
              hint
              @click:append="show = !show"
              dense
            ></v-text-field>
            <v-text-field
              autocomplete="new-password"
              v-model="passwordRepeat"
              label="Repeat password"
              outlined
              color="accent"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              @click:append="show = !show"
              ref="passwordRepeatField"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense>
          <v-col>
            <div class="font-weight-light body-2 mb-2">
              <router-link :to="{name:'signin'}">Sign in</router-link>
            </div>
          </v-col>
          <v-col align="right">
            <v-btn @click="sendResetPasswordAction">
              Reset
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";

export default {
  name: "ResetPassword",
  mixins: [defaultMixin],
  data() {
    return {
      show: false,
      password: "",
      passwordRepeat: "",
      username: "",
      challengeCode: "",
      resetSent: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match"
      }
    };
  },
  created() {
    if (this.$route.params.cc) {
      this.challengeCode = this.$route.params.cc;
      this.resetSent = true;
    }
  },
  computed: {},
  methods: {
    async sendResetLinkAction() {
      const success = await this.sendPasswordReset({
        email: this.username,
        p: this.password
      });
      // eslint-disable-next-line
      console.log("sendResetLinkAction : " + success);
      this.resetSent = success;
    },
    async sendResetPasswordAction() {
      const success = await this.resetPassword({
        p: this.password,
        pr: this.passwordRepeat,
        cc: this.challengeCode
      });
      // eslint-disable-next-line
      console.log("sendResetLinkAction : " + success);
      if (success) {
        this.setMessage({
          message: "New passowrd set",
          dialog: true,
          type: "success",
          timeout: 1500
        });
        this.$router.push({ name: "signin" });
      }
    }
  }
};
</script>