<template>
  <v-container>
    <v-row no-gutters class="mb-2">
      <v-col class="font-weight-thin display-1 pl-1">Releases</v-col>
    </v-row>
    <v-data-iterator :items="pubreleases" class="pa-0"
    :items-per-page="myTableState.itemsPerPage"
    :hide-default-footer="pubreleases.length <= myTableState.itemsPerPage"
    >
      <template v-slot:default="props">
        <v-card
          v-for="release in props.items" :key="release.id"
          :to="{name:'release',params:{id:release.id,releasename:normalizeUrl(release.name)}}"
          outlined
          class="translucent-bg-1 mb-3"
        
          dense
        >
          <v-card-title class="pl-5 pb-1 pt-1 font-weight-thin title">{{release.name}}</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col :cols="$vuetify.breakpoint.smAndUp ? 3 : 12">
                <v-img :src="getFileUrl(release)" aspect-ratio="1"></v-img>
              </v-col>
              <v-col class="pl-4">
                <!-- <v-row no-gutters>
                  <v-col class="font-weight-thin title">{{release.name}}</v-col>
                </v-row>-->
                <v-row no-gutters>
                  <v-col class="font-weight-thin caption">Release Date: {{release.dateRelease}}</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="font-weight-thin caption">CatNo:: {{release.catNo}}</v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
                <v-simple-table dense class="transparent-bg">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(track, i) in release.tracks" :key="track.id">
                        <td class="font-weight-thin caption pa-0">{{i + 1 + "."}}</td>
                        <td class="font-weight-thin caption pa-0">{{track.name}} - {{track.artists}}</td>
                        <td class="font-weight-thin caption pa-0"></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-divider class="mb-4"></v-divider>
                <v-row no-gutters>
                  <v-col align="right"><v-btn  x-small>details</v-btn></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");

export default {
  name: "Releases",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Release date", value: "dateRelease" },
      { text: "", value: "action", sortable: false, align: "right" }
    ],
    search: "",
    dialog: false,
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("releaseTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateRelease";
      this.myTableState.sortDesc = true;
    }
    this.fetchPubReleases();
  },
  computed: {
    ...mapStateReleases(["pubreleases"])
  },
  methods: {
    ...mapActionsReleases(["fetchPubReleases"]),
    getFileUrl(release) {
      if (release.filesImageArtWork) {
        let fileEntity = release.filesImageArtWork[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "releases" +
            "/" +
            release.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }
};
</script>