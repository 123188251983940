import axios from 'axios';

const SIGN_IN_PENDING = 'SIGN_IN_PENDING';
const SIGN_IN_FULFILLED = 'SIGN_IN_FULFILLED';
const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

const SIGN_UP_PENDING = 'SIGN_UP_PENDING';
const SIGN_UP_FULFILLED = 'SIGN_UP_FULFILLED';
const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

const SIGN_OUT_PENDING = 'SIGN_OUT_PENDING';
const SIGN_OUT_FULFILLED = 'SIGN_OUT_FULFILLED';
const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

const SET_REDIRECT_AFTER_LOGIN = 'SET_REDIRECT_AFTER_LOGIN';

const SET_ACCESSTOKEN = 'SET_ACCESSTOKEN';

const RESET_PASSWORD = 'RESET_PASSWORD';

const state = {

    signInPending: false,
    signUpPending: false,
    signOutPending: false,
    isAdmin: false,
    isEditor: false,
    isMember: false,
    isSignedIn: false,
    isShopper: false,
    userId: '',
    redirectAfterLogin: null,
    accessToken: '',
    init: false,
    paypalConnectURL: '',
    prodShopEnabled: false
};
const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [SIGN_IN_PENDING](state) { state.signInPending = true; },
    [SIGN_IN_FULFILLED](state, response) {
        state.isAdmin = response.isAdmin;
        state.isEditor = response.isEditor;
        state.isMember = response.isMember;
        state.isSignedIn = response.isSignedIn;
        state.userId = response.userId;
        state.isShopper = response.isShopper;
        state.signInPending = false;
        state.accessToken = response.ac;
        state.init = true;
        state.paypalConnectURL = response.paypalConnectURL;
        state.prodShopEnabled = response.prodShopEnabled;
    },
    [SIGN_IN_ERROR](state) { state.signInPending = false; },

    [SIGN_OUT_PENDING](state) { state.signOutPending = true; },
    [SIGN_OUT_FULFILLED](state) {
        state.isAdmin = false;
        state.isEditor = false;
        state.isMember = false;
        state.isSignedIn = false;
        state.userId = '';
        state.signOutPending = false;
    },
    [SIGN_OUT_ERROR](state) { state.signOutPending = false; },
    [SET_REDIRECT_AFTER_LOGIN](state, val) { state.redirectAfterLogin = val; },
    [SET_ACCESSTOKEN](state, accessToken) { state.accessToken = accessToken; },


    [SIGN_UP_PENDING](state) { state.signUpPending = true; },
    [SIGN_UP_FULFILLED](state) {
        state.signUpPending = false;
    },
    [SIGN_UP_ERROR](state) { state.signUpPending = false; },

};
const actions = {
    async signInState({ commit, dispatch }) {
        commit(SIGN_IN_PENDING);
        // eslint-disable-next-line
        console.log("signInState");

        try {
            const url = 'auth';
            const response = await axios.get(url);
            console.log(JSON.stringify(response.data, null, 2))

            if (response.data.status[0] === "OK") {
                commit(SIGN_IN_FULFILLED, response.data);
                return true;

            } else {
                commit(SIGN_IN_FULFILLED, response.data);
                return false
            }


        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }

    },
    async signIn({ commit, dispatch }, args) {
        commit(SIGN_IN_PENDING);
        // eslint-disable-next-line
        console.log("SIGN IN");

        try {
            const url = 'auth';

            const command = {
                "apiCommand": "SIGN_IN",
                "email": args.u,
                "password": args.p,
            }

            const response = await axios.post(url, command);

            if (response.data.status[0] === "OK") {
                commit(SIGN_IN_FULFILLED, response.data);
                dispatch('dialog/setMessage', { message: 'Sign in success', dialog: true, type: 'success' }, {
                    root: true
                });
                return true;

            } else {
                commit(SIGN_IN_FULFILLED, response.data);
                dispatch('dialog/setMessage', { message: 'Wrong Credentials', dialog: true, type: 'warn', add: true }, {
                    root: true
                });
                return false
            }

        } catch (error) {

            dispatch('dialog/setMessage', { message: 'Wrong Credentials!', dialog: true, add: true, type: 'error', timeout: 2000 }, {
                root: true
            });

            return false;
        }

    },
    async signUp({ commit, dispatch }, args) {
        commit(SIGN_UP_PENDING);
        // eslint-disable-next-line
        console.log("SIGN UP");

        try {
            const url = 'auth';

            const command = {
                "apiCommand": "SIGN_UP",
                "email": args.e,
                "password": args.p,
                "passwordRepeat": args.pr,
                "artistName": args.an,
                "socialMediaLink": args.sl
            }
            /*
          e: this.useremail, 
          p: this.password, 
          pr: this.passwordRepeat, 
          an: this.artistName, 
          sl: this.soundCloudLink, 
            */

            const response = await axios.post(url, command);

            if (response.data.status.includes("OK")) {
                commit(SIGN_UP_FULFILLED, response.data);
                return response.data;

            }

        } catch (error) {

            dispatch('dialog/setMessage', { message: 'Sign Up', dialog: true, add: true, type: 'error', timeout: 2000 }, {
                root: true
            });

            return false;
        }

    },
    async sendPasswordReset({ dispatch }, args) {

        console.log("SEND_PASSWORD_RESET");

        try {
            const url = 'auth';

            const command = {
                "apiCommand": "SEND_PASSWORD_RESET",
                "email": args.email,
            }

            const response = await axios.post(url, command);

            if (response.data.status.includes("PASSWORD_RESET_SENT")) {
                return true;
            }

        } catch (error) {

            dispatch('dialog/setMessage', { message: 'Bad request', dialog: true, add: true, type: 'error', timeout: 2000 }, {
                root: true
            });

            return false;
        }

    },
    async resetPassword({ dispatch }, args) {

        console.log("SEND_PASSWORD_RESET");

        try {
            const url = 'auth';

            const command = {
                "apiCommand": "RESET_PASSWORD",
                "challengeCode": args.cc,
                "password": args.p,
                "passwordRepeat": args.pr,
            }

            const response = await axios.post(url, command);

            if (response.data.status.includes("PASSWORD_RESET_SUCCESS")) {
                return true;
            } else if (response.data.status.includes("PASSWORD_TO_SHORT")) {
                dispatch('dialog/setMessage', { message: 'Password too short', dialog: true, add: true, type: 'error', timeout: 1500 }, {
                    root: true
                });
                return false;
            } else if (response.data.status.includes("PASSWORD_NOT_MATCH")) {
                dispatch('dialog/setMessage', { message: 'Passwords do not match', dialog: true, add: true, type: 'error', timeout: 1500 }, {
                    root: true
                });
                return false;
            } else if (response.data.status.includes("NOT_FOUND")) {
                dispatch('dialog/setMessage', { message: 'Not found', dialog: true, add: true, type: 'error', timeout: 1500 }, {
                    root: true
                });
                return false;
            }
            return false;

        } catch (error) {

            dispatch('dialog/setMessage', { message: error, dialog: true, add: true, type: 'error', timeout: 2000 }, {
                root: true
            });

            return false;
        }

    },
    async activateAccount({ commit, dispatch }, args) {

        console.log("ACTIVATE_ACCOUNT");

        try {
            const url = 'auth';

            const command = {
                "apiCommand": "ACTIVATE_ACCOUNT",
                "challengeCode": args.cc
            }

            const response = await axios.post(url, command);

            if (response.data.status.includes("OK")) {
                commit(SIGN_IN_FULFILLED, response.data);
                return true;
            }

        } catch (error) {

            dispatch('dialog/setMessage', { message: 'Bad request', dialog: true, add: true, type: 'error', timeout: 2000 }, {
                root: true
            });

            return false;
        }

    },
    async signOut({ commit, dispatch }) {
        commit(SIGN_OUT_PENDING);
        try {
            const url = 'auth';
            const command = {
                "apiCommand": "SIGN_OUT",
            }
            const response = await axios.post(url, command);
            if (response.data.status[0] === "OK") {
                commit(SIGN_OUT_FULFILLED, response.data);
                return true;
            } else {
                commit(SIGN_OUT_FULFILLED, response.data);
                return false
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }

    },
    async handleToken({ commit }, accesstoken) {

        console.log("HANDLE TOKEN " + accesstoken);

        commit('SET_ACCESSTOKEN', accesstoken)

        return true;

    },
};

const getters = {
    getUserLanguage: (state) => {
        return state.language;
    },
    getUserCurrency: (state) => {
        return state.currency;
    },
    getUserAccessToken: (state) => {
        return state.accessToken;
    },
    getUserHierarchyId: (state) => {
        return state.hierarchyId;
    },
    getUserSignedIn: (state) => {
        return state.isSignedIn;
    },
    getUserIsAdmin: (state) => {
        return state.isAdmin;
    },
    getUserIsEditor: (state) => {
        return state.isEditor;
    },
    getUserIsMember: (state) => {
        return state.isMember;
    },
    getUserInit: (state) => {
        return state.init;
    },
    getPaypalConnectURL: (state) => {
        return state.paypalConnectURL;
    },
    getProdShopEnabled: (state) => {
        return state.prodShopEnabled;
    },
    getRedirectAfterLogin: (state) => {
        return state.redirectAfterLogin;
    }
};




export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};