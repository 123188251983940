<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{name:'admin'}" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateEvent">
          Create Event
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="events"
          :search="search"
          :headers="headers"
          class="transparent-bg"
          must-sort
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          calculate-widths
          @click:row="rowClickDetail"
          style="cursor:pointer"
        >
          <template v-slot:item.action="{ item }"  >
            <v-icon
              v-if="!item.filesImageEvent || item.filesImageEvent.lenght == 0 "
              small
            >mdi-alert</v-icon>
          </template>

          <template v-slot:item.publish="{ item }">
            <v-icon v-if="item.publish" small color="accent" class="ma-0">mdi-check</v-icon>
          </template>

          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateEvents,
  mapActions: mapActionsEvents,
} = createNamespacedHelpers("events");

export default {
  name: "AdminEvents",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Date", value: "date" },
      { text: "Publ", value: "publish", width:"20" ,align: "center" },
      { text: "", value: "action", sortable: false, align: "right", width:"20" }
    ],
    search: "",
    dialog: false,
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("eventsTable");
    if(!this.myTableState.sortBy)
    {
      this.myTableState.sortBy = "date";
      this.myTableState.sortDesc = true;
    }
    this.fetchEvents();
  },
  computed: {
    ...mapStateEvents(["events"])
  },
  methods: {
    ...mapActionsEvents(["fetchEvents", "createEvent"]),
    async doCreateEvent() {
      const newEventID = await this.getObjectId();
      console.log("newEventID " + newEventID);
      this.$router.push({ path: "/admin/event/" + newEventID });
    },
    rowClickDetail(e)
    {
      console.log("rowClickDetail " + e.id);
      this.$router.push({path:'/admin/event/'+ e.id});
    },
  }
};
</script>