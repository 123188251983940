import axios from 'axios';
import { appconfig } from '@/config';


const ORDER_ACTION_PENDING = 'ORDER_ACTION_PENDING';
const ORDER_ACTION_FULFILLED = 'ORDER_ACTION_FULFILLED';
const ORDER_ACTION_ERROR = 'ORDER_ACTION_ERROR';

const PLACE_ORDER_PENDING = 'PLACE_ORDER_PENDING';
const PLACE_ORDER_FULFILLED = 'PLACE_ORDER_FULFILLED';
const PLACE_ORDER_ERROR = 'PLACE_ORDER_ERROR';

const FETCH_CART_PENDING = 'FETCH_CART_PENDING';
const FETCH_CART_FULFILLED = 'FETCH_CART_FULFILLED';
const FETCH_CART_ERROR = 'FETCH_CART_ERROR';

const FETCH_ORDER_PENDING = 'FETCH_ORDER_PENDING';
const FETCH_ORDER_FULFILLED = 'FETCH_ORDER_FULFILLED';
const FETCH_ORDER_ERROR = 'FETCH_ORDER_ERROR';

const FETCH_ORDERS_PENDING = 'FETCH_ORDERS_PENDING';
const FETCH_ORDERS_FULFILLED = 'FETCH_ORDERS_FULFILLED';
const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';

const CHECK_PAYPAL_OAUTH_PENDING = 'CHECK_PAYPAL_OAUTH_PENDING';
const CHECK_PAYPAL_OAUTH_FULFILLED = 'CHECK_PAYPAL_OAUTH_FULFILLED';
const CHECK_PAYPAL_OAUTH_ERROR = 'CHECK_PAYPAL_OAUTH_ERROR';

const state = {

    cart: null,
    orders: [],
    order: null,
    orderActionPending: false,
    fetchCartPending: false,
    fetchOrderPending: false,
    fetchOrdersPending: false,
    newPurchase: false,
    checkPayPalOauthPending : false
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [ORDER_ACTION_PENDING](state) { state.orderActionPending = true; },
    [ORDER_ACTION_FULFILLED](state) { state.orderActionPending = false; },
    [ORDER_ACTION_ERROR](state) { state.orderActionPending = false; },

    [PLACE_ORDER_PENDING](state) { state.placeOrderPending = true; },
    [PLACE_ORDER_FULFILLED](state) { state.placeOrderPending = false; },
    [PLACE_ORDER_ERROR](state) { state.placeOrderPending = false; },

    [FETCH_CART_PENDING](state) { state.fetchCartPending = true; },
    [FETCH_CART_FULFILLED](state, shopOrder) {
        state.cart = shopOrder;
        state.fetchCartPending = false;
    },
    [FETCH_CART_ERROR](state) { state.fetchCartPending = false; },

    [FETCH_ORDER_PENDING](state) { state.fetchOrderPending = true; },
    [FETCH_ORDER_FULFILLED](state, args) {
        state.order = args.shopOrder;
        state.newPurchase = args.np;
        state.fetchOrderPending = false;
    },
    [FETCH_ORDER_ERROR](state) { state.fetchOrderPending = false; },

    [FETCH_ORDERS_PENDING](state) { state.fetchOrdersPending = true; },
    [FETCH_ORDERS_FULFILLED](state, shopOrders) {
        state.orders = shopOrders;
        state.fetchOrdersPending = false;
    },
    [FETCH_ORDERS_ERROR](state) { state.fetchOrdersPending = false; },

    [CHECK_PAYPAL_OAUTH_PENDING](state) { state.checkPayPalOauthPending = true; },
    [CHECK_PAYPAL_OAUTH_FULFILLED](state) { state.checkPayPalOauthPending = false; },
    [CHECK_PAYPAL_OAUTH_ERROR](state) { state.checkPayPalOauthPending = false; },

};


const actions = {
    async orderAction({ dispatch, commit }, args) {
        commit(ORDER_ACTION_PENDING);
        console.log("ORDER ACTION " + args.cmd);
        try {
            const url = 'shop';

            const postdata = {
                apiCommand: args.cmd,
                productId: args.productId
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            console.log("->" + response.data.status[0])
            if (response.data.status[0] === "ITEM_ALREADY_IN_CART") {
                commit(ORDER_ACTION_FULFILLED);
                dispatch('dialog/setMessage', { message: 'Item already bagged', dialog: true, type: 'warning' }, {
                    root: true
                });
            } else if (response.data.status[0] === "ITEM_ADDED_TO_CART") {

                commit(ORDER_ACTION_FULFILLED);
                dispatch('dialog/setMessage', { message: 'Item added to Cart', dialog: true, type: 'success' }, {
                    root: true
                });

                dispatch('fetchCart', {
                    root: true
                });

                return true;
            } else if (response.data.status[0] === "CART_UPDATED") {
                commit(ORDER_ACTION_FULFILLED);
                dispatch('dialog/setMessage', { message: 'Cart updated', dialog: true, type: 'success' }, {
                    root: true
                });

                dispatch('fetchCart', {
                    root: true
                });

                return true;
            } else {
                commit(ORDER_ACTION_ERROR);
                return true;
            }
        } catch (error) {
            commit(ORDER_ACTION_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return true;
        }
    },
    async placeOrder({ dispatch, commit }) {



        commit(PLACE_ORDER_PENDING);
        console.log("PLACE_ORDER ");
        try {
            const url = 'shop';

            const postdata = {
                apiCommand: "PLACE_ORDER",
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                console.log("redirection to " + response.data.payLink);
                //window.location.replace( response.data.payLink);
                commit(PLACE_ORDER_FULFILLED);
                window.location.href = response.data.payLink;
                return true;
            } else {
                commit(PLACE_ORDER_ERROR);
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });
            commit(PLACE_ORDER_ERROR);

            return true;
        }
    },
    async fetchCart({ dispatch, commit }) {
        commit(FETCH_CART_PENDING);
        console.log("FETCH_CART ");
        try {
            const url = 'shop';

            const postdata = {
                apiCommand: "FETCH_CART",
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {

                commit(FETCH_CART_FULFILLED, response.data.shopOrder);

                return true;
            } else {
                commit(FETCH_CART_ERROR);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });
            commit(FETCH_CART_ERROR);
            return true;
        }
    },
    async fetchOrder({ dispatch, commit }, args) {
        commit(FETCH_ORDER_PENDING);
        
        try {
            const url = 'shop';

            const postdata = {
                apiCommand: "FETCH_ORDER",
                orderId: args.orderId
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            console.log("FETCH_ORDER "+ response.data.status[0]);
            if (response.data.status[0] === "OK") {
                commit(FETCH_ORDER_FULFILLED, {shopOrder:response.data.shopOrder,np:false});
                dispatch('fetchCart', {
                    root: true
                });

                return true;
            } else if (response.data.status[0] === "PURCHASE_SUCCESS") {
                commit(FETCH_ORDER_FULFILLED, {shopOrder:response.data.shopOrder,np:true});
                dispatch('fetchCart', {
                    root: true
                });

                return true;
            } else {
                commit(FETCH_ORDER_ERROR);
                return false;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });
            commit(FETCH_ORDER_ERROR);

            return false;
        }
    },
    async fetchOrders({ dispatch, commit }) {
        commit(FETCH_ORDERS_PENDING);
        console.log("FETCH_ORDERS");
        try {
            const url = 'shop';

            const postdata = {
                apiCommand: "FETCH_ORDERS",
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                commit(FETCH_ORDERS_FULFILLED, response.data.shopOrders);
                dispatch('fetchCart', {
                    root: true
                });

                return true;
            } else {
                commit(FETCH_ORDERS_ERROR);
                return false;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });
            commit(FETCH_ORDERS_ERROR);

            return false;
        }
    },
    async downloadOrder({ dispatch, commit }, args) {
        commit(PLACE_ORDER_PENDING);
        console.log("DOWNLOAD_ORDER " + args.audioFormat);
        try {
            const url = 'shop';

            const postdata = {
                apiCommand: "DOWNLOAD_ORDER",
                audioFormat: args.audioFormat,
                orderId: args.orderId
            }

            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                console.log("redirection to " + response.data.payLink);
                //window.location.replace( response.data.payLink);
                commit(PLACE_ORDER_FULFILLED);
                window.location.href = response.data.downloadLink;
                return true;
            } else {
                commit(PLACE_ORDER_ERROR);
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });
            commit(PLACE_ORDER_ERROR);

            return true;
        }
    },
    async checkPayPalAccessOauthToken({ dispatch, commit }, clientToken) {
        console.log("CHECK_PAYPAL_OAUTH " + clientToken);
        commit(CHECK_PAYPAL_OAUTH_PENDING);
        try {
            const url = 'shop';

            const postdata = {
                apiCommand: "OAUTH_PAYPAL",
                payPalClientToken: clientToken,
            }

            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                //window.location.replace( response.data.payLink);
                commit(CHECK_PAYPAL_OAUTH_FULFILLED);

                dispatch('fetchOrders', {
                    root: true
                });
  
                return true;
            } else {
                commit(CHECK_PAYPAL_OAUTH_ERROR);
                return false;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });
            commit(CHECK_PAYPAL_OAUTH_ERROR);

            return true;
        }
    },

}



export default {
    state,
    actions,
    mutations,
    namespaced: true,
};