<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{ path: $store.state.route.from.fullPath }" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1" v-if="!isnew">
          EDIT TRACK
          <br />
        </div>
        <div class="font-weight-thin display-1" v-if="isnew">
          NEW TRACK
          <br />
        </div>
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="track"
      schemaName="Track"
      :formData="trackData"
      namespace="tracks"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row justify="end" class="pa-2">
      <v-btn v-if="releasesTrackIsIn" :to="{name:'admrelease',params:{id:releasesTrackIsIn}}" class="ma-1">
        Goto Release
        <v-icon right>mdi-close-circle</v-icon>
      </v-btn>
      <v-btn :to="{name:'admtracks'}" class="ma-1">
        Cancel
        <v-icon right>mdi-close-circle</v-icon>
      </v-btn>
      <v-btn @click="dialog=true" v-if="$route.params.id" class="ma-1">
        Delete
        <v-icon right>mdi-trash-can</v-icon>
      </v-btn>
      <v-btn @click="saveTrack(false)" class="ma-1">
        Save
        <v-icon right>mdi-content-save</v-icon>
      </v-btn>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>Track {{trackData.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="saveTrack(true);dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapActions: mapActionsTracks,
  mapGetters: mapGettersTracks
} = createNamespacedHelpers("tracks");

const {
  mapActions: mapActionsArtists,
  mapState: mapStateArtists
} = createNamespacedHelpers("artists");

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmTrack",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    trackData: {
      id: null
    },
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaTrack: SchemasConst.Track,
    refObjects: {}
  }),
  async created() {
    await this.fetchTracks();
    await this.fetchArtists();
    await this.fetchReleases();
    this.refObjects["refReview"] = [];
    this.refObjects["refArtist"] = this.artists;
    if (this.$route.params.id) {
      const ev = await this.getTrackById(this.$route.params.id);
      if (ev === undefined) {
        const lastTrack = await this.getLastTrack;
        if (lastTrack != null) {
          this.trackData.ISRC = lastTrack.ISRC;
          this.trackData.price = lastTrack.price;
        }
        this.trackData.id = this.$route.params.id;
        this.isnew = true;
      } else {
        this.trackData = this.getTrackById(this.$route.params.id);
      }
    }
  },
  computed: {
    ...mapGettersTracks(["getTrackById","getLastTrack"]),
    ...mapStateArtists(["artists"]),
    ...mapStateReleases(["releases"]),
    releasesTrackIsIn: {
      get: function() {
        let ids = this.releases.find(r => r.refTrack.find(t => t === this.$route.params.id ))
        //console.log("found rel " + JSON.stringify(ids,null,2))
        return ids ? ids.id:null;
      }
    },
  },
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsTracks(["fetchTracks", "updateTrack"]),
    ...mapActionsArtists(["fetchArtists"]),
    ...mapActionsReleases(["fetchReleases"]),
    getvModel(name) {
      return this.trackData[name];
    },
    uploadFiles() {
      //TODO: make generic
      this.schemaTrack.forEach(field => {
        if (field.itemType === "files") {
          if (this.trackData[field.name]) {
            this.trackData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "tracks/" + this.$route.params.id,
                  file.id + file.ext,
                  field.isprotected
                );
              }
            });
          }
        }
      });
    },
    async saveTrack(deleteTrack) {
      console.log("saveTrack");
      let cmd = this.isnew ? "CREATE" : "UPDATE";
      cmd = deleteTrack ? "DELETE" : cmd;

      const success = await this.updateTrack({
        track: this.trackData,
        cmd: cmd
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admtracks" });
      }
    }
  }
};
</script>