const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
const SET_DOWNLOAD_PROGRESS = 'SET_DOWNLOAD_PROGRESS';



const state = {
    uploadProgress: [],
    downloadProgressItems: [],
    downloadProgress: 0,
    tableStates: {},
    accessToken: ''
}
const getters = {
    getTableState: (state) => (id) => {
        if (!state.tableStates[id]) {
            state.tableStates[id] = {
                itemsPerPage: 12,
                currentPage: 0,
                sortBy: '',
                sortDesc: false,
                search: '',
                selectedItems: [],
                isGridView : false,
                itemsPerPageOptions : [4,8,12,16,32,-1]
            }
        }
        return state.tableStates[id];
    }
}
const mutations = {
    [SET_UPLOAD_PROGRESS](state, val) {

        let item = state.uploadProgress.find(x => x.id === val.id)

        if (item !== undefined) {
            if (val.progress > -1) {
                item.progress = val.progress;
            } else {
                state.uploadProgress = state.uploadProgress.filter(item => {
                    return item.id != val.id;
                });

            }
        } else {
            state.uploadProgress.push(val);
        }
    },
    [SET_DOWNLOAD_PROGRESS](state, inputProgress) {

        if (inputProgress) {
            let currentProgress = state.downloadProgressItems.find(x => x.id === inputProgress.id);
            if (currentProgress) {
                if (inputProgress.loaded >= inputProgress.total) {
                    state.downloadProgressItems = state.downloadProgressItems.filter(x => x.id !== inputProgress.id)
                } else {
                    currentProgress.loaded = inputProgress.loaded;
                    currentProgress.total = inputProgress.total;
                }
            } else {
                state.downloadProgressItems.push(inputProgress);
            }

        }

        let loaded = state.downloadProgressItems.reduce((a, item) => a + item.loaded, 0);
        let total = state.downloadProgressItems.reduce((a, item) => a + item.total, 0);

        state.downloadProgress = Math.round((loaded * 100) / total) || 0;

        //console.log("SET_DOWNLOAD_PROGRESS " + JSON.stringify(inputProgress) + " len " + state.downloadProgressItems.length + " l : " + loaded + " t : " + total + " p : " + state.downloadProgress);



    },

}
const actions = {


}


export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};