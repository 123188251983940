<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateMixTape">
          Create MixTape
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="mixtapes"
          :search="search"
          :headers="headers"
          class="transparent-bg"
          must-sort
          dense
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
                    :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          style="cursor:pointer"
        >
          <template v-slot:item.action="{ item }" color="secondary">
            <v-btn :to="{path:'/admin/mixtape/'+ item.id}" text depressed icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.publish="{ item }">
            <v-icon v-if="item.publish" small color="accent">mdi-check</v-icon>
          </template>

          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateMixTapes,
  mapActions: mapActionsMixTapes,
} = createNamespacedHelpers("mixtapes");

export default {
  name: "AdminMixTapes",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Artist", value: "artist" },
      { text: "Name", value: "name" },
      { text: "Date", value: "date" },
      { text: "Published", value: "publish", align: "center" },
      { text: "Action", value: "action", align: "center" }
    ],
    search: "",
    dialog: false,
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("tableMixtapes");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "date";
      this.myTableState.sortDesc = true;
    }
    this.fetchMixTapes();
  },
  computed: {
    ...mapStateMixTapes(["mixtapes"])
  },
  methods: {
    ...mapActionsMixTapes(["fetchMixTapes", "createMixTape"]),
    async doCreateMixTape() {
      const newMixTapeID = await this.getObjectId();
      console.log("newMixTapeID " + newMixTapeID);
      this.$router.push({ path: "/admin/mixtape/" + newMixTapeID });
    },
    rowClickDetail(e) {
      console.log("rowClickDetail " + e.id);
      this.$router.push({ path: "/admin/mixtape/" + e.id });
    }
  }
};
</script>