<template>
  <div style="position:fixed;z-index:1000;width:100%;left:0px;top:50px">
  <v-container class="pa-0 maxw" >
      <v-expand-transition v-for="m in messages" :key="m.id" :name="m.id" group="true">

        <v-alert dense class="ma-0 mt-1 mr-4 ml-4" :type="m.type" prominent>
          {{ m.message }}
          <template slot="close">
            <v-btn @click="dismissMessage2(m.id)" :name="m.id" icon depressed>
              <v-icon size="24px">mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <template slot="prepend"></template>
        </v-alert>

      </v-expand-transition>
  </v-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: mapActionsDialog,
  mapState: mapStateDialog
} = createNamespacedHelpers("dialog");

export default {
  computed: {
    ...mapStateDialog(["message", "dialog", "messages"])
  },
  methods: {
    ...mapActionsDialog(["dismissMessage", "dismissMessage2"])
  }
};
</script>