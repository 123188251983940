<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right" class="font-weight-thin display-1  mb-1">

          SETTINGS
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="settings"
      schemaName="AppSettings"
      :formData="settingsData"
      namespace="settings"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row justify="end" class="pa-2">
        <v-btn :to="{name:'admin'}" class="ma-1">
          Cancel
          <v-icon right>mdi-close-circle</v-icon>
        </v-btn>
        <v-btn @click="saveSettings(false)" class="ma-1">
          Save
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapActions: mapActionsSettings,
  mapGetters: mapGettersSettings
} = createNamespacedHelpers("settings");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmSettings",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    settingsData: {},
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaSettings: SchemasConst.AppSettings,
    refObjects: {},
  }),
  async created() {
    await this.fetchSettings();
    this.settingsData = this.getSettings;
    console.log("Adm Settings created");
  },
  computed: {
    ...mapGettersSettings(["getSettings"])
  },
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsSettings(["fetchSettings", "updateSettings"]),
    getvModel(name) {
      return this.settingsData[name];
    },
    uploadFiles() {
      this.schemaSettings.forEach(field => {
        if (field.itemType === "files") {
          if (this.settingsData[field.name]) {
            this.settingsData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "settings/" + this.settingsData.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveSettings(deleteArtist) {
      console.log("saveSettings");

      const success = await this.updateSettings({
        settings: this.settingsData,
        cmd: "UPDATE"
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admin" });
      }
    }
  }
};
</script>