import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_MIXTAPES_PENDING = 'FETCH_MIXTAPES_PENDING';
const FETCH_MIXTAPES_FULFILLED = 'FETCH_MIXTAPES_FULFILLED';
const FETCH_MIXTAPES_ERROR = 'FETCH_MIXTAPES_ERROR';

const UPDATE_MIXTAPE_PENDING = 'UPDATE_MIXTAPE_PENDING';
const UPDATE_MIXTAPE_FULFILLED = 'UPDATE_MIXTAPE_FULFILLED';
const UPDATE_MIXTAPE_ERROR = 'UPDATE_MIXTAPE_ERROR';


const state = {

    fetchMixTapesPending: false,
    updateMixTapePending: false,
    mixtapes: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_MIXTAPES_PENDING](state) { state.fetchMixTapesPending = true; },
    [FETCH_MIXTAPES_FULFILLED](state, mixtapes) {
        state.mixtapes = mixtapes;
        state.fetchMixTapesPending = false;
    },
    [FETCH_MIXTAPES_ERROR](state) { state.fetchMixTapesPending = false; },

    [UPDATE_MIXTAPE_PENDING](state) { state.updateMixTapePending = true; },
    [UPDATE_MIXTAPE_FULFILLED](state) { state.updateMixTapePending = false; },
    [UPDATE_MIXTAPE_ERROR](state) { state.updateMixTapePending = false; },

};
const actions = {
    async updateMixTape({ commit, dispatch }, args) {
        commit(UPDATE_MIXTAPE_PENDING);

        console.log("UPDATE_MIXTAPE " );
        try {
            const url = 'mixtapes';

            const postdata = {
                apiCommand: args.cmd,
                mixtape: args.mixtape
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('mixtape/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchMixTapes({ commit, dispatch }) {
        commit(FETCH_MIXTAPES_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_MIXTAPES");
        try {
            const url = 'mixtapes';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_MIXTAPES_FULFILLED, response.data.mixtapes);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getMixTapeById: (state) => (id) => {
        return state.mixtapes.find(mixtapes => mixtapes.id === id)
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};