<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col>
        <v-parallax dark :src="require('@/assets/imgs/mixtapes.jpg')" height="200">
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <h1 class="display-1 font-weight-thin mb-4">MIX TAPES</h1>
            </v-col>
          </v-row>
        </v-parallax>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          color="secondary"
          :items="mixtapes"
          :headers="headers"
          sort-by="date"
          sort-desc
          class="transparent-bg elevation-1"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
          :expanded.sync="expanded"
          item-key="id"
          :mobile-breakpoint="200"
          show-expand
          :single-expand="true"
          @click:row="clicked"
        >

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pl-2 pr-2 pt-2">
              <iframe
                v-if="item.streamLink"
                width="100%"
                height="60"
                :src="'https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed='+ item.streamLink"
                frameborder="0"
              ></iframe>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateMixtapes,
  mapActions: mapActionsMixtapes
} = createNamespacedHelpers("mixtapes");

export default {
  created()
  {
    this.fetchMixTapes();
  },
  methods: {
    ...mapActionsMixtapes(["fetchMixTapes", ]),
    clicked(value) {
      this.expanded = [];
      this.expanded.push(value);
    }
  },
  computed: {
    ...mapStateMixtapes(["mixtapes"])
  },
  data: () => ({
    expanded: [],
    singleExpand: true,
    headers: [
      { text: "Name", value: "name" },
      { text: "Date", value: "date" },
      { text: "Artist", value: "artist" },
      { text: "", value: "data-table-expand", align: "right" }
    ],
  })
};
</script>
