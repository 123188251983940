// Base Routes
export const appconfig = {
    apiBaseURL: process.env.NODE_ENV === 'development' ? 'https://dev.buzzbass.com:8081/api/v1/' : '/api/v1/',
    serializeOmitFiles: function(postdata) {
        return JSON.stringify(postdata, function(key, value) {
            //console.log("key" + key);
            if (key === "url") return undefined;
            else if (key === "file") return undefined;
            else return value;
        })
    },
    siteName: 'JUST BUZZ BASS',
    buildLink(name, link)
    {
        if(name.match(/juno/gi))
        {
            return "https://www.junodownload.com" + link;
        }
        if(name.match(/soundcloud/gi))
        {
            return "https://www.soundcloud.com" + link;
        }
        if(name.match(/spotify/gi))
        {
            return "https://open.spotify.com" + link;
        }
        if(name.match(/amazon/gi))
        {
            return "https://www.amazon.com" + link;
        }
        if(name.match(/play/gi))
        {
            return "https://play.google.com" + link;
        }
        if(name.match(/apple/gi) || name.match(/itunes/gi))
        {
            return "https://music.apple.com" + link;
        }
        if(name.match(/bandcamp/gi))
        {
            return "https://justbuzzbass.bandcamp.com" + link;
        }
        if(name.match(/hypeddit/gi))
        {
            return "https://hypeddit.com" + link;
        }
        if(name.match(/youtube/gi))
        {
            return "https://youtube.com" + link;
        }
        if(name.match(/tiktok/gi))
        {
            return "https://www.tiktok.com/" + link;
        }
        if(name.match(/amazon/gi))
        {
            return "https://amazon.com" + link;
        }
        if(name.match(/twitter/gi))
        {
            return "https://twitter.com" + link;
        }
        if(name.match(/facebook/gi))
        {
            return "https://facebook.com" + link;
        }
        if(name.match(/instagram/gi))
        {
            return "https://instagram.com" + link;
        }
        if(name.match(/yandex/gi))
        {
            return "https://music.yandex.com" + link;
        }
        if(name.match(/kkbox/gi))
        {
            return "https://www.kkbox.com" + link;
        }
        if(name.match(/deezer/gi))
        {
            return "https://www.deezer.com" + link;
        }
        if(name.match(/tidal/gi))
        {
            return "https://listen.tidal.com" + link;
        }
        if(name.match(/vk/gi))
        {
            return "https://vk.com/" + link;
        }
        if(name.match(/mixcloud/gi))
        {
            return "https://mixcloud.com/" + link;
        }
        if(name.match(/beatport/gi))
        {
            return "https://beatport.com/" + link;
        }

        return "";
    },
    copyToClipboard(text) {
        var dummy = document.createElement("textarea");
        // to avoid breaking orgain page when copying more words
        // cant copy when adding below this code
        // dummy.style.display = 'none'
        document.body.appendChild(dummy);
        //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    },
    AudioFormats : Object.freeze([
        {value:"WAV_24BIT", name:"WAV 24 Bit"},
        {value:"WAV_16BIT", name:"WAV 16 Bit"},
        {value:"MP3_320", name:"MP3 320"},
        {value:"FLAC", name:"FLAC"}
    ])
}



