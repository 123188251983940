import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_SITE_CONTENT_PENDING = 'FETCH_SITE_CONTENT_PENDING';
const FETCH_SITE_CONTENT_FULFILLED = 'FETCH_SITE_CONTENT_FULFILLED';
const FETCH_SITE_CONTENT_ERROR = 'FETCH_SITE_CONTENT_ERROR';

const UPDATE_SITE_CONTENT_PENDING = 'UPDATE_SITE_CONTENT_PENDING';
const UPDATE_SITE_CONTENT_FULFILLED = 'UPDATE_SITE_CONTENT_FULFILLED';
const UPDATE_SITE_CONTENT_ERROR = 'UPDATE_SITE_CONTENT_ERROR';


const state = {

    fetchSiteContentPending: false,
    updateSiteContentPending: false,
    sitecontent: {}
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_SITE_CONTENT_PENDING](state) { state.fetchSiteContentPending = true; },
    [FETCH_SITE_CONTENT_FULFILLED](state, sitecontent) {
        state.sitecontent = sitecontent;
        state.fetchSiteContentPending = false;
    },
    [FETCH_SITE_CONTENT_ERROR](state) { state.fetchSiteContentPending = false; },

    [UPDATE_SITE_CONTENT_PENDING](state) { state.updateSiteContentPending = true; },
    [UPDATE_SITE_CONTENT_FULFILLED](state) { state.updateSiteContentPending = false; },
    [UPDATE_SITE_CONTENT_ERROR](state) { state.updateSiteContentPending = false; },

};
const actions = {
    async fetchSiteContent({  dispatch,commit }) {
        commit(FETCH_SITE_CONTENT_PENDING);
        console.log("FETCH_SITE_CONTENT");
        try {
            const url = 'sitecontent';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_SITE_CONTENT_FULFILLED, response.data.sitecontent);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
    async updateSiteContent({ commit, dispatch }, args) {
        commit(UPDATE_SITE_CONTENT_PENDING);

        console.log("UPDATE_SITE_CONTENT " );
        try {
            const url = 'sitecontent';

            const postdata = {
                apiCommand: args.cmd,
                sitecontent: args.sitecontent
            }

            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));

            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
};

const getters = {
    getSiteContent: (state) => {
        return state.sitecontent
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};