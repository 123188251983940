import axios from 'axios';
import { appconfig } from '@/config';

const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
const FETCH_USER_ERROR = 'FETCH_USER_ERROR';


const state = {

    fetchUsersPending: false,
    fetchUserPending: false,
    updateUserPending: false,
    users: [],
    user: {}
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_USERS_PENDING](state) { state.fetchUsersPending = true; },
    [FETCH_USERS_FULFILLED](state, users) {
        state.users = users;
        state.fetchUsersPending = false;
    },
    [FETCH_USERS_ERROR](state) { state.fetchUsersPending = false; },

    [FETCH_USER_PENDING](state) { state.fetchUserPending = true; },
    [FETCH_USER_FULFILLED](state, user) {
        state.user = user;
        state.fetchUserPending = false;
    },
    [FETCH_USER_ERROR](state) { state.fetchUserPending = false; },

    [UPDATE_USER_PENDING](state) { state.updateUserPending = true; },
    [UPDATE_USER_FULFILLED](state) { state.updateUserPending = false; },
    [UPDATE_USER_ERROR](state) { state.updateUserPending = false; },

};
const actions = {
    async updateUser({ commit, dispatch }, args) {
        commit(UPDATE_USER_PENDING);

        console.log("UPDATE_USER " + args.user.id);
        try {
            const url = 'users';

            const postdata = {
                apiCommand: args.cmd,
                user: args.user
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchUser({ commit, dispatch }, id) {
        commit(FETCH_USER_PENDING);

        console.log("FETCH_USER " + id);
        try {
            const url = 'users';

            const postdata = {
                apiCommand: 'FETCH_USER',
                user: {
                    id:id,
                }
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                commit(FETCH_USER_FULFILLED, response.data.user);
                return response.data.user;
            }
            else
            {
                commit(FETCH_USERS_ERROR);
                dispatch('dialog/setMessage', { message: response.data.status, dialog: true, type: 'error' }, {
                    root: true
                });
                return null;
            }
        } catch (error) {
            commit(FETCH_USERS_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchUsers({ commit, dispatch }) {
        commit(FETCH_USERS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_USERS");
        try {
            const url = 'users';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_USERS_FULFILLED, response.data.users);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getUserById: (state) => (id) => {
        return state.users.find(users => users.id === id) || {}
    },
    getVipUser: (state) => {
        return state.users.filter(u => u.userType !== 'PUBLIC')
    }

}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};