import axios from 'axios';

const FETCH_DEMOS_PENDING = 'FETCH_DEMOS_PENDING';
const FETCH_DEMOS_FULFILLED = 'FETCH_DEMOS_FULFILLED';
const FETCH_DEMOS_ERROR = 'FETCH_DEMOS_ERROR';

const UPDATE_DEMO_PENDING = 'UPDATE_DEMO_PENDING';
const UPDATE_DEMO_FULFILLED = 'UPDATE_DEMO_FULFILLED';
const UPDATE_DEMO_ERROR = 'UPDATE_DEMO_ERROR';

const LOAD_PERMISSION_PENDING = 'LOAD_PERMISSION_PENDING';
const LOAD_PERMISSION_FULFILLED = 'LOAD_PERMISSION_FULFILLED';
const LOAD_PERMISSION_ERROR = 'LOAD_PERMISSION_ERROR';

const SAVE_PERMISSION_PENDING = 'SAVE_PERMISSION_PENDING';
const SAVE_PERMISSION_FULFILLED = 'SAVE_PERMISSION_FULFILLED';
const SAVE_PERMISSION_ERROR = 'SAVE_PERMISSION_ERROR';

const LOAD_ALL_PERMISSIONS_PENDING = 'LOAD_ALL_PERMISSIONS_PENDING';
const LOAD_ALL_PERMISSIONS_FULFILLED = 'LOAD_ALL_PERMISSIONS_FULFILLED';
const LOAD_ALL_PERMISSIONS_ERROR = 'LOAD_ALL_PERMISSIONS_ERROR';

const SUBMIT_DEMO_FEEDBACK_PENDING = 'SUBMIT_DEMO_FEEDBACK_PENDING';
const SUBMIT_DEMO_FEEDBACK_FULFILLED = 'SUBMIT_DEMO_FEEDBACK_FULFILLED';
const SUBMIT_DEMO_FEEDBACK_ERROR = 'SUBMIT_DEMO_FEEDBACK_ERROR';


const state = {

    fetchDemosPending: false,
    fetchDemoPermissionsPending: false,
    updateDemoPending: false,
    loadPermissionPending: false,
    savePermissionPending: false,
    submitDemoFeedBackPending: false,
    demos: [],
    demoPermissions :[],
    demoPermission: {
        demoTrackProps: []
    },
    forUser: ''
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_DEMOS_PENDING](state) { state.fetchDemosPending = true; },
    [FETCH_DEMOS_FULFILLED](state, data) {
        state.demos = data.demos;            
        state.forUser = data.userName;
        state.fetchDemosPending = false;
    },
    [FETCH_DEMOS_ERROR](state) { state.fetchPubDemosPending = false; },

    [UPDATE_DEMO_PENDING](state) { state.updateDemoPending = true; },
    [UPDATE_DEMO_FULFILLED](state) { state.updateDemoPending = false; },
    [UPDATE_DEMO_ERROR](state) { state.updateDemoPending = false; },

    [LOAD_PERMISSION_PENDING](state) { state.loadPermissionPending = true; },
    [LOAD_PERMISSION_FULFILLED](state, pp) {
        if(pp)
        {
            state.demoPermission = pp;
        }
        state.fetchDemosPending = false;
    },
    [LOAD_PERMISSION_ERROR](state) { state.loadPermissionPending = false; },


    [SAVE_PERMISSION_PENDING](state) { state.savePermissionPending = true; },
    [SAVE_PERMISSION_FULFILLED](state) { state.savePermissionPending = false; },
    [SAVE_PERMISSION_ERROR](state) { state.savePermissionPending = false; },

    [LOAD_ALL_PERMISSIONS_PENDING](state) { state.fetchDemoPermissionsPending = true; },
    [LOAD_ALL_PERMISSIONS_FULFILLED](state, pp) {
        state.demoPermissions = pp;
        state.fetchDemoPermissionsPending = false;
    },
    [LOAD_ALL_PERMISSIONS_ERROR](state) { state.fetchDemoPermissionsPending = false; },

    [SUBMIT_DEMO_FEEDBACK_PENDING](state) { state.savePermissionPending = true; },
    [SUBMIT_DEMO_FEEDBACK_FULFILLED](state) { state.savePermissionPending = false; },
    [SUBMIT_DEMO_FEEDBACK_ERROR](state) { state.savePermissionPending = false; },

};
const actions = {
    async fetchDemos({ commit, dispatch }) {
        commit(FETCH_DEMOS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_DEMOS");
        try {
            const url = 'demos';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_DEMOS_FULFILLED, response.data);
                return true;
            }
        } catch (error) {
            commit(FETCH_DEMOS_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return false;
        }
    },
    async loadPermission({ commit, dispatch }, args) {
        commit(LOAD_PERMISSION_PENDING);
        // eslint-disable-next-line
        console.log("LOAD_PERMISSION");
        try {
            const url = 'admdemos';

            const postdata = {
                apiCommand: 'LOAD',
                id: args.id,
            }
            const response = await axios.post(url, postdata);



            if (response.data.status[0] === "OK") {
                await commit(LOAD_PERMISSION_FULFILLED, response.data.demoPermission);
                return true;
            }
        } catch (error) {
            commit(LOAD_PERMISSION_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return false;
        }
    },
    async loadAllPermissions({ commit, dispatch }) {
        commit(LOAD_ALL_PERMISSIONS_PENDING);
        // eslint-disable-next-line
        console.log("LOAD_ALL_PERMISSIONS");
        try {
            const url = 'admdemos';

            const postdata = {
                apiCommand: 'LOAD_ALL',
            }
            const response = await axios.post(url, postdata);



            if (response.data.status[0] === "OK") {
                await commit(LOAD_ALL_PERMISSIONS_FULFILLED, response.data.demoPermissions);
                return true;
            }
        } catch (error) {
            commit(LOAD_ALL_PERMISSIONS_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return false;
        }
    },
    async savePermission({ commit, dispatch }, args) {
        commit(SAVE_PERMISSION_PENDING);
        // eslint-disable-next-line
        console.log("SAVE_PERMISSION");
        try {
            const url = 'admdemos';

            const postdata = {
                apiCommand: 'UPDATE',
                id: args.id,
                demoPermission: args.demoPermission
            }
            const response = await axios.post(url, postdata);

            if (response.data.status[0] === "OK") {
                commit(SAVE_PERMISSION_FULFILLED, response.data.demoPermission);
                return true;
            }
        } catch (error) {
            commit(SAVE_PERMISSION_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return false;
        }
    },
    async submitDemoFeedBack({ commit, dispatch }, args) {
        commit(SUBMIT_DEMO_FEEDBACK_PENDING);
        // eslint-disable-next-line
        console.log("SUBMIT_DEMO_FEEDBACK");
        try {
            const url = 'demos';

            const postdata = {
                apiCommand: 'DEMO_FEEDBACK',
                demoTrackProps: args.demoTrackProps,
            }
            const response = await axios.post(url, postdata);

            if (response.data.status[0] === "OK") {
                commit(SUBMIT_DEMO_FEEDBACK_FULFILLED);
                return true;
            }
        } catch (error) {
            commit(SUBMIT_DEMO_FEEDBACK_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return false;
        }
    },

};

const getters = {
    getDemoById: (state) => (id) => {
        return state.demos.find(release => release.id === id) || {};
    },
    getLastDemo: (state) => {
        if (state.demos.length === 0) {
            return null;
        } else {

            return state.demos[state.demos.length - 1]
        }
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};