/* eslint-disable no-unused-vars */
import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Events from '@/views/Events.vue'
import FileUpload from '@/views/FileUpload.vue'
import AdmEvent from '@/views/AdmEvent.vue'
import AdmEvents from '@/views/AdmEvents.vue'
import AdmRelease from '@/views/AdmRelease.vue'
import AdmReleases from '@/views/AdmReleases.vue'
import AdmUser from '@/views/AdmUser.vue'
import AdmUsers from '@/views/AdmUsers.vue'
import AdmMixTape from '@/views/AdmMixTape.vue'
import AdmMixTapes from '@/views/AdmMixTapes.vue'
import AdmArtist from '@/views/AdmArtist.vue'
import AdmArtists from '@/views/AdmArtists.vue'
import AdmTrack from '@/views/AdmTrack.vue'
import AdmTracks from '@/views/AdmTracks.vue'
import AdmMailing from '@/views/AdmMailing.vue'
import AdmMailings from '@/views/AdmMailings.vue'
import AdmSendMail from '@/views/AdmSendMail.vue'
import AdmMailingLists from '@/views/AdmMailingLists.vue'
import AdmMailingList from '@/views/AdmMailingList.vue'
import AdmDemos from '@/views/AdmDemos.vue'
import AdmSubmissions from '@/views/AdmSubmissions.vue'
import AdmSettings from '@/views/AdmSettings.vue'
import AdmStats from '@/views/AdmStats.vue'
import AdmStatDetails from '@/views/AdmStatDetails.vue'
import AdmSiteContent from '@/views/AdmSiteContent.vue'
import AdmUserTokens from '@/views/AdmUserTokens.vue'
import AdmTools from '@/views/AdmTools.vue'
import AdmReviews from '@/views/AdmReviews.vue'
import AdmEmailProvider from '@/views/AdmEmailProvider.vue'
import AdmEmailProviders from '@/views/AdmEmailProviders.vue'
import Artists from '@/views/Artists.vue'
import Artists2 from '@/views/Artists2.vue'
import Artist from '@/views/Artist.vue'
import Release from '@/views/Release.vue'
import Releases from '@/views/Releases.vue'
import Releases2 from '@/views/Releases2.vue'
import Demos from '@/views/Demos.vue'
import MixTapes from '@/views/MixTapes.vue'
import Contact from '@/views/Contact.vue'
import Privacy from '@/views/Privacy.vue'
import Tos from '@/views/Tos.vue'
import AdminDashboard from '@/views/AdminDashboard.vue'
import ProxyView from '@/views/ProxyView.vue'
import SignIn from '@/views/SignIn.vue'
import SignUp from '@/views/SignUp.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import SubmitDemo from '@/views/SubmitDemo.vue'
import Profile from '@/views/Profile.vue'
import Order from '@/views/Order.vue'
import ErrorPage from '@/views/ErrorPage.vue'
import Test from '@/views/Test.vue'
import store from './store/store'

window.store = store;

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/test',
        name: 'test',
        component: Test
    },
    {
        path: '/events',
        name: 'events',
        component: Events
    },
    {
        path: '/artists',
        name: 'artists',
        component: Artists
    },
    {
        path: '/artists2',
        name: 'artists2',
        component: Artists2
    },
    {
        path: '/artist/:artistname?/:id',
        name: 'artist',
        component: Artist
    },
    {
        path: '/mixtapes',
        name: 'mixtapes',
        component: MixTapes
    },
    {
        path: '/releases',
        name: 'releases',
        component: Releases
    },
    {
        path: '/releases2',
        name: 'releases2',
        component: Releases2
    },
    {
        path: '/release/:releasename?/:id',
        name: 'release',
        component: Release
    },
    {
        path: '/demos',
        name: 'demos',
        component: Demos
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
    },
    {
        path: '/tos',
        name: 'tos',
        component: Tos
    },
    {
        path: '/admin',
        component: ProxyView,
        meta: {
            requiresAdminAuth: true,
            requiresEditorAuth: true,
        },
    
        children: [{
                path: '',
                name: 'admin',
                component: AdminDashboard,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'events',
                name: 'admevents',
                component: AdmEvents,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'event/:id',
                name: 'admevent',
                component: AdmEvent,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'releases',
                name: 'admreleases',
                component: AdmReleases,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'release/:id',
                name: 'admrelease',
                component: AdmRelease,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'users',
                name: 'admusers',
                component: AdmUsers,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'user/:id/',
                name: 'admuser',
                component: AdmUser,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'mixtapes',
                name: 'admmixtapes',
                component: AdmMixTapes,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'mixtape/:id',
                name: 'admmixtape',
                component: AdmMixTape,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'tracks',
                name: 'admtracks',
                component: AdmTracks,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'track/:id',
                name: 'admtrack',
                component: AdmTrack,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'artist/:id',
                name: 'admartist',
                component: AdmArtist,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'artists',
                name: 'admartists',
                component: AdmArtists,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'mailing/:id',
                name: 'admmailing',
                component: AdmMailing,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'mailings',
                name: 'admmailings',
                component: AdmMailings,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'sendmail/:id',
                name: 'admsendmail',
                component: AdmSendMail,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'mailinglist/:id',
                name: 'admmailinglist',
                component: AdmMailingList,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'mailinglists',
                name: 'admmailinglists',
                component: AdmMailingLists,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'demos',
                name: 'admdemos',
                component: AdmDemos,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'Submissions',
                name: 'admsubmissions',
                component: AdmSubmissions,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },
            {
                path: 'emailprovider/:id',
                name: 'admemailprovider',
                component: AdmEmailProvider,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'emailproviders',
                name: 'admemailproviders',
                component: AdmEmailProviders,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'settings',
                name: 'admsettings',
                component: AdmSettings,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'stats',
                name: 'admstats',
                component: AdmStats,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'statdetails/:id',
                name: 'admstatdetails',
                component: AdmStatDetails,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'tools/',
                name: 'admtools',
                component: AdmTools,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'reviews/',
                name: 'admreviews',
                component: AdmReviews,
                meta: {
                    requiresAdminAuth: true,
                    requiresEditorAuth: true,
                },
            },            
            {
                path: 'usertokens/:id',
                name: 'admusertokens',
                component: AdmUserTokens,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'sitecontent',
                name: 'admsitecontent',
                component: AdmSiteContent,
                meta: {
                    requiresAdminAuth: true,
                },
            },
            {
                path: 'fupload/:id?',
                name: 'fupload',
                component: FileUpload,
                meta: {
                    requiresAdminAuth: true,
                },
            },
        ]
    },
    {
        path: '/signin',
        name: 'signin',
        component: SignIn,
        meta: {
            requiresAdminAuth: false,
        }
    },
    {
        path: '/signup/:cc?',
        name: 'signup',
        component: SignUp,
        meta: {
            requiresAdminAuth: false,
        }
    },
    {
        path: '/resetpw/:cc?',
        name: 'resetpw',
        component: ResetPassword,
        meta: {
            requiresAdminAuth: false,
        }
    },
    {
        path: '/submitdemo',
        name: 'submitdemo',
        component: SubmitDemo,
        meta: {
            requiresMemberAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresMemberAuth: true,
        }
    },
    {
        path: '/cart',
        name: 'cart',
        component: Order,
        meta: {
            requiresMemberAuth: false,
        }
    },
    {
        path: '/order/:orderid?',
        name: 'order',
        component: Order,
        meta: {
            requiresMemberAuth: false,
        }
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Order,
        meta: {
            requiresMemberAuth: false,
        }
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: ErrorPage
    },
    {
        path: '*',
        name: 'notfound2',
        component: ErrorPage
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
      }
});

router.beforeEach(async (to, from, next)  =>  {
    document.title = "BuzzBass - " + to.name.toUpperCase();


    if(to.query.code)
    {
        await store.dispatch('shop/checkPayPalAccessOauthToken', to.query.code);
        console.log("check paypal accesstoken " + to.query.code)
        delete to.query.code;
        next(to);
        return
    }

    if(to.query.a)
    {
        await store.dispatch('user/handleToken', to.query.a);
        console.log("check accesstoken " + store.getters['user/getUserAccessToken'])
        delete to.query.a;
        next(to);
        return
    }

    if(store.getters['user/getUserAccessToken'])
    {
        console.log("set accesstoken header " + store.getters['user/getUserAccessToken'])
        axios.defaults.headers.common['accesstoken'] = store.getters['user/getUserAccessToken'];
    }else
    {
        console.log("no accesstoken set " + store.getters['user/getUserAccessToken'])
    }

    // eslint-disable-next-line no-console
    console.log("ROUTER " + JSON.stringify(to.parms) + " " + JSON.stringify(to.meta) + " " + to.name);
    // eslint-disable-next-line no-console
    console.log("ROUTER getUserSignedIn " + store.getters['user/getUserSignedIn']);

    if(!store.getters['user/getUserInit'])
    {
        await store.dispatch('user/signInState'); 
    }


    if (to.meta.requiresAdminAuth || to.meta.requiresEditorAuth || to.meta.requiresMemberAuth) {

        
       
        await store.dispatch('user/signInState');
        if(!store.getters['user/getUserSignedIn'])
        {
            console.log("Redirect to signin")
            store.commit('user/SET_REDIRECT_AFTER_LOGIN', to);
            next({ name: 'signin' });
            return;

        }

    }

    if((to.meta.requiresAdminAuth || to.meta.requiresEditorAuth) && (!store.getters['user/getUserIsAdmin'] && !store.getters['user/getUserIsEditor']))
    {
        console.log("Redirect to not authorized is admin " + store.getters['user/getUserIsAdmin'])
        store.commit('user/SET_REDIRECT_AFTER_LOGIN', to);
        store.dispatch('dialog/setMessage', { message: 'Not Authorized', dialog: true, type: 'error' }, {
            root: true
        });
        next({ name: 'signin' });
        return;
    }


    next();
});

export default router