<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateArtist">
          Create Artist
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="artists"
          :search="search"
          :headers="headers"
          sort-by="date"
          sort-desc
          class="transparent-bg"
          :hide-default-header="!isAdmin"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          style="cursor:pointer"
        >
          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
          <template v-slot:item.publish="{ item }">
            <v-icon v-if="item.publish" small color="accent">mdi-check</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateArtists,
  mapActions: mapActionsArtists,
} = createNamespacedHelpers("artists");

export default {
  name: "AdminArtists",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Published", value: "publish" }
    ],
    search: "",
    dialog: false
  }),
  created() {
    this.fetchArtists();
  },
  computed: {
    ...mapStateArtists(["artists"])
  },
  methods: {
    ...mapActionsArtists(["fetchArtists", "createArtist"]),
    async doCreateArtist() {
      const newArtistID = await this.getObjectId();
      console.log("newArtistID " + newArtistID);
      this.$router.push({ path: "/admin/artist/" + newArtistID });
    },
    rowClickDetail(e) {
      console.log("rowClickDetail " + e.id);
      this.$router.push({ path: "/admin/artist/" + e.id });
    }
  }
};
</script>