<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn
          :to="{name:'admin'}"
          class="mr-2"
          icon
          text
          depressed
          exact
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateRelease" :small="$vuetify.breakpoint.smAndDown">
          Create Release
          <v-icon right :small="$vuetify.breakpoint.smAndDown">mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="releases"
          :search="search"
          :headers="headers"
          class="transparent-bg"
          dense
          must-sort
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          style="cursor:pointer"
        >
          <template v-slot:item.view="{ item }" color="secondary">
            <v-btn :to="{name:'release',params:{id:item.id,releasename:normalizeUrl(item.name)}}" target="fb" text depressed icon>
              <v-icon smal :color="item.publish ? 'primary':''">mdi-eye</v-icon>
            </v-btn>
          </template>
           <template v-slot:item.catNo="{ item }"> {{item.catNo ? $vuetify.breakpoint.smAndDown ? item.catNo.slice(-3) : item.catNo : "-"}} </template>
          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");

export default {
  name: "AdminReleases",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Cat No", value: "catNo" },
      { text: "Release date", value: "dateRelease" },
      { text: "", value: "view", sortable: false, align: "right" },
    ],
    search: "",
    dialog: false,
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("releaseTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "catNo";
      this.myTableState.sortDesc = true;
    }
    this.fetchReleases();
  },
  computed: {
    ...mapStateReleases(["releases"])
  },
  methods: {
    ...mapActionsReleases(["fetchReleases", "createRelease"]),
    async doCreateRelease() {
      const newReleaseID = await this.getObjectId();
      console.log("newReleaseID " + newReleaseID);
      this.$router.push({ name: "admrelease", params: { id: newReleaseID } });
    },
    rowClickDetail(e) {
      console.log("rowClickDetail " + e.id);
      this.$router.push({ path: "/admin/release/" + e.id });
    }
  }
};
</script>