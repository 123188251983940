<template>
  <v-container>
    Campaign "{{campaignStatsDetails.campaignName}}" Details
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{ path: $store.state.route.from.fullPath }" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="refresh" class="mr-2" icon text depressed exact>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          clearable
          dense
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="campaignStatsDetails.userDetailsList"
          :search="search"
          :headers="headers"
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          must-sort
          show-expand
          :single-expand="true"
          item-key="userId"
          :expanded.sync="expanded"
          class="transparent-bg"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          @item-expanded="fetchLogItems"
          style="cursor:pointer"
        >
          <template v-slot:item.artistName="{ item }" >
            <div style ="max-width:100px;overflow:hidden" >
            <router-link :to="{name:'admuser',params:{id:item.userId}}" style="text-decoration: none;color:white;font-weight:bold">
              <span v-if="item.artistName">
                {{item.artistName}}
              </span>
              <span v-else>
                {{item.email}}
              </span>
            </router-link>
              </div>
          </template>

          <template v-slot:expanded-item="{ headers , item }">
            <td :colspan="headers.length" class="pa-4">
              <div v-for="review in item.reviews" :key="review.id" >
                <v-row >
                  <v-col class="pa-1">{{review.resourceName}} </v-col>
                  <v-col class="pa-1">{{review.reviewText}}</v-col>
                </v-row>
                <v-row v-for="qa in review.questionsAnswers" :key="qa.question">
                  <v-col class="pa-1">{{qa.question}}</v-col>
                  <v-col class="pa-1">{{qa.answer}}</v-col>
                </v-row>
                </div>
                <v-data-table
                :headers="headersLogs"
                :items="logItems"
                item-key="id"
                class="transparent-bg"
                must-sort
                :sort-by.sync="tableStateLogs.sortBy"
                :sort-desc.sync="tableStateLogs.sortDesc"
                :page.sync="tableStateLogs.currentPage"
                :items-per-page.sync="tableStateLogs.itemsPerPage"
                :footer-props="{ 'items-per-page-options':tableStateLogs.itemsPerPageOptions }"
                :mobile-breakpoint="200"
              ></v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";

const {
  mapActions: mapActionsStats,
  mapState: mapStateStats
} = createNamespacedHelpers("stats");

export default {
  name: "AdmStats",
  mixins: [defaultMixin],
  dialog: false,
  data: () => ({
    headers: [
      { text: "Artist Name", value: "artistName" },
      { text: "accessed", value: "dateAccessed"},
      { text: "played", value: "datePlayed" },
      { text: "downl", value: "dateDownloaded" },
      { text: '', value: 'data-table-expand' },
    ],
        headersLogs: [
      { text: "action", value: "action" },
      { text: "date", value: "date" },
      { text: "resourceName", value: "resourceName" },
      { text: "reomoteHost", value: "reomoteHost" }
    ],
    search: "",
    expanded: [],
    dialog: false,
    logItems: [],
    myTableState: {},
    tableStateLogs: {},
  }),
  computed: {
    ...mapStateStats(["campaignStatsDetails"])
  },
  methods: {
    ...mapActionsStats(["fetchStats", "fetchStatDetails"]),
    rowClickDetail(e) {
      console.log("rowClickDetail " + e.campaignId);
      //this.$router.push({ path: "/admin/track/" + e.id });
    },
    async refresh()
    {
      await this.fetchStatDetails(this.$route.params.id);
    },
    async fetchLogItems(item) {
    console.log("fetchLogItems "   + item.item.userId);
    const url = "userlogs";
    const postdata = {
      id: item.item.userId
    };
    const response = await axios.post(url, postdata);

    if (response.data.status[0] === "OK") {
      this.logItems = response.data.logItems;
    }
  },

  },
  async created() {
    this.myTableState = this.getTableState("tableReviews");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateAccessed";
      this.myTableState.sortDesc = true;
      this.myTableState.itemsPerPage = 12;
    }
    await this.fetchStatDetails(this.$route.params.id);
    
    this.tableStateLogs = this.getTableState("tableLogs");
    if (!this.tableStateLogs.sortBy) {
      this.tableStateLogs.sortBy = "date";
      this.tableStateLogs.sortDesc = true;
    }
  },

};
</script>