import axios from 'axios';


const FETCH_STATS_BY_CAMPAIGN_PENDING = 'FETCH_STATS_BY_CAMPAIGN_PENDING';
const FETCH_STATS_BY_CAMPAIGN_FULFILLED = 'FETCH_STATS_BY_CAMPAIGN_FULFILLED';
const FETCH_STATS_BY_CAMPAIGN_ERROR = 'FETCH_STATS_BY_CAMPAIGN_ERROR';

const FETCH_STAT_DETAILS_PENDING = 'FETCH_STAT_DETAILS_PENDING';
const FETCH_STAT_DETAILS_FULFILLED = 'FETCH_STAT_DETAILS_FULFILLED';
const FETCH_STAT_DETAILS_ERROR = 'FETCH_STAT_DETAILS_ERROR';



const state = {

    fetchSiteContentPending: false,
    fetchStatDetailsPending: false,
    campaignStats: [],
    campaignStatsDetails: {}
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_STATS_BY_CAMPAIGN_PENDING](state) { state.fetchSiteContentPending = true; },
    [FETCH_STATS_BY_CAMPAIGN_FULFILLED](state, data) {
        state.campaignStats = data.campaignStats;
        state.fetchSiteContentPending = false;
    },
    [FETCH_STATS_BY_CAMPAIGN_ERROR](state) { state.fetchSiteContentPending = false; },

    [FETCH_STAT_DETAILS_PENDING](state) { state.fetchStatDetailsPending = true; },
    [FETCH_STAT_DETAILS_FULFILLED](state, data) {
        state.campaignStatsDetails = data;
        state.fetchStatDetailsPending = false;
    },
    [FETCH_STAT_DETAILS_ERROR](state) { state.fetchStatDetailsPending = false; },


};
const actions = {
    async fetchStats({  dispatch,commit }) {
        commit(FETCH_STATS_BY_CAMPAIGN_PENDING);
        commit(FETCH_STATS_BY_CAMPAIGN_FULFILLED, []);
        console.log("FETCH_STATS_BY_CAMPAIGN");
        try {
            const url = 'stats';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_STATS_BY_CAMPAIGN_FULFILLED, response.data);
                console.log("FETCH_STATS_BY_CAMPAIGN DONE");
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
    async fetchStatDetails({  dispatch,commit },id) {
        commit(FETCH_STAT_DETAILS_PENDING);
        console.log("FETCH_STAT_DETAILS");
        commit(FETCH_STAT_DETAILS_FULFILLED, {});
        try {
            const url = 'statsdetails';
            const postdata = {
                id:id
            }
            const response = await axios.post(url,postdata);

            if (response.data.status[0] === "OK") {
                commit(FETCH_STAT_DETAILS_FULFILLED, response.data.campaignStatDetails);
                console.log("FETCH_STAT_DETAILS DONE");
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            commit(FETCH_STAT_DETAILS_ERROR);

            return false;
        }
    },

};



export default {
    state,
    mutations,
    actions,
    namespaced: true,
};