<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1" >
          Tools
          <br />
        </div>
      </v-col>
    </v-row>
    <v-row>
        <v-col v-for="link in toolsLinks" :key="link.name">
            <v-btn :target="link.target" :href="link.link" style="width:100%">{{link.name}}</v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";

export default {
    name: "AdminTools",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "ISRC", value: "ISRC" },
      { text: "", value: "action", sortable: false, align: "right" }
    ],
    toolsLinks:[
        {name:'Analytics', target:'analytics', link:'https://analytics.google.com/analytics/web/#/report-home/a89468210w132692947p136663771'},
        {name:'Search Console', target:'analytics', link:'https://search.google.com/search-console?resource_id=https%3A%2F%2Fbuzzbass.com%2F'},
        {name:'Gmail', target:'gmail', link:'https://mail.google.com/mail/u/0/?tab=rm#inbox'},
        {name:'Drive', target:'drive', link:'https://drive.google.com/drive/my-drive'},
        {name:'FB Profile', target:'fbpage', link:'https://www.facebook.com/buzz.bass/'},
        {name:'FB Page', target:'fbpage', link:'https://www.facebook.com/JustBuzzBass/'},
        {name:'FB Ads', target:'fbads', link:'https://www.facebook.com/JustBuzzBass/ad_center/?refSource=pages_manager_bar'},
        {name:'FB Ads Business', target:'fbadsb', link:'https://business.facebook.com/JustBuzzBass/ad_center/?refSource=pages_manager_bar&business_id=706555939810298'},
        {name:'Soundcloud', target:'sc', link:'https://soundcloud.com/buzzbass'},
        {name:'Juno', target:'junolms', link:'https://lms.junodownload.com/lms/'},
        {name:'Cloudflare', target:'cloudflare', link:'https://dash.cloudflare.com/'},
        {name:'SounCloud Api', target:'soundcloud api', link:'https://developers.soundcloud.com/docs/api/'},
        {name:'Vuetify', target:'Vuetify', link:'https://vuetifyjs.com/en/components/api-explorer'},
        {name:'Vuejs', target:'vuejs', link:'https://vuejs.org/v2/api/'},
        {name:'Vue Cli', target:'vuecli', link:'https://cli.vuejs.org/config/'},
        {name:'Metatags', target:'metatags', link:'https://metatags.io/'},
        {name:'Mjml', target:'mjml', link:'https://mjml.io/documentation/'},
        {name:'Simplejavamail', target:'simplejavamail', link:'http://www.simplejavamail.org/features.html#navigation'},
    ]
  }),  
}
</script>