import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_EVENTS_PENDING = 'FETCH_EVENTS_PENDING';
const FETCH_EVENTS_FULFILLED = 'FETCH_EVENTS_FULFILLED';
const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR';

const UPDATE_EVENT_PENDING = 'UPDATE_EVENT_PENDING';
const UPDATE_EVENT_FULFILLED = 'UPDATE_EVENT_FULFILLED';
const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';


const state = {

    fetchEventsPending: false,
    updateEventPending: false,
    events: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_EVENTS_PENDING](state) { state.fetchEventsPending = true; },
    [FETCH_EVENTS_FULFILLED](state, events) {
        state.events = events;
        state.fetchEventsPending = false;
    },
    [FETCH_EVENTS_ERROR](state) { state.fetchEventsPending = false; },

    [UPDATE_EVENT_PENDING](state) { state.updateEventPending = true; },
    [UPDATE_EVENT_FULFILLED](state) { state.updateEventPending = false; },
    [UPDATE_EVENT_ERROR](state) { state.updateEventPending = false; },

};
const actions = {
    async updateEvent({ commit, dispatch }, args) {
        commit(UPDATE_EVENT_PENDING);
        try {
            const url = 'events';
            const postdata = {
                apiCommand: args.cmd,
                event: args.event
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));

            if (response.data.status[0] === "OK") {
                commit(UPDATE_EVENT_FULFILLED, response.data.events);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchEvents({ commit, dispatch }) {
        commit(FETCH_EVENTS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_EVENTS");
        try {
            const url = 'events';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_EVENTS_FULFILLED, response.data.events);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getEventById: (state) => (id) => {
        return state.events.find(event => event.id === id)
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};