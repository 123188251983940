<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" align="left">
        <v-btn @click="$router.push({name:'admin'})" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateUser" class="mr-2">
          New User
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
        <v-btn @click="dialog = !dialog">
          Import
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-data-table
          :items="users"
          :search="search"
          :headers="headers"
          class="transparent-bg"
          must-sort
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          style="cursor:pointer"
        >

          <template v-slot:item.dateSubscribed="{ item }">{{new Date(item.dateSubscribed).toDateString()}}</template>
          <template v-slot:item.isSubscribed="{ item }">
            <v-btn icon>
            <v-icon v-if="!item.isSubscribed == '' && isAdmin" small >mdi-check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" :overlay="true" max-width="500px" transition="dialog-transition">
      <v-card class="dialog-bg">
        <v-card-title>Import Emails as Users</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="toImport"
            hide-details
            label="E-Mail to import"
            outlined
            color="accent"
            class="mb-2"
          ></v-textarea>
          <v-select
            label="Add to List"
            v-model="addToList"
            dense
            color="accent"
            hide-details
            outlined
            multiple
            :items="mailinglists"
            item-value="id"
            item-text="name"
            class="mb-2"
          ></v-select>
          <v-select
            :items="userTypes"
            label="User Type"
            v-model="userType"
            dense
            color="accent"
            hide-details
            outlined
            class="mb-2"
          ></v-select>
        </v-card-text>
        <v-card-text v-if="parsedEmails.length > 0">Found {{parsedEmails.length}} emails</v-card-text>
        <v-card-text align="right">
          <v-spacer></v-spacer>
          <v-btn outlined color="accent" @click="parseEmails()">Parse</v-btn>
          <v-btn outlined color="accent" v-if="parsedEmails.length > 0" @click="doImport" class="ml-2">Import</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";

const {
  mapState: mapStateUsers,
  mapActions: mapActionsUsers,
} = createNamespacedHelpers("users");

// todo relations to mailing list
const {
  mapActions: mapActionsMailingLists,
  mapGetters: mapGettersMailingLists,
  mapState: mapStateMailingLists
} = createNamespacedHelpers("mailinglists");

export default {
  name: "AdminUsers",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Type", value: "userType" },
      { text: "Artist", value: "artistsName" },
      { text: "Email", value: "email" },
      { text: "Subscribed", value: "isSubscribed" },
      { text: "Date", value: "dateSubscribed" },
    ],
    search: "",
    toImport: "",
    addToList: [],
    parsedEmails: [],
    dialog: false,
    userType: "PUBLIC",
    userTypes: ["PUBLIC", "VIP"],
    myTableState: {}
  }),
  async created() {
    this.myTableState = this.getTableState("tableUsers");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateSubscribed";
      this.myTableState.sortDesc = true;
      this.myTableState.itemsPerPage = 12;
    }
    await this.fetchMailingLists({force:false,mailingId:null});
    await this.fetchUsers();
  },
  computed: {
    ...mapStateUsers(["users"]),
    ...mapStateMailingLists(["mailinglists"]),
    ...mapGettersMailingLists(["getMailingListById"])
  },
  methods: {
    ...mapActionsUsers(["fetchUsers", "createUser"]),
    ...mapActionsMailingLists(["fetchMailingLists", "updateMailingList"]),
    async doCreateUser() {
      const newUserID = await this.getObjectId();
      console.log("newUserID " + newUserID);
      this.$router.push({ path: "/admin/user/" + newUserID });
    },
    rowClickDetail(e)
    {
      console.log("rowClickDetail " + e.id);
      this.$router.push({path:'/admin/user/'+ e.id});
    },
    async doImport() {
      try {
        const url = "users";

        const postdata = {
          apiCommand: "IMPORT_EMAILS",
          emailImport: {
            emails: this.parsedEmails,
            addToMailingList: this.addToList,
            user_type: this.userType
          }
        };

        await axios.post(url, postdata);

        this.dialog = false;
        await this.fetchUsers();

        this.setMessage({
          message: "Users imported",
          dialog: true,
          type: "success",
          timeout : 500
        });
      } catch (error) {
        this.subscribeDialog = false;
        console.log("IMPORT_EMAILS " + error);
      }
    },
    parseEmails() {
      const regex = /[\w\.]+\@[\w]+(?:\.[\w]{3}|\.[\w]{2}\.[\w]{2})\b/g;

      let m;
      this.parsedEmails = [];
      

      while ((m = regex.exec(this.toImport)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          this.parsedEmails.push(match);
          console.log(`Found match, group ${groupIndex}: ${match}`);
        });
      }

      this.toImport = this.parsedEmails.join("\n");
    }
  }
};
</script>