<template>
  <v-container class="pa-0">
    <v-form class="ma-4" ref="ContactForm">
      <v-row no-gutters>
        <v-col>
          <div class="font-weight-thin display-1 mb-2">
            Contact / Booking Inquiries
            <br />
          </div>
          <div
            class="font-weight-light body-2"
          >Please don't hesitate to contact us. We will get back to you as soon as possible.</div>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            outlined
            color="accent"
            hide-details
            class="mt-4"
            :lazy-validation="false"
            dense
          ></v-text-field>
          <v-text-field
            v-model="name"
            :rules="[v => !!v || 'Item is required']"
            label="Name"
            outlined
            color="accent"
            hide-details
            class="mt-4"
            :lazy-validation="false"
            dense
          ></v-text-field>
          <v-textarea
            v-model="message"
            :rules="[v => !!v || 'Item is required']"
            label="Message"
            outlined
            color="accent"
            hide-details
            class="mt-4"
            :lazy-validation="false"
            dense
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn @click="checkAndSend">
            Send
            <v-icon right>mdi-send</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay v-model="waitOverlay">
      <v-progress-circular indeterminate :size="100" :width="5" color="accent">sending..</v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import axios from "axios";

export default {
  name: "Contact",
  mixins: [defaultMixin],
  data: () => ({
    waitOverlay: false,
    email: "",
    name: "",
    message: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid"
    ]
  }),
  methods: {
    async checkAndSend() {
      const url = "contact";
      this.waitOverlay = true;
      console.log("checkAndSend CONTACT_US");

      if (this.$refs.ContactForm.validate()) {
        const postdata = {
          apiCommand: "CONTACT_US",
          name: this.name,
          email: this.email,
          message: this.message
        };

        const response = await axios.post(url, postdata);

        if (response.data.status[0] === "OK") {
          console.log("Success CONTACT_US");
          this.message = "";
          this.setMessage({
            message: "Thank you for contacting us!",
            dialog: true,
            type: "success"
          });
          this.$refs.ContactForm.resetValidation();
        } else {
          console.log("Fail CONTACT_US");
        }
      }
      this.waitOverlay = false;
    }
  }
};
</script>
