<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateMailingList">
          Create MailingList
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="mailinglists"
          :search="search"
          :headers="headers"
          class="transparent-bg"
          must-sort
          dense
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          style="cursor:pointer"
          @click:row="rowClickDetail"
        >
          <template v-slot:item.action="{ item }" color="secondary">
            <v-btn
              :to="{path:'/admin/mailinglist/'+ item.id}"
              target="fb"
              text
              depressed
              icon
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.count="{ item }" color="secondary">{{item.refUser.length}}</template>

          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateMailingLists,
  mapActions: mapActionsMailingLists,
} = createNamespacedHelpers("mailinglists");

export default {
  name: "AdminMailingLists",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Date Created", value: "dateCreated" },
      { text: "cnt", value: "count" },
      { text: "", value: "action", sortable: false, align: "right" }
    ],
    search: "",
    dialog: false,
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("mailinglistTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateCreated";
      this.myTableState.sortDesc = true;
    }
    this.fetchMailingLists({force:true,mailingId:null});
  },
  computed: {
    ...mapStateMailingLists(["mailinglists"])
  },
  methods: {
    ...mapActionsMailingLists(["fetchMailingLists", "createMailingList"]),
    async doCreateMailingList() {
      const newMailingListID = await this.getObjectId();
      console.log("newMailingListID " + newMailingListID);
      this.$router.push({ path: "/admin/mailinglist/" + newMailingListID });
    },
    rowClickDetail(e) {
      console.log("rowClickDetail " + e.id);
      this.$router.push({ path: "/admin/mailinglist/" + e.id });
    }
  }
};
</script>