<template>
  <v-container class="pa-0">
    <template v-if="artist.publish">
      <v-img dark :src="getFileUrl(artist)" :aspect-ratio="1.9">
        <v-row align="center" justify="center" class="fill-height">
          <v-col class="text-center" cols="12">
            <h1 class="display-2 font-weight-thin"></h1>
          </v-col>
        </v-row>
      </v-img>
      <div class="font-weight-thin ma-4 mb-8" style="white-space: pre-line">
        <span class="glass3">{{ artist.name }}</span>
        {{ artist.profileTextArea }}
      </div>
      <div class="ma-4 mb-8">
        <div v-if="artist.linkSpotifyPage">
          <a :href="artist.linkSpotifyPage" target="_blank">SPOTIFY</a>
        </div>
        <div v-if="artist.linkBeatPortPage">
          <a :href="artist.linkBeatPortPage" target="_blank">BEATPORT</a>
        </div>
        <div v-if="artist.linkYoutubePage">
          <a :href="artist.linkYoutubePage" target="_blank">YOUTUBE</a>
        </div>
        <div v-if="artist.linkSoundcloudPage">
          <a :href="artist.linkSoundcloudPage" target="_blank">SOUNDCLOUD</a>
        </div>
        <div v-if="artist.linkInstagramPage">
          <a :href="artist.linkInstagramPage" target="_blank">INSTAGRAM</a>
        </div>
        <div v-if="artist.linkFacebookPage">
          <a :href="artist.linkFacebookPage" target="_blank">FACEBOOK</a>
        </div>
        <div v-if="artist.linkEmailPage">
          <a :href="artist.linkEmailPage" target="_blank">EMAIL</a>
        </div>
      </div>
    </template>
    <v-data-iterator
      :items="filteredByArtist"
      :items-per-page="10"
      hide-default-footer
      no-data-text=""
    >
      <template v-slot:default="props">
        <div class="glass3 ma-4">Releases by {{ artist.name }}</div>
        <v-row dense class="pa-0">
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? 4 : 6"
            v-for="(release, idx) in props.items"
            :key="release.id"
          >
            <transition name="fade" appear>
              <v-card
                :style="'transition-delay: ' + (idx + 1) / 8 + 's'"
                class="translucent-bg-1 mb-0"
                dense
                :to="{
                  name: 'release',
                  params: {
                    id: release.id,
                    releasename: normalizeUrl(release.name),
                  },
                }"
              >
                <v-img
                  :src="getReleaseFileUrl(release)"
                  aspect-ratio="1"
                ></v-img>
              </v-card>
            </transition>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapStateArtists, mapActions: mapActionsArtists } =
  createNamespacedHelpers("artists");

const { mapState: mapStateReleases, mapActions: mapActionsReleases } =
  createNamespacedHelpers("releases");

export default {
  name: "Artists",
  mixins: [defaultMixin],
  data: () => ({
    artist: {},
    myTableState: {},
  }),
  async created() {
    await this.fetchArtists();
    this.artist = this.artists.find((a) => a.id === this.$route.params.id);
    this.fetchPubReleases();
    document.title = this.artist.name + " " + this.ac.siteName + " RECORDS";
  },
  computed: {
    ...mapStateArtists(["artists"]),
    ...mapStateReleases(["pubreleases"]),
    filteredByArtist: {
      get: function () {
        return this.pubreleases.filter((r) =>
          r.tracks.find((t) => t.refArtist.includes(this.$route.params.id))
        );
      },
    },
  },
  methods: {
    ...mapActionsArtists(["fetchArtists"]),
    ...mapActionsReleases(["fetchPubReleases"]),
    getFileUrl(artist) {
      let fileEntity = artist.filesImageArtist[0];
      if (!fileEntity.url) {
        return (
          this.ac.apiBaseURL +
          "res/" +
          "artists" +
          "/" +
          artist.id +
          "/" +
          fileEntity.id +
          fileEntity.ext
        );
      } else {
        return fileEntity.url;
      }
    },
    getReleaseFileUrl(release) {
      if (release.filesImageArtWork) {
        let fileEntity = release.filesImageArtWork[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "releases" +
            "/" +
            release.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
};
</script>
