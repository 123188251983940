<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1">
          Reviews
          <br />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="reviews"
          :headers="headers"
          class="transparent-bg"
          must-sort
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          style="cursor:pointer"
        >
          <template v-slot:item.userEmail="{ item }" >
            <!-- <div style="min-width:80px;word-break: break-all;"> -->
              <router-link :to="{name:'admuser',params:{id:item.userId}}" style="text-decoration: none;color:white;font-weight:bold">
                <span v-if="item.userEmail">{{item.userEmail}}</span>
                <span v-else>{{item.userId}}</span>
              </router-link>  
            <!-- </div> -->
          </template>

          <template v-slot:item.action="{ item }" color="secondary">
            <v-btn text depressed icon @click="showDelete(item)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.publish="{ item }" color="secondary">
            <v-checkbox
              class="mt-0"
              v-model="item.publish"
              dense
              hide-details
              @change="doPublishReview(item)"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>Review {{currentReview.id}} {{currentReview.reviewText}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="doDeleteReview();dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import axios from "axios";

export default {
  name: "AdminReviews",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "email", value: "userEmail" },
      { text: "resourceName", value: "resourceName" },
      { text: "dateCreated", value: "dateCreated" },
      { text: "rating", value: "rating" },
      { text: "reviewText", value: "reviewText" },
      { text: "pub", value: "publish", sortable: false, align: "center" },
      { text: "del", value: "action", sortable: false, align: "center" }
    ],
    reviews: [],
    myTableState: {},
    dialog: false,
    currentReview: ""
  }),
  async created() {
    this.myTableState = this.getTableState("tableReviews");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateCreated";
      this.myTableState.sortDesc = true;
    }
    await this.fetchReviews();
  },
  methods: {
    showDelete(review) {
      this.currentReview = review;
      this.dialog = true;
    },
    async fetchReviews() {
      const url = "review";
      const postdata = {
        apiCommand: "GET_REVIEWS"
      };
      const response = await axios.post(url, postdata);

      if (response.data.status[0] === "OK") {
        this.reviews = response.data.reviews;
      }
    },
    async doDeleteReview() {
      const url = "review";
      const postdata = {
        apiCommand: "DELETE",
        review: {
          id: this.currentReview.id
        }
      };
      const response = await axios.post(url, postdata);

      if (response.data.status[0] === "OK") {
        this.reviews = response.data.reviews;
      }
      await this.fetchReviews();
    },
    async doPublishReview(review) {
      const url = "review";
      const postdata = {
        apiCommand: "PUBLISH",
        review: {
          id: review.id,
          publish: review.publish
        }
      };
      console.log(JSON.stringify(postdata));

      const response = await axios.post(url, postdata);

      if (response.data.status[0] === "OK") {
        this.reviews = response.data.reviews;
      }
      await this.fetchReviews();
    }
  }
};
</script>