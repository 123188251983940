<template>
  <v-container>
    <v-row no-gutters class="mb-2">
      <v-col class="glass2 pl-0">Releases</v-col>
      <v-col class="glass2 pl-0">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="pubreleases"
      class="pa-0"
      :search="search"
      :items-per-page="myTableState.itemsPerPage"
      :sort-by.sync="myTableState.sortBy"
      :sort-desc.sync="myTableState.sortDesc"
      :page.sync="myTableState.currentPage"
      :hide-default-footer="pubreleases.length <= myTableState.itemsPerPage"
      :footer-props="{
        'items-per-page-options':myTableState.itemsPerPageOptions
      }"
    >
      <template v-slot:default="props">
        <div v-if="!list">
          <v-row dense>
            <v-col
              :cols="$vuetify.breakpoint.smAndUp ? 4 : 6"
              v-for="(release, idx) in props.items"
              :key="release.id"
            >
              <transition name="fade" appear>
                <v-card
                  class="translucent-bg-1 mb-1"
                  :style="'transition-delay: ' + ((idx+1)/12) +'s'"
                  dense
                  :to="{name:'release',params:{id:release.id,releasename:normalizeUrl(release.name)}}"
                >
                  <v-row no-gutters class="pa-1" style="flex-wrap: nowrap;">
                    <v-col class="glass4 flex-grow-1 flex-shrink-0 pa-1 pl-2">{{release.name}}</v-col>
                    <v-col align="right" class="flex-grow-0 flex-shrink-1"></v-col>
                  </v-row>
                  <v-img :src="getFileUrl(release)" aspect-ratio="1"></v-img>
                </v-card>
              </transition>
            </v-col>
          </v-row>
        </div>
        <div dense v-if="list">
          <v-row v-for="(release, idx) in props.items" :key="release.id">
            <v-col>
              <transition name="fade" appear>
                <v-card
                  class="translucent-bg-1 mb-1"
                  :style="'transition-delay: ' + ((idx+1)/12) +'s'"
                  dense
                  :to="{name:'release',params:{id:release.id,releasename:normalizeUrl(release.name)}}"
                >
                  <v-row no-gutters class="pa-1" style="flex-wrap: nowrap;">
                    <v-col align="right" class="flex-grow-1 flex-shrink-2" cols="4">
                      <v-img :src="getFileUrl(release)" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col class="glass4 flex-grow-1 flex-shrink-0 pa-1 pl-2">
                      <v-row>
                        <v-col>{{release.name}}</v-col>
                      </v-row>
                      <v-row>
                        <v-col>Release Date: {{release.dateRelease}}</v-col>
                      </v-row>

                      <v-row>
                        <v-col>Tracks: {{release.refTrack.length}}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </transition>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");

export default {
  name: "Releases",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Release date", value: "dateRelease" },
      { text: "", value: "action", sortable: false, align: "right" }
    ],
    search: "",
    dialog: false,
    list: false,
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("releaseTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateRelease";
      this.myTableState.sortDesc = true;
      this.myTableState.itemsPerPage = 32;
    }
    this.fetchPubReleases();
  },
  computed: {
    ...mapStateReleases(["pubreleases"])
  },
  methods: {
    ...mapActionsReleases(["fetchPubReleases"]),
    getFileUrl(release) {
      if (release.filesImageArtWork) {
        let fileEntity = release.filesImageArtWork[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "releases" +
            "/" +
            release.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }
};
</script>