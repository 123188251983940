<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{name:'admmailinglists'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1" v-if="!isnew">
          EDIT MAILING LIST
          <br />
        </div>
        <div class="font-weight-thin display-1" v-if="isnew">
          NEW MAILING LIST
          <br />
        </div>
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="mailinglist"
      schemaName="MailingList"
      :formData="mailinglistData"
      namespace="mailinglists"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row justify="end" class="pa-2">
        <v-btn :to="{name:'admmailinglists'}" class="ma-1">
          Cancel
          <v-icon right>mdi-close-circle</v-icon>
        </v-btn>
        <v-btn  @click="dialog=true" v-if="!mailinglistData.locked && $route.params.id" class="ma-1">
          Delete
          <v-icon right>mdi-trash-can</v-icon>
        </v-btn>
        <v-btn  @click="saveMailingList(false)" class="ma-1">
          Save
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>MailingList {{mailinglistData.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="saveMailingList(true);dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapActions: mapActionsMailingLists,
  mapGetters: mapGettersMailingLists
} = createNamespacedHelpers("mailinglists");

const {
  mapState: mapStateUsers,
  mapActions: mapActionsUsers
} = createNamespacedHelpers("users");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmMailingList",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    mailinglistData: {},
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaMailingList: SchemasConst.MailingList,
    refObjects: {}
  }),
  async created() {
    await this.fetchUsers();
    this.refObjects["refUser"] = this.users;
    await this.fetchMailingLists({force:true,mailingId:null});
    if (this.$route.params.id) {
      const ev = await this.getMailingListById(this.$route.params.id);
      if (ev === undefined) {
        this.mailinglistData.id = this.$route.params.id;
        this.isnew = true;
      } else {
        this.mailinglistData = this.getMailingListById(this.$route.params.id);
      }
    }
  },
  computed: {
    ...mapGettersMailingLists(["getMailingListById"]),
    ...mapStateUsers(["users"])
  },
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsMailingLists(["fetchMailingLists", "updateMailingList"]),
    ...mapActionsUsers(["fetchUsers"]),
    getvModel(name) {
      return this.mailinglistData[name];
    },
    uploadFiles() {
      this.schemaMailingList.forEach(field => {
        if (field.itemType === "files") {
          if (this.mailinglistData[field.name]) {
            this.mailinglistData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "mailinglists/" + this.$route.params.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveMailingList(deleteMailingList) {
      console.log("saveMailingList");
      let cmd = this.isnew ? "CREATE" : "UPDATE";
      cmd = deleteMailingList ? "DELETE" : cmd;

      const success = await this.updateMailingList({
        mailinglist: this.mailinglistData,
        cmd: cmd
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.fetchMailingLists({force:true,mailingId:null});
        this.$router.push({ name: "admmailinglists" });
      }
    }
  }
};
</script>