<template>
  <div class="player" ref="player">
    <!-- <v-img :src="waveFormUrl"></v-img> -->
    <canvas
      ref="canvas"
      class="canvas"
      @mousemove="mouseover"
      @mouseleave="mouseleave"
      @mousedown="mousedown"
      @mouseup="mouseup"
      @contextmenu.prevent
    ></canvas>
    <audio
      ref="audio"
      :src="audioUrl"
      :preload="preload"
      controls
      controlslist="nodownload"
      style="height: 0px"
      @timeupdate="timeupdate"
      @canplay="canplay = true"
      @playing="
        playing = true;
        play();
      "
      @pause="
        playing = false;
        drawPlayer();
      "
      @stalled="playing = false"
      @waiting="playing = false"
      @ended="playing = false"
      hidden
    ></audio>
  </div>
</template>
<style scoped>
.player {
  background-color: rgba(255, 255, 255, 0.08);
  height: auto;
  margin: 0px;
}
.canvas {
  width: 100%;
}
</style>
<script>

export default {
  name: "AudioPlayerComponent",
  data: () => ({
    heightRatioDefault: 0.25,
    buttonSize: 260,
    bottomBar: 50,
    sizeRatio: 0,
    waveImage: null,
    waveImage2: null,
    playImage: null,
    playImage_o: null,
    pauseImage: null,
    pauseImage_o: null,
    ctx: null,
    canvas: null,
    audio: null,
    duration: null,
    currentTime: null,
    canplay: false,
    playing: false,
    isHoover: false,
    overButton: false,
    waveImageRetry: 0,
    waveImageFail: false,
    reloadTimeOut: null,
    rangeIn: 0,
    rangeOut: 0,
    preload: "none",
    reportInterval: 8,
    lastReportTime: 0,
  }),
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    audioUrl: {
      type: String,
    },
    waveFormUrl: {
      type: String,
    },
    waveFormUrl2: {
      type: String,
    },
    heightRatio: {
      type: Number,
      default: 0.25,
    },
    showRange: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.loadImage();
    this.loadWaveImages();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.drawPlayer();
    });
    this.drawPlayer();
  },
  watch: {
    heightRatio: () => {
      setTimeout(
        function () {
          try {
            this.drawPlayer();
          } catch (e) {
            console.log(e);
          }
        }.bind(this),
        300
      );
    },
  },
  methods: {
    init() {
      if (this.showRange) {
        this.preload = "auto";
      }
    },
    play() {
      this.$emit("play", { id: this.id, time: this.currentTime });
    },
    stop() {
      this.audio.pause();
    },
    drawPlayer() {
      if (this.ctx === null) {
        this.canvas = this.$refs.canvas;
        this.ctx = this.canvas.getContext("2d");
        this.audio = this.$refs.audio;
      }
      this.canvas.width = 2000;
      this.sizeRatio =
        this.$refs.player !== undefined
          ? this.$refs.player.offsetWidth / this.canvas.width
          : 1;
      if (this.$refs.player !== undefined) {
        this.$refs.player.style.height = this.canvas.offsetHeight + "px";
      }
      this.canvas.height = this.canvas.width * this.heightRatio;
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.imageSmoothingEnabled = true;

      if (this.isHoover) {
        this.ctx.fillStyle = "rgba(255, 255, 255, 0.04)";

        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      }
      if (this.waveImage.exists) {
        this.ctx.drawImage(
          this.waveImage,
          0.0,
          0.0,
          this.canvas.width,
          this.canvas.height
        );
      }

      this.ctx.font = "50px Consolas";
      this.ctx.fillStyle = "white";
      //   this.ctx.fillText(
      //     this.$refs.player.offsetWidth +
      //       " " +
      //       this.canvas.width * this.heightRatio,
      //     5,
      //     this.canvas.height - 2
      //   );

      if (this.duration) {
        this.ctx.fillStyle = "white";
        const xPos = (this.canvas.width / this.duration) * this.currentTime;

        //ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        if (this.waveImage2.exists === true) {
          this.ctx.drawImage(
            this.waveImage2,
            0,
            0,
            xPos,
            500,
            0,
            0,
            xPos,
            this.canvas.height
          );
        }
      }

      this.ctx.fillStyle = "rgba(255, 255, 255, 0.3)";

      this.ctx.fillRect(
        0,
        this.canvas.height - this.bottomBar,
        this.canvas.width,
        this.bottomBar
      );

      if (this.duration) {
        const xPos = (this.canvas.width / this.duration) * this.currentTime;
        this.ctx.fillRect(xPos, 0, 4, this.canvas.height);

        this.ctx.fillStyle = "black";
        this.ctx.fillRect(
          this.canvas.width - 400,
          this.canvas.height - this.bottomBar,
          400,
          this.bottomBar
        );

        this.ctx.fillStyle = "white";
        const timetext =
          this.formatTime(this.currentTime) +
          " / " +
          this.formatTime(this.duration);
        this.ctx.fillText(
          timetext,
          this.canvas.width - 400 + 12,
          this.canvas.height - 8
        );
      }

      if (this.playing) {
        this.ctx.drawImage(
          this.overButton ? this.pauseImage_o : this.pauseImage,
          10,
          10,
          this.buttonSize,
          this.buttonSize
        );
      } else {
        this.ctx.drawImage(
          this.overButton ? this.playImage_o : this.playImage,
          10,
          10,
          this.buttonSize,
          this.buttonSize
        );
      }
    },
    loadImage() {
      this.playImage = new window.Image();
      this.playImage.src = require("@/assets/imgs/playImage.png");

      this.playImage_o = new window.Image();
      this.playImage_o.src = require("@/assets/imgs/playImage_o.png");

      this.pauseImage = new window.Image();
      this.pauseImage.src = require("@/assets/imgs/pauseImage.png");

      this.pauseImage_o = new window.Image();
      this.pauseImage_o.src = require("@/assets/imgs/pauseImage_o.png");
    },
    loadWaveImages() {
      console.log("LoadWaveImages " + this.waveImageRetry);
      this.waveImage = new window.Image();
      this.waveImage.src = this.waveFormUrl;
      this.waveImage.addEventListener("load", () => {
        this.waveImage.exists = true;
        this.checkFailed();
        this.drawPlayer();
      });
      this.waveImage.addEventListener("error", () => {
        this.waveImage.exists = false;
        this.checkFailed();
        this.drawPlayer();
      });
      this.waveImage2 = new window.Image();
      this.waveImage2.src = this.waveFormUrl2;
      this.waveImage2.addEventListener("load", () => {
        this.waveImage2.exists = true;
        this.checkFailed();
        this.drawPlayer();
      });
      this.waveImage2.addEventListener("error", () => {
        this.waveImage2.exists = false;
        this.checkFailed();
        this.drawPlayer();
      });
    },
    checkFailed() {
      this.waveImageFail = !this.waveImage2.exists || !this.waveImage.exists;
      if (this.waveImageFail == true && this.waveImageRetry < 10) {
        clearTimeout(this.reloadTimeOut);
        this.reloadTimeOut = window.setTimeout(() => {
          this.loadWaveImages();
          this.waveImageRetry++;
        }, 1500);
      } else {
        clearTimeout(this.reloadTimeOut);
      }
    },
    progress(event) {
      var range = 0;
      var bf = event.srcElement.buffered;
      var time = event.srcElement.currentTime;

      while (!(bf.start(range) <= time && time <= bf.end(range))) {
        range += 1;
      }
      // var loadStartPercentage = bf.start(range) / event.srcElement.duration;
      // var loadEndPercentage = bf.end(range) / event.srcElement.duration;
      // var loadPercentage = loadEndPercentage - loadStartPercentage;

      //console.log(loadPercentage);
    },
    timeupdate(event) {
      this.currentTime = event.srcElement.currentTime;

      if (
        Math.round(this.currentTime % this.reportInterval) === 0 &&
        this.lastReportTime !== Math.round(this.currentTime)
      ) {
        this.lastReportTime = Math.round(this.currentTime);
        this.$emit("playing", { id: this.id, time: this.currentTime });
      }

      this.duration = event.srcElement.duration;
      this.drawPlayer();
    },
    formatTime(seconds) {
      seconds = Math.round(seconds);
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = seconds % 60;

      var formattedTime = "";

      if (hours > 0) {
        formattedTime += hours.toString().padStart(2, "0") + ":";
      }

      formattedTime +=
        minutes.toString().padStart(2, "0") +
        ":" +
        remainingSeconds.toString().padStart(2, "0");

      return formattedTime;
      // return moment(moment.duration(ts * 1000)._data).format("mm:ss");
    },
    mouseover(event) {
      this.overButton = this.isMouseOverPlayButton(event);
      this.canvas.style.cursor = this.overButton ? "pointer" : "";
      this.isHoover = true;
      this.drawPlayer();
    },
    mouseleave(event) {
      console.log("mouseleave " + event.type);
      this.overButton = false;
      this.isHoover = false;
      this.drawPlayer();
    },
    mouseup(event) {
      console.log("mouseup " + event.type);
      const isTouchEvent = window.ontouchstart !== undefined;
      if (isTouchEvent) {
        this.overButton = false;
      } else {
        this.overButton = this.isMouseOverPlayButton(event);
      }
      this.drawPlayer();
    },
    intersects(x, y, cx, cy, r) {
      var dx = x - cx;
      var dy = y - cy;
      return dx * dx + dy * dy <= r * r;
    },
    isMouseOverPlayButton(event) {
      const centerButton = (10 + this.buttonSize / 2) * this.sizeRatio;
      const radius = (this.buttonSize / 2) * this.sizeRatio;

      return this.intersects(
        event.offsetX,
        event.offsetY,
        centerButton,
        centerButton,
        radius
      );
    },
    mousedown(event) {
      this.overButton = this.isMouseOverPlayButton(event);

      console.log("mousedown " + event.type);

      if (this.overButton) {
        if (this.playing) {
          this.audio.pause();
        } else {
          this.audio.play();
        }
        setTimeout(() => {
          this.drawPlayer();
        }, 50);
      } else {
        var playPos = 0;
        if (this.duration) {
          playPos =
            (this.duration / this.$refs.player.offsetWidth) * event.offsetX;
        }

        this.audio.play();
        this.audio.currentTime = playPos;
      }
    },
  },
};
</script>
