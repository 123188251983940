<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{ path: $store.state.route.from.fullPath }" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1 mb-1" v-if="!isnew">
          EDIT Campaign
          <br />
        </div>
        <div class="font-weight-thin display-1 mb-1" v-if="isnew">
          NEW Campaign
          <br />
        </div>
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="mailing"
      schemaName="Mailing"
      :formData="mailingData"
      namespace="mailings"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row  justify="end" class="pa-2">
        <v-btn :to="{name:'admmailings'}" class="ma-1">
          Cancel
          <v-icon right>mdi-close-circle</v-icon>
        </v-btn>
        <v-btn  @click="dialog=true" v-if="$route.params.id" class="ma-1">
          Delete
          <v-icon right>mdi-trash-can</v-icon>
        </v-btn>
        <v-btn  @click="doUpdateMailing()" class="ma-1">
          Update
          <v-icon right>mdi-update</v-icon>
        </v-btn>
        <v-btn  @click="saveMailing(false)" class="ma-1">
          Save
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>Mailing {{mailingData.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="saveMailing(true);dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- onload="this.style.height=contentWindow.document.body.scrollHeight +'px';" -->
    <iframe
      :src="previewUrl"
      style="width:100%;height:800px;background-color:white"
      frameborder="0"
    ></iframe>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";


const {
  mapActions: mapActionsMailings,
  mapGetters: mapGettersMailings
} = createNamespacedHelpers("mailings");

const {
  mapState: mapStateMailingLists,
  mapActions: mapActionsMailingLists
} = createNamespacedHelpers("mailinglists");

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");


export default {
  name: "AdmMailing",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    mailingData: {
      subject:""
    },
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaMailing: SchemasConst.Mailing,
    refObjects: {},
    previewUrl: ""
  }),
  async created() {
    await this.fetchMailingLists({force:true,mailingId:this.$route.params.id});
    await this.fetchReleases();
    this.refObjects["refMailingList"] = this.mailinglists;
    this.refObjects["refRelease"] = this.releases;
    await this.fetchMailings();
    if (this.$route.params.id) {
      const ev = await this.getMailingById(this.$route.params.id);
      if (ev === undefined) {
        this.mailingData.id = this.$route.params.id;
        const lastMailing = await this.getLastMailing;
        console.log("get last mailing " +  JSON.stringify(lastMailing,null,2));
        if(lastMailing != null)
        {
          
          this.mailingData.bodyColor = lastMailing.bodyColor;
          this.mailingData.fontColor = lastMailing.fontColor;
          this.mailingData.primaryColor = lastMailing.primaryColor;
          this.mailingData.secondaryColor = lastMailing.secondaryColor;
          this.mailingData.senderEMail = lastMailing.senderEMail;
          this.mailingData.senderName = lastMailing.senderName;
          this.mailingData.subject = lastMailing.subject;

        }
        console.log("this mailing " +  JSON.stringify(this.mailingData,null,2));
        this.isnew = true;
      } else {
        this.mailingData = this.getMailingById(this.$route.params.id);
      }
    }
    this.previewUrl =
      this.ac.apiBaseURL + "mailpreview/" + this.$route.params.id;
  },
  computed: {
    ...mapGettersMailings(["getMailingById","getLastMailing"]),
    ...mapStateMailingLists(["mailinglists"]),
    ...mapStateReleases(["releases"]),
    refDataCmp: {
      get: function() {
        return null;
      }
    }
  },
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsMailings(["fetchMailings", "updateMailing"]),
    ...mapActionsReleases(["fetchReleases"]),
    ...mapActionsMailingLists(["fetchMailingLists"]),
    getvModel(name) {
      return this.mailingData[name];
    },

    uploadFiles() {
      this.schemaMailing.forEach(field => {
        if (field.itemType === "files") {
          if (this.mailingData[field.name]) {
            this.mailingData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "mailings/" + this.$route.params.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveMailing(deleteMailing) {
      console.log("saveMailing");
      let cmd = this.isnew ? "CREATE" : "UPDATE";
      cmd = deleteMailing ? "DELETE" : cmd;

      const success = await this.updateMailing({
        mailing: this.mailingData,
        cmd: cmd
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admmailings" });
      }
    },
    async doUpdateMailing() {
      console.log("updateMailing");

      this.previewUrl =
        this.ac.apiBaseURL +
        "mailpreview/" +
        this.$route.params.id +
        "/" +
        Math.random();

      const success = await this.updateMailing({
        mailing: this.mailingData,
        cmd: "UPDATE"
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
      }
    },
  }
};
</script>