import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'

import BuzzBassLogo from '@/components/BuzzBassLogo.vue'
import beatport from "@/components/beatport.vue";

Vue.use(Vuetify);

  export default new Vuetify({
    icons: {
      values: {
        buzzbasslogo: { // name of our custom icon
          component: BuzzBassLogo, // our custom component
        },
        beatport: { // name of our custom icon
          component: beatport, // our custom component
        },
      },
    },
    theme: {
        dark: true,
        themes: {
          light: {
            primary:  colors.blue.lighten3, //colors.blueGrey.darken1, // #E53935
            secondary: colors.amber.base, // #FFCDD2
            accent: colors.amber.lighten1, // #3F51B5
          },
          dark: {
            primary: colors.amber.darken1,
            secondary: '#121212',
            accent: colors.amber.base,
            error: '#FF5252',
            info: '#2196F3',
            success: '#4fac25',
            warning: '#ea5a00',
          },
        },
      },
});
