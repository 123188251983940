<template>
  <v-container>
    Campaign Stats Overview
    <v-row no-gutters>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="refresh" class="mr-2" icon text depressed exact>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="campaignStats"
          :search="search"
          :headers="headers"
          sort-by="dateSent"
          sort-desc
          must-sort
          class="transparent-bg"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          style="cursor:pointer"
          
        >
          <template v-slot:item.action="{ item }" color="secondary">
            <v-btn :to="{path:'/admin/track/'+ item.id}" text depressed icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.dateSent="{ item }"><div class="text-no-wrap"> {{item.dateSent}}</div></template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: mapActionsStats,
  mapState: mapStateStats
} = createNamespacedHelpers("stats");

export default {
  name: "AdmStats",
  mixins: [defaultMixin],
  dialog: false,
  data: () => ({
    headers: [
      { text: "Campaign Name", value: "campaignName" },
      { text: "Date", value: "dateSent" },
      { text: "Recip.", value: "recipientsCount" },
      { text: "Users React.", value: "uniqueUsersReacted" },
      { text: "Plays Count", value: "playsCount" },
      { text: "Dl Count", value: "downloadCount" }
    ],
    search: "",
    dialog: false
  }),
  computed: {
    ...mapStateStats(["campaignStats"])
  },
  methods: {
    ...mapActionsStats(["fetchStats"]),
    rowClickDetail(e) {
      console.log("rowClickDetail " + e.campaignId);
      this.$router.push({ path: "/admin/statdetails/" + e.campaignId });
    },
    async refresh()
    {
      await this.fetchStats();
    }
  },
  async created() {
    await this.fetchStats();
  }
};
</script>