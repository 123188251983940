<template>
  <v-container class="pa-0">
    <v-form class="ma-4">
      <v-row no-gutters>
        <v-col>
          <div class="font-weight-thin display-2 mb-2" v-if="$route.params.id">
            FILE UPLOAD
            <br />
          </div>
          <div class="font-weight-light">{{ $route.params.id }}</div>
          <v-text-field
            v-model="fileName"
            label="File name"
            outlined
            color="accent"
            hide-details
            class="mt-4"
          ></v-text-field>
          <v-file-input
            :rules="rules"
            v-model="file"
            @change="onFileChange"
            accept="*"
            placeholder="Pick an File"
            append-icon="mdi-file"
            outlined
            color="accent"
            class="mt-4"
            label="Event Flyer"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn class="ml-2" @click="uploadFile(file,'events/87213597653219658')">
            Save
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import defaultMixin from '@/mixins/defaultMixin'
import { createNamespacedHelpers } from "vuex";

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "FileUpload",
  mixins: [defaultMixin],     
  data: () => ({
    modal: false,
    file: null,
    imageUrl: null,
    fileName: "",
    progressData: 0,
    rules: [
      value =>
        !value ||
        value.size < 800000000 ||
        "Flyer size should be less than 8 MB!"
    ]
  }),
  async created() {},
  computed: {},
  methods: {
    ...mapActionsDialog(["setMessage"]),

    onFileChange() {
      let reader = new FileReader();
      reader.onload = () => {
        //this.eventData.image = reader.result;
      };
      //reader.readAsDataURL(this.file);
    },
  }
};
</script>