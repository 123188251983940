<template>
  <v-container>
    <div v-for="rel in pubreleases" :key="rel.id">
      <div class="caption">{{rel.name}}</div>
      <div v-for="trk in rel.tracks" :key="trk.id" class="pb-10">
        <AudioPlayer
          :ref="'audio'+trk.id"
          :name="trk.name"
          :heightRatio="0.18"
          :showRange="true"
          :id="trk.id"
          :audioUrl="ac.apiBaseURL +'res/tracks/' + trk.id +'/' +trk.filesAudioPrewiew[0].id + trk.filesAudioPrewiew[0].ext"
          :waveFormUrl="ac.apiBaseURL +'res/tracks/' + trk.id +'/' +trk.filesAudioPrewiew[0].id + '.png'"
          :waveFormUrl2="ac.apiBaseURL +'res/tracks/' + trk.id +'/' +trk.filesAudioPrewiew[0].id + '2.png'"
          @play="stopOthers"
        />
      </div>
    </div>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import AudioPlayer from "@/components/AudioPlayer.vue";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");

export default {
  mixins: [defaultMixin],
  data: () => ({}),
  components: {
    AudioPlayer
  },

  async created() {
    this.fetchPubReleases();
  },
  computed: {
    ...mapStateReleases(["pubreleases"])
  },
  methods: {
    ...mapActionsReleases(["fetchPubReleases"]),
    stopOthers(id) {
      console.log("stop except : " + id);
      let thisId = id;
      this.pubreleases.forEach(release => {
        release.tracks.forEach(track => {
          if (track.id != thisId) {
            this.$refs["audio" + track.id][0].stop();
          }
        });
      });
    }
  }
};
</script>