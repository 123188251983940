import axios from "axios";
import { appconfig } from '@/config';
import { createNamespacedHelpers } from "vuex";
import { SchemasConst } from '@/schemas';

import moment from "moment"


const {
    mapState: mapStateUser,
    mapActions: mapActionsUser
} = createNamespacedHelpers("user");

const {
    mapState: mapStateShop,
    mapActions: mapActionsShop
} = createNamespacedHelpers("shop");

const { mapState: mapStateGlobal,
    mapGetters: mapGettersGlobal,
} = createNamespacedHelpers("global");

const { mapActions: mapActionsDialog,
} = createNamespacedHelpers("dialog");

const created = function() {
    console.log('Mixin created! ' + this.$route.name);
}

const computed = {
    ...mapStateGlobal(["uploadProgress","tableStates","downloadProgress"]),
    ...mapGettersGlobal(["getTableState"]),
    ...mapStateUser(["isAdmin","isEditor", "isSignedIn", "isMember", "redirectAfterLogin","accessToken","userId","isShopper","init","paypalConnectURL","prodShopEnabled"]),
    ...mapStateShop(["cart","orders","order","newPurchase"]),
   
}

const methods = {
    ...mapActionsUser(["signIn", "signOut","signUp","activateAccount", "setRedirectAfterLogin", "signInState", "resetPassword", "sendPasswordReset"]),
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsShop(["orderAction","fetchCart","fetchOrder","placeOrder","fetchOrders","downloadOrder","checkPayPalAccessOauthToken"]),
    deCamel(str) {
        const regex = /^([a-z]+)|([A-Z][a-z]+)/gm;
        const subst = `$1$2 `;
  
        // The substituted value will be contained in the result variable
        let result = str.replace(regex, subst);
  
        result =
          result.length > 1
            ? result.substring(0, 1).toUpperCase() +
              result.substring(1, result.length)
            : result;
  
        return result;
      },
    async uploadFile(file, path, altFileName, isprotected, notes) {
        console.log("UPLOAD FILE" + file.name + " " + path + " " + altFileName);
        const url = "file";
        try {

            await axios.post(url, file, {
                headers: {
                    filename: altFileName ? altFileName : file.name,
                    path: path,
                    isprotected : isprotected, 
                    notes:notes
                },
            });

            file = null;

        } catch (error) {
            this.$store.dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return null;
        }
    },
    async playStats(aevent,track,isPlaying) {
        console.log("playStats : " + JSON.stringify(aevent))
        try {
          const url = "ps";
  
          const postdata = {
            playTime : aevent.time,
            isPlaying : isPlaying,
            track: {
              id: track.id,
              name: track.name
            }
          };
          const response = await axios.post(url, postdata);
  
          if (response.data.status[0] === "OK") {
            console.log("OK post playStats");
          }
        } catch (error) {
          console.log("error post playStats " + error);
        }
      },
    async uploadSubmission(file, path, altFileName, isprotected, notes) {
        console.log("UPLOAD FILE" + file.name + " " + path + " " + altFileName);
        const uri = "putsubmission";
        try {

            await axios.post(uri, file, {
                headers: {
                    filename: altFileName ? altFileName : file.name,
                    path: path,
                    isprotected : isprotected, 
                    notes:notes
                },
            });

        } catch (error) {
            this.$store.dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return null;
        }
    },
    extractFileExtension(name) {
        const regex = /.*(\.[a-z0-9]{3,4})/gmi;
        let m;
        if ((m = regex.exec(name)) !== null && m.length == 2) {
            return m[1];
        } else {
            return '';
        }
    },
    async getObjectId() {
        console.log("GET OBJECT ID");
        const url = "objectId";
        try {

            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                return response.data.newId;
            }

        } catch (error) {
            this.$store.dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return null;
        }
    },
    async getSchema(etype) {
        console.log("GET ENTITY " + etype);
        const url = "entity";
        try {

            const response = await axios.post(url, {
                "classname": "org.vodka.entities." + etype
            });


            return response.data;


        } catch (error) {
            this.$store.dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return null;
        }
    },
    normalizeUrl(input)
    {
        return input.replace(/ /g,'-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
    },
    formatAmountCurrency(val, currency) {
        return parseFloat(val).toFixed(2) + " " + (currency !== undefined ? currency : "GBP");
    },
    isEmptyObj(obj)
    {
        return !obj || Object.keys(obj).length === 0;
        // if( )
        // {
        //     return true;
        // }
        // else{
        //     return false;
        // }

    }


}
const data = () => ({
    ac: appconfig,
    schemas: SchemasConst,
    moment : moment,
    host : window.location.host,
    isDev : window.location.host === 'dev.buzzbass.com:8080'
})

export default {
    data,
    created,
    computed,
    methods,
}