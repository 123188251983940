<template>
  <v-container>
    <v-row no-gutters class="mb-2">
      <v-col class="glass2 pl-0">Artists</v-col>
    </v-row>
    <v-data-iterator
      :items="artists"
      class="pa-0"
      :items-per-page="myTableState.itemsPerPage"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row dense>
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? 6 : 12"
            v-for="(artist,idx) in props.items"
            :key="artist.id"
            class="ma-0"
          >
            <transition name="fade" appear>
              <v-card
                :style="'transition-delay: ' + ((idx+1)/8) +'s'"
                :class="'translucent-bg-1 mb-0'"
                dense
                :to="{name:'artist',params:{id:artist.id,artistname:normalizeUrl(artist.name)}}"
              >
                <v-card-title class="pa-0">{{artist.name}}</v-card-title>
                <v-card-text class="pa-0">
                  <v-img :src="getFileUrl(artist)" aspect-ratio="1"></v-img>
                </v-card-text>
              </v-card>
            </transition>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
const {
  mapState: mapStateArtists,
  mapActions: mapActionsArtists
} = createNamespacedHelpers("artists");

export default {
  name: "Artists",
  mixins: [defaultMixin],
  data: () => ({
    myTableState: {}
  }),
  created() {
    this.fetchArtists();
  },
  computed: {
    ...mapStateArtists(["artists"])
  },
  methods: {
    ...mapActionsArtists(["fetchArtists"]),
    getFileUrl(artist) {
      if (artist.filesImageArtist) {
        let fileEntity = artist.filesImageArtist[0];
        if (!fileEntity.url) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "artists" +
            "/" +
            artist.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return fileEntity.url;
        }
      }
    }
  }
};
</script>