<template>
    <v-container class="pa-4 font-weight-thin" v-html="sitecontent.tosTextArea">

    </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateMixtapes,
  mapActions: mapActionsMixtapes
} = createNamespacedHelpers("sitecontent");


export default {
      name: "Privacy",
  mixins: [defaultMixin],
    created() {
    this.fetchSiteContent();
  },
  computed: {
    ...mapStateMixtapes(["sitecontent"])
  },
  methods: {
    ...mapActionsMixtapes(["fetchSiteContent"]),
  }
}
</script>