import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_RELEASES_PENDING = 'FETCH_RELEASES_PENDING';
const FETCH_RELEASES_FULFILLED = 'FETCH_RELEASES_FULFILLED';
const FETCH_RELEASES_ERROR = 'FETCH_RELEASES_ERROR';

const FETCH_PUB_RELEASES_PENDING = 'FETCH_PUB_RELEASES_PENDING';
const FETCH_PUB_RELEASES_FULFILLED = 'FETCH_PUB_RELEASES_FULFILLED';
const FETCH_PUB_RELEASES_ERROR = 'FETCH_PUB_RELEASES_ERROR';

const UPDATE_RELEASE_PENDING = 'UPDATE_RELEASE_PENDING';
const UPDATE_RELEASE_FULFILLED = 'UPDATE_RELEASE_FULFILLED';
const UPDATE_RELEASE_ERROR = 'UPDATE_RELEASE_ERROR';


const state = {

    fetchReleasesPending: false,
    updateReleasePending: false,
    releases: [],
    pubreleases: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_RELEASES_PENDING](state) { state.fetchReleasesPending = true; },
    [FETCH_RELEASES_FULFILLED](state, releases) {
        state.releases = releases;
        state.fetchReleasesPending = false;
    },
    [FETCH_RELEASES_ERROR](state) { state.fetchPubReleasesPending = false; },

    [FETCH_PUB_RELEASES_PENDING](state) { state.fetchPubReleasesPending = true; },
    [FETCH_PUB_RELEASES_FULFILLED](state, pubreleases) {
        state.pubreleases = pubreleases;
        state.fetchReleasesPending = false;
    },
    [FETCH_PUB_RELEASES_ERROR](state) { state.fetchPubReleasesPending = false; },

    [UPDATE_RELEASE_PENDING](state) { state.updateReleasePending = true; },
    [UPDATE_RELEASE_FULFILLED](state) { state.updateReleasePending = false; },
    [UPDATE_RELEASE_ERROR](state) { state.updateReleasePending = false; },

};
const actions = {
    async createRelease({ dispatch }) {
        console.log("CREATE_RELEASE ");
        try {
            const url = 'releases';

            const postdata = {
                apiCommand: 'CREATE',
            }

            const response = await axios.post(url, postdata);

            if (response.data.status[0] === "OK") {
                return response.data.newId;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return null;
        }
    },
    async updateRelease({ commit, dispatch }, args) {
        commit(UPDATE_RELEASE_PENDING);

        console.log("UPDATE_RELEASE ");
        try {
            const url = 'releases';

            const postdata = {
                apiCommand: args.cmd,
                release: args.release
            }

            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));

            if (response.data.status[0] === "OK") {
                commit(UPDATE_RELEASE_FULFILLED);
                return true;
            }
        } catch (error) {
            commit(UPDATE_RELEASE_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchReleases({ commit, dispatch }) {
        commit(FETCH_RELEASES_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_RELEASES");
        try {
            const url = 'releases';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_RELEASES_FULFILLED, response.data.releases);
                return true;
            }
        } catch (error) {
            commit(FETCH_RELEASES_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return false;
        }
    },

    async fetchPubReleases({ commit, dispatch }) {
        commit(FETCH_PUB_RELEASES_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_PUB_RELEASES_PENDING");
        try {
            const url = 'pubreleases';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_PUB_RELEASES_FULFILLED, response.data.releases);
                return true;
            }
        } catch (error) {
            commit(FETCH_PUB_RELEASES_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return false;
        }
    },
};

const getters = {
    getReleaseById: (state) => (id) => {
        return state.releases.find(release => release.id === id) || {};
    },
    getLastRelease: (state) => {
        if (state.releases.length === 0) {
            return null;
        } else {

            return state.releases[state.releases.length - 1]
        }
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};