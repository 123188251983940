import axios from 'axios';

const FETCH_SUBMISSIONS_PENDING = 'FETCH_SUBMISSIONS_PENDING';
const FETCH_SUBMISSIONS_FULFILLED = 'FETCH_SUBMISSIONS_FULFILLED';
const FETCH_SUBMISSIONS_ERROR = 'FETCH_SUBMISSIONS_ERROR';

const UPDATE_SUBMISSION_PENDING = 'UPDATE_SUBMISSION_PENDING';
const UPDATE_SUBMISSION_FULFILLED = 'UPDATE_SUBMISSION_FULFILLED';
const UPDATE_SUBMISSION_ERROR = 'UPDATE_SUBMISSION_ERROR';

const state = {

    fetchSubmissionsPending: false,
    updateSubmissionPending: false,
    submissions: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_SUBMISSIONS_PENDING](state) { state.fetchSiteContentPending = true; },
    [FETCH_SUBMISSIONS_FULFILLED](state, submissions) {
        state.submissions = submissions;
        state.fetchSiteContentPending = false;
    },
    [FETCH_SUBMISSIONS_ERROR](state) { state.fetchSiteContentPending = false; },

    [UPDATE_SUBMISSION_PENDING](state) { state.updateSubmissionPending = true; },
    [UPDATE_SUBMISSION_FULFILLED](state) { state.updateSubmissionPending = false; },
    [UPDATE_SUBMISSION_ERROR](state) { state.updateSubmissionPending = false; },

};


const actions = {

    async fetchSubmissions({  dispatch,commit }) {
        commit(FETCH_SUBMISSIONS_PENDING);
        console.log("FETCH_SUBMISSIONS");
        try {
            const url = 'getsubmissions';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_SUBMISSIONS_FULFILLED, response.data.submissions);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
    async updateSubmissions({  dispatch,commit },args) {
        commit(UPDATE_SUBMISSION_PENDING);
        console.log("UPDATE_SUBMISSION");
        try {
            const url = 'getsubmissions';
            const postdata = {
                apiCommand: args.command,
                submission:args.submission,
                
            }

            console.log(JSON.stringify(postdata,null,2));

            const response = await axios.post(url,postdata);

            if (response.data.status[0] === "OK") {
                commit(UPDATE_SUBMISSION_FULFILLED);
                return true;
            }
        } catch (error) {
            commit(UPDATE_SUBMISSION_ERROR);
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
}

export default {
    state,
    mutations,
    actions,
    namespaced: true,
};