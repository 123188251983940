<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="ma-0 mb-2" style="padding-top: 56.25%; position: relative">
        <iframe
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :src="
            'https://www.youtube.com/embed/' +
            sitecontent.youtubeVideoFront +
            '?rel=0&amp;showinfo=0'
          "
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-col>
    </v-row>

    <v-row no-gutters justify="end" class="pa-1">
      <v-btn
        href="https://justbuzzbass.bandcamp.com/follow_me"
        target="bandcamp"
        color="#1da0c3"
        class="ma-1"
        :icon="$vuetify.breakpoint.smAndDown"
      >
        <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-bandcamp</v-icon>
        <div v-if="!$vuetify.breakpoint.smAndDown">BandCamp</div>
      </v-btn>
      <v-btn
        href="https://open.spotify.com/artist/0TBRjJ3elWZAvEnDYdWIWJ"
        target="spotify"
        color="#1DB954"
        class="ma-1"
        :icon="$vuetify.breakpoint.smAndDown"
      >
        <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-spotify</v-icon>
        <div v-if="!$vuetify.breakpoint.smAndDown">Spotify</div>
      </v-btn>
      <v-btn
        :href="ac.buildLink('soundcloud', sitecontent.linkSoundcloudPage)"
        target="sc"
        color="#FE5000"
        class="ma-1"
        :icon="$vuetify.breakpoint.smAndDown"
      >
        <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-soundcloud</v-icon>
        <div v-if="!$vuetify.breakpoint.smAndDown">Soundcloud</div>
      </v-btn>
      <v-btn
        href="https://www.beatport.com/artist/vodkah/996955"
        target="appl"
        color="#01ff95"
        class="ma-1"
        :icon="$vuetify.breakpoint.smAndDown"
      >
        <v-icon :left="!$vuetify.breakpoint.smAndDown">$beatport</v-icon>

        <div v-if="!$vuetify.breakpoint.smAndDown">Beatport</div>
      </v-btn>
    </v-row>

    <v-row no-gutters class="ma-0 mt-3">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="release in pubreleases" :key="release.id">
          <router-link
            :to="{
              name: 'release',
              params: {
                id: release.id,
                releasename: normalizeUrl(release.name),
              },
            }"
          >
            <v-img :src="getFileUrl(release)" aspect-ratio="1">.</v-img>
          </router-link>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </v-row>

    <v-row no-gutters class="ma-4 mb-0 pt-5 glass4" v-if="!subscribeSucess">
      <v-col>Subscribe to our Newsletter to get the latest release infos</v-col>
    </v-row>

    <v-row dense class="ml-2" v-if="!subscribeSucess">
      <v-col>
        <v-text-field
          label="E-Mail"
          outlined
          color="accent"
          single-line
          v-model="subscribe.email"
          dense
          v-on:keyup.enter="checkEmail"
          ref="emailField"
          :rules="emailRules"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-dialog
          v-model="subscribeDialog"
          max-width="350px"
          transition="dialog-transition"
          :overlay-opacity="0.9"
          overlay-color="#000000"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="accent" @click="checkEmail">
              <v-icon left>mdi-heart</v-icon>Subscribe
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="font-weight-thin">Subscribe</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-4 pb-2"
              >Your E-Mail: {{ subscribe.email }}</v-card-text
            >
            <v-card-text
              v-for="topic in sitecontent.topicIdsAndNames"
              :key="topic.id"
              class="pt-2 pb-2"
            >
              <v-checkbox
                :label="topic.name"
                v-model="subscribe.topics[topic.id]"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions align="right">
              <v-spacer></v-spacer>
              <v-btn @click="subscribeDialog = false">Cancel</v-btn>
              <v-btn @click="submitSubscribe">Subscribe</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="ma-4">
        <div class="mb-0 glass3">
          ABOUT JUST BUZZ BASS
          <br />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-carousel height="300" cycle>
        <v-carousel-item
          v-for="item in sitecontent.filesImageFront"
          :key="item.id"
          :src="
            ac.apiBaseURL +
            'res/sitecontent/5ddd4ea2923f8aff807a5b67/' +
            item.id +
            item.ext
          "
        ></v-carousel-item>
      </v-carousel>
    </v-row>

    <v-row no-gutters>
      <v-col class="ma-4">
        <div class="font-weight-thin" style="white-space: pre-line">
          {{ sitecontent.frontTextTextArea }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";

const { mapState: mapStateSiteContent, mapActions: mapActionsSiteContent } =
  createNamespacedHelpers("sitecontent");

const { mapState: mapStateReleases, mapActions: mapActionsReleases } =
  createNamespacedHelpers("releases");

export default {
  mixins: [defaultMixin],
  created() {
    this.fetchSiteContent();
  },
  computed: {
    ...mapStateSiteContent(["sitecontent"]),
    ...mapStateReleases(["pubreleases"]),
  },
  methods: {
    ...mapActionsSiteContent(["fetchSiteContent"]),
    ...mapActionsReleases(["fetchPubReleases"]),
    clicked(value) {
      this.expanded = [];
      this.expanded.push(value);
    },
    checkEmail() {
      this.$refs.emailField.focus();
      if (this.$refs.emailField.validate()) {
        this.subscribeDialog = true;
      }
    },
    async submitSubscribe() {
      console.log("post subscription ");
      // post rating
      try {
        const url = "subscription";

        let topics = [];

        let isOneChecked = false;

        for (
          let index = 0;
          index < Object.keys(this.subscribe.topics).length;
          index++
        ) {
          const id = Object.keys(this.subscribe.topics)[index];
          let isSubscribed = this.subscribe.topics[id];
          isOneChecked = isSubscribed && !isOneChecked ? true : isOneChecked;
          topics.push({ id: id, isSubscribed: isSubscribed });
        }

        if (!isOneChecked) {
          this.setMessage({
            message: "Select at least on topic!",
            dialog: true,
            type: "error",
          });

          return;
        }

        const postdata = {
          apiCommand: "SUBSCRIBE",
          subscription: {
            email: this.subscribe.email,
            topics: topics,
          },
        };

        await axios.post(url, postdata);

        this.subscribeDialog = false;
        this.subscribeSucess = true;
        this.setMessage({
          message: "Thank you for subscribing!",
          dialog: true,
          type: "success",
        });
        this.subscribe.email = "";
        this.$refs.emailField.resetValidation();
      } catch (error) {
        this.subscribeDialog = false;
        console.log("subscription review " + error);
      }
    },
    getFileUrl(release) {
      if (release.filesImageArtWork) {
        let fileEntity = release.filesImageArtWork[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "releases" +
            "/" +
            release.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  data: () => ({
    subscribeDialog: false,
    subscribeSucess: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
    subscribe: {
      email: "",
      topics: {},
    },
    swiperOptions: {
      pagination: {
        el: ".swiper-pagination",
      },
      initialSlide: 2,
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 3,
      coverflowEffect: {
        rotate: 10,
        stretch: 50,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      autoplay: {
        delay: 4500,
        disableOnInteraction: true,
      },

      loopedSlides: 1,
      // ...
    },
  }),
  name: "home",
};
</script>
