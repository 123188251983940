<template>
  <v-container class="pa-0">
    <v-form class="ma-4">
      <v-row no-gutters>
        <v-col>
          <div class="glass2 mb-4">
            SIGN IN
            <br />
          </div>

          <v-text-field label="User email" v-model="username" autofocus outlined color="accent"></v-text-field>
          <v-text-field
            autocomplete
            v-model="password"
            label="Password"
            outlined
            color="accent"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            value="wqfasds"
            @click:append="show = !show"
            v-on:keyup.enter="signInAction"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col>
          <div class="font-weight-light body-2 mb-2">
            <router-link :to="{name:'signup'}">Create new account</router-link>
            <br />
            <router-link :to="{name:'resetpw'}">Forgot password</router-link>
          </div>
        </v-col>
        <v-col align="right">
          <v-btn @click="signInAction">
            Sign in
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";

export default {
  name: "SignIn",
  mixins: [defaultMixin],
  data() {
    return {
      show: false,
      password: "",
      username: "",
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match"
      }
    };
  },
  computed: {},
  methods: {
    async signInAction() {
      const success = await this.signIn({ u: this.username, p: this.password });
      // eslint-disable-next-line
      console.log("signInAction : " + success);
      if (success) {
        if (this.redirectAfterLogin !== null) {
          const temp = this.redirectAfterLogin;
          this.$store.commit("user/SET_REDIRECT_AFTER_LOGIN", null);
          this.$router.push(temp);
        } else {
          if (this.isAdmin) {
            this.$router.push({ name: "admin" });
          } else if (this.isEditor) {
            this.$router.push({ name: "admin" });
          } else if (this.isMember) {
            this.$router.push({ name: "profile" });
          }
        }
      }
    }
  }
};
</script>