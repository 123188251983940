<template>
  <v-container>
    <v-row no-gutters class="mb-2">
      <v-col class="pl-0 flex-grow-0 flex-shrink-1 d-flex">
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col class="glass2 pl-0">Manage Demos</v-col>
    </v-row>
    <v-autocomplete
      :items="getVipUser"
      v-model="selectedUserForToken"
      label="Select User"
      :item-text="getFieldText"
      item-value="id"
      @change="execLoadPermission"
    ></v-autocomplete>

    <v-data-table
      ref="demoList"
      :items="demoPermissions"
      :headers="headers"
      class="transparent-bg"
      dense
      must-sort
      :sort-by.sync="tableStateDemoPerm.sortBy"
      :sort-desc.sync="tableStateDemoPerm.sortDesc"
      :page.sync="tableStateDemoPerm.currentPage"
      :items-per-page.sync="tableStateDemoPerm.itemsPerPage"
      :footer-props="{ 'items-per-page-options':tableStateDemoPerm.itemsPerPageOptions }"
      :mobile-breakpoint="200"
      style="cursor:pointer"
      @click:row="selectedUserForToken = $event.userId; execLoadPermission()"
      v-model="selectedPermission"
      single-select
    >
      <template
        v-slot:item.lastAccess="{ item }"
      >{{item.lastAccess ? moment(item.lastAccess).locale('de').format('LLL') :'--'}}</template>
    </v-data-table>
    Permissions
    <v-data-table
      ref="demoList"
      :items="demos"
      :search="search"
      :headers="headersTrack"
      class="transparent-bg"
      dense
      must-sort
      :sort-by.sync="myTableState.sortBy"
      :sort-desc.sync="myTableState.sortDesc"
      :page.sync="myTableState.currentPage"
      :items-per-page.sync="myTableState.itemsPerPage"
      :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
      :mobile-breakpoint="200"
      style="cursor:pointer"
      v-if="selectedUserForToken"
    >
      <template v-slot:item.action="{ headers, item }">
        <v-layout row wrap>
          <v-btn @click="addShowPermission(item.id)" icon>
            <v-icon
              v-if="demoPermission.demoTrackProps.find(pt => pt.trackId == item.id && pt.show)"
            >mdi-check-box-outline</v-icon>
            <v-icon
              v-if="!demoPermission.demoTrackProps.find(pt => pt.trackId == item.id && pt.show)"
            >mdi-checkbox-blank-outline</v-icon>
          </v-btn>
          <v-btn @click="addDownloadPermission(item.id)" icon>
            <v-icon
              v-if="demoPermission.demoTrackProps.find(pt => pt.trackId == item.id && pt.downloadable)"
            >mdi-check-box-outline</v-icon>
            <v-icon
              v-if="!demoPermission.demoTrackProps.find(pt => pt.trackId == item.id && pt.downloadable)"
            >mdi-checkbox-blank-outline</v-icon>
          </v-btn>
        </v-layout>
      </template>

      <template v-slot:item.iaction="{ headers, item }">
        <v-icon v-if="getInteraction(item.id)" color="success">mdi-check</v-icon>
      </template>

      <template
        v-slot:item.catNo="{ item }"
      >{{item.catNo ? $vuetify.breakpoint.smAndDown ? item.catNo.slice(-3) : item.catNo : "-"}}</template>
      <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
    </v-data-table>Logs
    <v-data-table
      :headers="headersLogs"
      :items="logItems"
      item-key="id"
      class="transparent-bg"
      must-sort
      :sort-by.sync="tableStateLogs.sortBy"
      :sort-desc.sync="tableStateLogs.sortDesc"
      :page.sync="tableStateLogs.currentPage"
      :items-per-page.sync="tableStateLogs.itemsPerPage"
      :footer-props="{ 'items-per-page-options':tableStateLogs.itemsPerPageOptions }"
      :mobile-breakpoint="200"
      v-if="selectedUserForToken"
    ></v-data-table>

    <v-btn @click="copyMail()" class="ma-1" v-if="demoPermission.trackingToken">
      Copy Email
      <v-icon right>mdi-content-save</v-icon>
    </v-btn>
    <v-btn @click="generateDemoLink()" class="ma-1" v-if="demoPermission.trackingToken">
      Copy Demo Link
      <v-icon right>mdi-content-save</v-icon>
    </v-btn>
    <v-btn @click="execSavePermission()" class="ma-1">
      Save
      <v-icon right>mdi-content-save</v-icon>
    </v-btn>

    <!-- <div>selectedUserForToken : {{selectedUserForToken}}</div> -->
    <!-- <pre>demoPermission : {{demoPermission}}</pre>
    <pre>demoPermissions : {{demoPermissions}}</pre>
    <pre>{{demos}}</pre>-->
  </v-container>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";

const {
  mapState: mapStateDemos,
  mapActions: mapActionsDemos
} = createNamespacedHelpers("demos");

const {
  mapState: mapStateUsers,
  mapActions: mapActionsUsers,
  mapGetters: mapGettersUsers
} = createNamespacedHelpers("users");

export default {
  name: "ManageDemos",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "dateCreated", value: "dateCreated" },
      { text: "name", value: "name" },
      { text: "lastAccess", value: "lastAccess" }
    ],
    headersTrack: [
      { text: "Show/Dl", value: "action", sortable: false, width: "80px" },
      { text: "Name", value: "name" },
      { text: "ISRC", value: "ISRC" },
      { text: "Resp", value: "iaction", sortable: false }
    ],
    headersLogs: [
      { text: "action", value: "action" },
      { text: "date", value: "date" },
      { text: "resourceName", value: "resourceName" },
      { text: "playTime", value: "playTime" },
      { text: "reomoteHost", value: "reomoteHost" }
    ],
    search: "",
    dialog: false,
    myTableState: {},
    tableStateDemoPerm: {},
    tableStateLogs: {},
    selectedUserForToken: null,
    logItems: [],
    selectedPermission: []
  }),
  async created() {
    this.tableStateDemoPerm = this.getTableState("tableDemoPerm");
    if (!this.tableStateDemoPerm.sortBy) {
      this.tableStateDemoPerm.sortBy = "lastAccess";
      this.tableStateDemoPerm.sortDesc = true;
    }
    this.tableStateLogs = this.getTableState("tableLogs");
    if (!this.tableStateLogs.sortBy) {
      this.tableStateLogs.sortBy = "date";
      this.tableStateLogs.sortDesc = true;
      this.tableStateLogs.itemsPerPage = 8
    }
    this.myTableState = this.getTableState("demoTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "catNo";
      this.myTableState.sortDesc = true;
      this.myTableState.itemsPerPage = 8
    }
    this.fetchDemos();
    await this.fetchUsers();
    await this.loadAllPermissions();
  },
  computed: {
    ...mapStateDemos(["demos", "demoPermission", "demoPermissions"]),
    ...mapStateUsers(["users"]),
    ...mapGettersUsers(["getVipUser"]),
    debugSelectedDownload: {
      get: function() {
        return false;
      }
    }
  },
  methods: {
    ...mapActionsDemos([
      "fetchDemos",
      "loadPermission",
      "savePermission",
      "loadAllPermissions"
    ]),
    ...mapActionsUsers(["fetchUsers"]),
    async fetchLogItems(id) {
      const url = "userlogs";
      const postdata = {
        id: id
      };
      const response = await axios.post(url, postdata);

      if (response.data.status[0] === "OK") {
        this.logItems = response.data.logItems;
      }
    },
    getFieldText(item) {
      return item.artistsName + " " + item.email;
    },
    testUpdate() {
      console.log("test update");
      this.$refs["60017667befa1d27c871e4a4show"].$mount();
    },
    getInteraction(id) {
      const dp = this.demoPermission.demoTrackProps.find(
        pt => pt.trackId == id
      );

      return dp && dp.interested ? true : false;
    },
    copyMail() {
      this.ac.copyToClipboard(this.demoPermission.name);
    },
    generateDemoLink() {
      // eslint-disable-next-line no-undef
      let url =
        process.env.NODE_ENV === "development"
          ? "https://dev.buzzbass.com:8080/"
          : "https://buzzbass.com/";
      url += "demos/?a=" + this.demoPermission.trackingToken;
      console.log("generateDemoLink : " + url);

      this.ac.copyToClipboard(url);
    },
    async execLoadPermission() {
      await this.fetchLogItems(this.selectedUserForToken);
      await this.loadPermission({ id: this.selectedUserForToken });
      //console.log(JSON.stringify(this.demoPermission));
      this.selectedPermission = [this.demoPermission];
    },
    async execSavePermission() {
      let args = {};
      args.id = this.selectedUserForToken;
      args.demoPermission = this.demoPermission;

      await this.savePermission(args);
      await this.loadAllPermissions();
    },
    addShowPermission(v) {
      let entry = this.demoPermission.demoTrackProps.find(
        pt => pt.trackId == v
      );

      console.log("addShowPermission" + v + " " + entry);
      if (!entry) {
        this.demoPermission.demoTrackProps.push({
          trackId: v,
          show: true,
          downloadable: false
        });
      } else {
        entry.show = !entry.show;
        if (!entry.show && entry.downloadable) {
          entry.downloadable = false;
        }
      }
    },
    addDownloadPermission(v) {
      let entry = this.demoPermission.demoTrackProps.find(
        pt => pt.trackId == v
      );

      console.log("addDownloadPermission" + v + " " + entry);

      if (!entry) {
        this.demoPermission.demoTrackProps.push({
          trackId: v,
          downloadable: true,
          show: true
        });
      } else {
        entry.downloadable = !entry.downloadable;
        if (!entry.show && entry.downloadable) {
          entry.show = true;
        }
      }
    },
    getFileUrl(release) {
      if (release.filesImageArtWork) {
        let fileEntity = release.filesImageArtWork[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "releases" +
            "/" +
            release.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getCardClass() {
      console.log("get class" + this.$vuetify.breakpoint.smAndUp);

      if (this.$vuetify.breakpoint.smAndUp) {
        return "transparent-bg pa-4 pt-0";
      } else {
        return "transparent-bg pa-0 ";
      }
    },
    stopOthers(id) {
      console.log("stop except : " + id);
      let thisId = id;

      this.demos.forEach(r => {
        r.tracks.forEach(track => {
          if (track.id !== thisId) {
            this.$refs["audio" + track.id][0].stop();
          }
        });
      });
    }
  }
};
</script>