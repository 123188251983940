<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{ path: $store.state.route.from.fullPath }" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right" class="font-weight-thin display-1  mb-1">

          Site Content
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="sitecontent"
      schemaName="SiteContent"
      :formData="sitecontentData"
      namespace="sitecontent"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row justify="end" class="pa-2">
            <v-btn class="ma-1" @click="ac.copyToClipboard(getLinkTree())">
        Links
        <v-icon right>mdi-content-copy</v-icon>
      </v-btn>
        <v-btn :to="{name:'admin'}" class="ma-1">
          Cancel
          <v-icon right>mdi-close-circle</v-icon>
        </v-btn>
        <v-btn @click="saveSiteContent()" class="ma-1">
          Save
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapActions: mapActionsSiteContent,
  mapGetters: mapGettersSiteContent
} = createNamespacedHelpers("sitecontent");

const {
  mapState: mapStateMailingLists,
  mapActions: mapActionsMailingLists
} = createNamespacedHelpers("mailinglists");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmSiteContent",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    sitecontentData: {},
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaSiteContent: SchemasConst.SiteContent,
    refObjects: {},
  }),
  async created() {
    await this.fetchMailingLists({force:false,mailingId:null});
    await this.fetchSiteContent();
    this.refObjects["refMailingList"] = this.mailinglists;
    this.sitecontentData = this.getSiteContent;
    console.log("Adm SiteContent created");
  },
  computed: {
    ...mapGettersSiteContent(["getSiteContent"]),
    ...mapStateMailingLists(["mailinglists"]),
  },
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsSiteContent(["fetchSiteContent", "updateSiteContent"]),
    ...mapActionsMailingLists(["fetchMailingLists"]),
    getvModel(name) {
      return this.sitecontentData[name];
    },
    getLinkTree() {
      console.log("linkTree");
      const keys = Object.keys(this.sitecontentData).filter(
        n => n.includes("link") === true
      );
      console.log("schema includes" + keys);
      const ref = this;
      const links = keys.map(n => {
        return ref.ac.buildLink(n, this.sitecontentData[n]);
      });

      // const jbbLink =
      //   "https://buzzbass.com/release/" +
      //   this.normalizeUrl(this.releaseData.name) +
      //   "/" +
      //   this.releaseData.id;

      // links.unshift(jbbLink);

      return links.join("\n");
    },
    uploadFiles() {
      this.schemaSiteContent.forEach(field => {
        if (field.itemType === "files") {
          if (this.sitecontentData[field.name]) {
            this.sitecontentData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "sitecontent/" + this.sitecontentData.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveSiteContent() {
      console.log("saveSiteContent");

      const success = await this.updateSiteContent({
        sitecontent: this.sitecontentData,
        cmd: "UPDATE"
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admin" });
      }
    }
  }
};
</script>