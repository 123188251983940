<template>
  <v-container class>
    <div v-if="!activationSent">
      <v-row no-gutters>
        <v-col>
          <div class="glass2 mb-1">SIGN UP</div>
          <div class="mb-4 ml-1 body-2 font-weight-light">
            In order to submit demos or get access to dubs you need to sign up with Just Buzz Bass Recordings.
            <br />
            <router-link :to="{name:'signin'}">I already have an account</router-link>
          </div>
          <v-row dense>
            <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown? 12:6">
              <v-text-field
                label="Artist Name"
                v-model="artistName"
                autofocus
                outlined
                color="accent"
                ref="artistNameField"
                autocomplete="off"
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown? 12:6">
              <v-text-field
                label="Social media link"
                v-model="socialMediaLink"
                ref="socialMediaLinkField"
                autofocus
                outlined
                color="accent"
                autocomplete="off"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            label="E-Mail address"
            v-model="userEmail"
            autofocus
            outlined
            :emailRules="emailRules"
            color="accent"
            autocomplete="off"
            ref="userEmailField"
            dense
          ></v-text-field>
          <v-row dense>
            <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown? 12:6">
              <v-text-field
                autocomplete="new-password"
                v-model="password"
                label="Password"
                outlined
                color="accent"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                ref="passwordField"
                hint
                @click:append="show = !show"
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown? 12:6">
              <v-text-field
                autocomplete="new-password"
                v-model="passwordRepeat"
                label="Repeat password"
                outlined
                color="accent"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                @click:append="show = !show"
                ref="passwordRepeatField"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn @click="signUpAction">
            Sign Up
            <v-icon right>mdi-send-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div class="mb-4 ml-1 body-2 font-weight-light">
        An Activation Mail has been sent to {{userEmail}}
        <br />
        <v-btn @click="activationSent=false">Try again</v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";

export default {
  name: "SignUp",
  mixins: [defaultMixin],
  data() {
    return {
      show: false,
      userEmail: "",
      artistName: "",
      socialMediaLink: "",
      password: "",
      passwordRepeat: "",
      activationSent: false,
      timeOutClear: null,
      emailRules: [ v => /.+@.+/.test(v) || 'Invalid Email address' ],
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match",
        
      }
    };
  },
  async created() {
      if(this.$route.params.cc)
      {
          // try activate
            const success = await this.activateAccount({cc:this.$route.params.cc});
            if(success)
            {
             this.setMessage({
              message: "Your Account has been activated",
              dialog: true,
              type: "success",
              timeout: 4000
            });
                this.$router.push({ name: "profile" });
            }

      }
  },
  computed: {},
  methods: {
    async signUpAction() {
      const response = await this.signUp({
        e: this.userEmail,
        p: this.password,
        pr: this.passwordRepeat,
        an: this.artistName,
        sl: this.socialMediaLink
      });

      if (response.status.includes("ACTIVATION_MAIL_SENT")) {
        this.activationSent = true;
      } else {
        this.activationSent = false;
      }

      if (response.status.includes("INVALID_EMAIL")) {
        this.$refs.userEmailField.errorMessages.push("Invalid Email");
      }

      if (response.status.includes("ACCOUNT_EXISTS")) {
        this.$refs.userEmailField.errorMessages.push("Account already exists");
      }

      if (response.status.includes("PASSWORD_TO_SHORT")) {
        this.$refs.passwordField.errorMessages.push("Password to short");
      }

      if (response.status.includes("PASSWORD_NOT_MATCH")) {
        this.$refs.passwordRepeatField.errorMessages.push(
          "Passwords are not equal"
        );
      }

      if (this.$refs.artistNameField.value === "") {
        this.$refs.artistNameField.errorMessages.push("Required");
      }

      clearTimeout(this.timeOutClear);
      if (!this.activationSent) {
        this.timeOutClear = setTimeout(
          function name() {
            while (this.$refs.userEmailField.errorMessages.length > 0) {
              this.$refs.userEmailField.errorMessages.pop();
            }
            while (this.$refs.passwordField.errorMessages.length > 0) {
              this.$refs.passwordField.errorMessages.pop();
            }
            while (this.$refs.passwordRepeatField.errorMessages.length > 0) {
              this.$refs.passwordRepeatField.errorMessages.pop();
            }
            while (this.$refs.artistNameField.errorMessages.length > 0) {
              this.$refs.artistNameField.errorMessages.pop();
            }
          }.bind(this),
          1500
        );
      }
    }
  }
};
</script>