import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_SETTINGS_PENDING = 'FETCH_SETTINGS_PENDING';
const FETCH_SETTINGS_FULFILLED = 'FETCH_SETTINGS_FULFILLED';
const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR';

const UPDATE_SETTINGS_PENDING = 'UPDATE_SETTINGS_PENDING';
const UPDATE_SETTINGS_FULFILLED = 'UPDATE_SETTINGS_FULFILLED';
const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR';


const state = {

    fetchSettingsPending: false,
    updateSettingsPending: false,
    settings: {}
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_SETTINGS_PENDING](state) { state.fetchSettingsPending = true; },
    [FETCH_SETTINGS_FULFILLED](state, settings) {
        state.settings = settings;
        state.fetchSettingsPending = false;
    },
    [FETCH_SETTINGS_ERROR](state) { state.fetchSettingsPending = false; },

    [UPDATE_SETTINGS_PENDING](state) { state.updateSettingsPending = true; },
    [UPDATE_SETTINGS_FULFILLED](state) { state.updateSettingsPending = false; },
    [UPDATE_SETTINGS_ERROR](state) { state.updateSettingsPending = false; },

};
const actions = {
    async fetchSettings({  dispatch,commit }) {
        commit(FETCH_SETTINGS_PENDING);
        console.log("FETCH_SETTINGS");
        try {
            const url = 'settings';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_SETTINGS_FULFILLED, response.data.settings);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
    async updateSettings({ commit, dispatch }, args) {
        commit(UPDATE_SETTINGS_PENDING);

        console.log("UPDATE_SETTINGS " );
        try {
            const url = 'settings';

            const postdata = {
                apiCommand: args.cmd,
                settings: args.settings
            }

            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));

            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
};

const getters = {
    getSettings: (state) => {
        return state.settings
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};