import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_TOKENS_PENDING = 'FETCH_TOKENS_PENDING';
const FETCH_TOKENS_FULFILLED = 'FETCH_TOKENS_FULFILLED';
const FETCH_TOKENS_ERROR = 'FETCH_TOKENS_ERROR';

const UPDATE_TOKEN_PENDING = 'UPDATE_TOKEN_PENDING';
const UPDATE_TOKEN_FULFILLED = 'UPDATE_TOKEN_FULFILLED';
const UPDATE_TOKEN_ERROR = 'UPDATE_TOKEN_ERROR';



const state = {

    fetchTokensPending: false,
    updateTokenPending: false,
    tokens: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_TOKENS_PENDING](state) { state.fetchTokensPending = true; },
    [FETCH_TOKENS_FULFILLED](state, tokens) {
        state.tokens = tokens;
        state.fetchTokensPending = false;
    },
    [FETCH_TOKENS_ERROR](state) { state.fetchTokensPending = false; },

    [UPDATE_TOKEN_PENDING](state) { state.updateTokenPending = true; },
    [UPDATE_TOKEN_FULFILLED](state) { state.updateTokenPending = false; },
    [UPDATE_TOKEN_ERROR](state) { state.updateTokenPending = false; },

};
const actions = {
    async updateToken({ commit, dispatch }, args) {
        commit(UPDATE_TOKEN_PENDING);
        try {
            const url = 'tokens';

            const postdata = {
                apiCommand: args.cmd,
                token: args.token
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('token/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchTokens({ commit, dispatch }, userId) {
        commit(FETCH_TOKENS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_TOKENS");
        try {
            const url = 'tokens';

            const postdata = {
                apiCommand: 'GET_TOKENS',
                user:{
                    id: userId
                }
            }

            const response = await axios.post(url,postdata);

            if (response.data.status[0] === "OK") {
                commit(FETCH_TOKENS_FULFILLED, response.data.tokens);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getTokenById: (state) => (id) => {
        return state.tokens.find(tokens => tokens.id === id)
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};