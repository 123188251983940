import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_MAILINGLISTS_PENDING = 'FETCH_MAILINGLISTS_PENDING';
const FETCH_MAILINGLISTS_FULFILLED = 'FETCH_MAILINGLISTS_FULFILLED';
const FETCH_MAILINGLISTS_ERROR = 'FETCH_MAILINGLISTS_ERROR';

const UPDATE_MAILINGLIST_PENDING = 'UPDATE_MAILINGLIST_PENDING';
const UPDATE_MAILINGLIST_FULFILLED = 'UPDATE_MAILINGLIST_FULFILLED';
const UPDATE_MAILINGLIST_ERROR = 'UPDATE_MAILINGLIST_ERROR';



const state = {

    fetchMailingListsPending: false,
    updateMailingListPending: false,
    mailinglists: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_MAILINGLISTS_PENDING](state) { state.fetchMailingListsPending = true; },
    [FETCH_MAILINGLISTS_FULFILLED](state, mailinglists) {
        state.mailinglists = mailinglists;
        state.fetchMailingListsPending = false;
    },
    [FETCH_MAILINGLISTS_ERROR](state) { state.fetchMailingListsPending = false; },

    [UPDATE_MAILINGLIST_PENDING](state) { state.updateMailingListPending = true; },
    [UPDATE_MAILINGLIST_FULFILLED](state) { state.updateMailingListPending = false; },
    [UPDATE_MAILINGLIST_ERROR](state) { state.updateMailingListPending = false; },

};
const actions = {
    async updateMailingList({ commit, dispatch }, args) {
        commit(UPDATE_MAILINGLIST_PENDING);
        try {
            
            console.log('save mailinglist : ' + args.mailinglist.id)

            const url = 'mailinglists';

            const postdata = {
                apiCommand: args.cmd,
                mailinglist: args.mailinglist
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));
            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('mailinglist/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchMailingLists({ commit, dispatch },args) {

        if(!args.force && state.mailinglists.length >  0 )
        {
            console.log("SKIP FETCH_MAILINGLISTS force " + args.force);
            return true;
        }

        commit(FETCH_MAILINGLISTS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_MAILINGLISTS " + args.force + " mailingId " + args.mailingId);
        try {
            const url = 'mailinglists';

            const postdata = {
                apiCommand: 'FETCH_MAILING_LISTS',
                mailing:{
                    id:args.mailingId
                }
            }

            const response = await axios.post(url,postdata);

            if (response.data.status[0] === "OK") {
                commit(FETCH_MAILINGLISTS_FULFILLED, response.data.mailinglists);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getMailingListById: (state) => (id) => {
        return state.mailinglists.find(mailinglists => mailinglists.id === id)
    }
}


export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};