import { render, staticRenderFns } from "./DynamicFormComponent.vue?vue&type=template&id=a5b8112e&"
import script from "./DynamicFormComponent.vue?vue&type=script&lang=js&"
export * from "./DynamicFormComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports