<template>
  <v-container class="pa-0">
    <v-data-iterator
      :items="events"
      :items-per-page="ipp"
      hide-default-footer
      :page="myTableState.currentPage"
    >
      <template v-slot:footer>
        <v-pagination
          class="mt-2"
          v-model="myTableState.currentPage"
          :length="Math.ceil(events.length / ipp)"
        ></v-pagination>
      </template>
      <template v-slot:default="props">
        
        <v-row dense class="mt-2 ml-2 mr-2">
          

          <v-col
            v-for="ev in props.items"
            :key="ev.id"
            class="d-flex child-flex"
            :cols="$vuetify.breakpoint.smAndUp ? 4 : 6"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card flat tile class="d-flex transparent-bg" style="cursor:pointer">
                <v-img
                  v-if="!ev.image == ''"
                  :src="getFileUrl(ev)"
                  aspect-ratio="1"
                  @click="dialog = true; devent = ev"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      v-if="!ev.image == ''"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out accent v-card--reveal title white--text fill-height pa-3"
                      style="height: 100%;"
                    >{{ev.name}}</div>
                  </v-expand-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>

        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog
      v-model="dialog"
      :ev="devent"
      :scrollable="true"
      :overlay-opacity="80"
      overlay-color="#000000"
    >
      <v-img :src="getFileUrl(devent)" contain @click="dialog=false" style="cursor:pointer"></v-img>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateEvents,
  mapActions: mapActionsEvents
} = createNamespacedHelpers("events");

export default {
  name: "Events",
  mixins: [defaultMixin],
  data: () => ({
    dialog: false,
    devent: {},
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("eventsTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "date";
      this.myTableState.sortDesc = true;
      this.myTableState.currentPage = 1;
      this.myTableState.itemsPerPage = 6;
    }
    this.fetchEvents();
  },
  computed: {
    ...mapStateEvents(["events"]),
    ipp: {
      get: function() {
        return this.$vuetify.breakpoint.smAndUp ? 12 : 6;
      }
    }
  },
  methods: {
    ...mapActionsEvents(["fetchEvents"]),
    getFileUrl(event) {
      if (event.filesImageEvent) {
        let fileEntity = event.filesImageEvent[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "events" +
            "/" +
            event.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }
};
</script>
