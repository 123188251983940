import Vue from 'vue'
import Vuex from 'vuex'

import user from './user';
import events from './events';
import releases from './releases';
import demos from './demos';
import users from './users';
import tracks from './tracks';
import mixtapes from './mixtapes';
import artists from './artists';
import mailings from './mailings';
import mailinglists from './mailinglists';
import emailproviders from './emailproviders';
import settings from './settings';
import stats from './stats';
import submissions from './submissions';
import tokens from './tokens';
import sitecontent from './sitecontent';
import release from './release';
import shop from './shop';
import dialog from './dialog';
import global from './global';


Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    user,
    events,
    releases,
    demos,
    users,
    tracks,
    mixtapes,
    artists,
    mailings,
    mailinglists,
    emailproviders,
    settings,
    stats,
    submissions,
    tokens,
    sitecontent,
    release,
    shop,
    dialog,
    global 
  },
});
