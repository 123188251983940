
const ADD_MESSAGE = 'ADD_MESSAGE';
const SET_MESSAGE = 'SET_MESSAGE';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

const state = {
    messages:[],
};


const mutations = {
    [SET_MESSAGE](state, args) {
        if(!args.add)
        {
            state.messages = [];
        }

        const message = {
            id: Math.random(),
            message: args.message,
            type: args.type,
            show:true,
            timeout: args.timeout ? args.timeout : 2000,
        }

        state.messages.push(message);
        if(message.timeout > 0)
        {
            setTimeout(function(id){
                state.messages = state.messages.filter(i => i.id !== id);
            }.bind(this,message.id),message.timeout);
        }
    },
    [ADD_MESSAGE](state, args) {
        state.messages.push({
            id: Math.random(),
            message: args.message,
            type: args.type,
            show:true,
            timeout: 0,
        })
    },
    [REMOVE_MESSAGE](state, id) {
        state.messages = state.messages.filter(i => i.id !== id);
    },
};


const actions = {
    setMessage({ commit }, args)
    {
        commit(SET_MESSAGE,args);
    },
    addMessage({ commit }, args)
    {
        commit(ADD_MESSAGE,args);
    },
    dismissMessage({ commit })
    {
        commit(ADD_MESSAGE,{message:'',dialog:false});
    }
    ,
    dismissMessage2({ commit },id)
    {
        commit(REMOVE_MESSAGE,id);
    }
}

export default {
    state,
    mutations,
    actions,
    namespaced: true,
};