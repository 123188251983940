<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" align="left">
        <v-btn :to="{name:'admin'}" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-btn @click="doCreateMailing">
          New Campaign
          <v-icon right>mdi-plus-thick</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="search"
          outlined
          color="accent"
          dark
          label="Search"
          single-line
          hide-details
          dense
          clearable
        >
          <template slot="append">
            <v-icon v-if="!search">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <v-data-table
          :items="mailings"
          :search="search"
          :headers="headers"
          class="transparent-bg"
          must-sort
          :sort-by.sync="myTableState.sortBy"
          :sort-desc.sync="myTableState.sortDesc"
          :page.sync="myTableState.currentPage"
          :items-per-page.sync="myTableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options':myTableState.itemsPerPageOptions }"
          :mobile-breakpoint="200"
          @click:row="rowClickDetail"
          style="cursor:pointer"
        >
          <template v-slot:item.action="{ item }" color="secondary">
            <v-btn text depressed icon @click.stop="gotoDetails(item)">
              <v-icon>mdi-email-send</v-icon>
            </v-btn>
            <v-btn text depressed icon class="ml-2" @click.stop="gotoStats(item)">
              <v-icon>mdi-finance</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.date="{ item }">{{new Date(item.date).toDateString()}}</template>
          <template v-slot:item.publish="{ item }">
            <v-icon v-if="item.publish" small color="accent">mdi-check</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: mapStateMailings,
  mapActions: mapActionsMailings
} = createNamespacedHelpers("mailings");

export default {
  name: "AdminMailings",
  mixins: [defaultMixin],
  data: () => ({
    headers: [
      { text: "Subject", value: "subject" },
      { text: "Created", value: "dateCreated" },
      { text: "", value: "action", sortable: false, align: "right" }
    ],
    search: "",
    dialog: false,
    myTableState: {}
  }),
  created() {
    this.myTableState = this.getTableState("mailingsTable");
    if (!this.myTableState.sortBy) {
      this.myTableState.sortBy = "dateCreated";
      this.myTableState.sortDesc = true;
    }
    this.fetchMailings();
  },
  computed: {
    ...mapStateMailings(["mailings"])
  },
  methods: {
    ...mapActionsMailings(["fetchMailings", "createMailing"]),
    gotoDetails(item) {
      this.$router.push({ name: "admsendmail", params: { id: item.id } });
    },
    gotoStats(item) {
      this.$router.push({ name: "admstatdetails", params: { id: item.id } });
    },
    rowClickDetail(e) {
      console.log("rowClickDetail " + e.id);
      this.$router.push({ path: "/admin/mailing/" + e.id });
    },
    async doCreateMailing() {
      const newMailingID = await this.getObjectId();
      console.log("newMailingID " + newMailingID);
      this.$router.push({ path: "/admin/mailing/" + newMailingID });
    }
  }
};
</script>