<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="flex-grow-0 flex-shrink-1">
        <v-btn :to="{ name:'releases' }" depressed>
          <v-icon left>mdi-arrow-left-circle</v-icon>More
        </v-btn>
      </v-col>
      <v-col class="glass2 pl-2 flex-grow-1 flex-shrink-0" align="right">{{release.name}}</v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="5">
        <v-card outlined class="transparent-bg">
          <v-card>
            <v-img
              :src="getFileUrlFromList(release.filesImageArtWork)"
              @click="imagezoom = true; imgsrc = getFileUrlFromList(release.filesImageArtWork)"
              style="cursor:pointer"
            />
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7">
        <v-row no-gutters>
          <v-col
            class="font-weight-thin"
            style="white-space: pre-line;"
          >{{release.releaseTextTextArea}}</v-col>
        </v-row>
        <v-row no-gutters v-for="track in release.tracks" :key="track.id" class="mb-2">
          <v-col class="pa-0">
            <v-card :class="getCardClass()">
              <v-card-text class="pa-1 glass3">{{track.name}}</v-card-text>
              <v-card-text class="pa-1 pt-0 caption">Artists: {{track.artists}}</v-card-text>
              <v-card-text class="pa-1 pt-0 caption">Release Date: {{release.dateRelease}}</v-card-text>
              <v-card-text class="pa-1 pt-0 caption">Cat-No: {{release.catNo}}</v-card-text>
              <v-card-text class="pa-1 pt-0 caption" v-if="release.upcEan">UPC: {{release.upcEan}}</v-card-text>
              <v-card-text class="pa-1 pt-0 caption">ISRC: {{track.ISRC}}</v-card-text>
              <v-card-text
                class="pa-1 font-weight-thin"
                style="white-space: pre-line;"
              >{{track.descriptionTextArea}}</v-card-text>
              <v-card-text class="pa-1 mb-4">
                <AudioPlayer
                  :ref="'audio'+track.id"
                  :name="track.name"
                  :id="track.id"
                  :audioUrl="ac.apiBaseURL +'res/tracks/' + track.id +'/' +track.filesAudioPrewiew[0].id + track.filesAudioPrewiew[0].ext"
                  :waveFormUrl="ac.apiBaseURL +'res/tracks/' + track.id +'/' +track.filesAudioPrewiew[0].id + '.png'"
                  :waveFormUrl2="ac.apiBaseURL +'res/tracks/' + track.id +'/' +track.filesAudioPrewiew[0].id + '2.png'"
                  @play="stopOthers(track.id);playStats($event,track,false)"
                  @playing="playStats($event,track,true)"
                />
              </v-card-text>
              <v-card-actions class="pa-0">
                <v-row justify="end" class="pl-4 pr-4">
                  <v-dialog
                    v-model="reviewDialogs[track.id]"
                    light
                    :max-width="600"
                    name="feedback"
                    :overlay-opacity="0.9"
                    overlay-color="#222222"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="track.filesAudioFullDownloadProtected"
                        v-on="on"
                        :block="$vuetify.breakpoint.smAndDown"
                        :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                        color="#faa11b"
                        outlined
                        :small="$vuetify.breakpoint.smAndUp"
                        @click="clickStats('start-review')"
                      >
                        PROMO Download
                        <v-icon right>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-form :ref="'form'+track.id" v-model="formModels[track.id]">
                        <v-card-title
                          class="font-weight-thin"
                        >Please give us your thoughts on {{track.name}}</v-card-title>
                        <v-card-text>
                          <v-rating
                            :ref="'rating'+track.id"
                            v-model="ratings[track.id]"
                            class="mb-2"
                            label="Rating"
                          ></v-rating>
                          <v-select
                            v-for="q in questions"
                            :key="q.question"
                            color="accent"
                            :rules="[v => !!v || 'Item is required']"
                            outlined
                            :label="q.question"
                            v-model="q.answer[track.id]"
                            :items="q.answers"
                            light
                            :menu-props="{'light':true, offsetY: true}"
                          ></v-select>

                          <v-textarea
                            color="accent"
                            label="Review/Feedback"
                            outlined
                            v-model="feedback[track.id]"
                          ></v-textarea>

                          <v-row no-gutters>
                            <v-col align="right">
                              <v-btn class="mr-2" @click="reviewDialogs[track.id] = false">
                                Cancel
                                <v-icon right>mdi-close-outline</v-icon>
                              </v-btn>
                              <v-btn @click="checkAndDownload(track)">
                                Download
                                <v-icon right>mdi-download</v-icon>
                              </v-btn>
                              <!-- <v-btn class="ml-2" @click="checkAndDownload()">
                                Send to EMail
                                <v-icon right>mdi-send</v-icon>
                              </v-btn>-->
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-dialog>

                  <v-btn
                    v-if="track.purchaseEnabled && (prodShopEnabled) && !track.filesAudioFullDownloadProtected"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="orderAction({cmd:'ADD_TO_ORDER',productId:track.id});clickStats('addToCart')"
                  >
                    <v-icon left>mdi-cart</v-icon>BAG IT {{formatAmountCurrency(track.price,"GBP")}}
                  </v-btn>

                  <v-btn
                    target="hypeddit"
                    :href="'https://hypeddit.com'+release.hypEdditLink"
                    v-if="release.hypEdditLink && !track.filesAudioFullDownloadProtected"
                    color="#40de21"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('hypeddit')"
                  >
                    <v-icon left>mdi-cloud-download-outline</v-icon>FREE DOWNLOAD
                  </v-btn>
                  <v-btn
                    target="bandcamp"
                    :href="ac.buildLink('bandcamp',release.bandCampLink)"
                    v-if="release.bandCampLink && !track.filesAudioFullDownloadProtected"
                    color="#1da0c3"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('bandcamp')"
                  >
                    <v-icon left>mdi-bandcamp</v-icon>BAND CAMP
                  </v-btn>
                                    <v-btn
                    target="beat.port"
                    :href="'https://www.beatport.com/'+release.beatPortLink"
                    v-if="release.beatPortLink && !track.filesAudioFullDownloadProtected"
                    color="#01ff95"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('beat.port')"
                  >
                    <v-icon left>mdi-play</v-icon>BEATPORT
                  </v-btn>
                  <v-btn
                    target="soundcloud"
                    :href="ac.buildLink('soundcloud',release.soundCloudLink)"
                    v-if="release.soundCloudLink && !track.filesAudioFullDownloadProtected"
                    color="#dd3300"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('soundcloud')"
                  >
                    <v-icon left>mdi-soundcloud</v-icon>SOUNDCLOUD
                  </v-btn>
                  <v-btn
                    target="spotify"
                    :href="ac.buildLink('spotify',release.spotifyLink)"
                    v-if="release.spotifyLink && !track.filesAudioFullDownloadProtected"
                    color="#88b700"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('spotify')"
                  >
                    <v-icon left>mdi-spotify</v-icon>SPOTIFY
                  </v-btn>
                  <v-btn
                    target="itunes"
                    :href="ac.buildLink('apple',release.appleLink)"
                    v-if="release.appleLink && !track.filesAudioFullDownloadProtected"
                    color="#fa233b"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('itunes')"
                  >
                    <v-icon left>mdi-apple</v-icon>ITUNES
                  </v-btn>
                  <v-btn
                    target="amazon"
                    :href="ac.buildLink('amazon',release.amazonLink)"
                    v-if="release.amazonLink && !track.filesAudioFullDownloadProtected"
                    color="#2c68c8"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('amazon')"
                  >
                    <v-icon left>mdi-amazon</v-icon>AMAZON
                  </v-btn>
                  <v-btn
                    target="youtube"
                    :href="ac.buildLink('youtube',release.youtubeLink)"
                    v-if="release.youtubeLink && !track.filesAudioFullDownloadProtected"
                    color="#FF0000"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('youtube')"
                  >
                    <v-icon left>mdi-youtube</v-icon>YOUTUBE
                  </v-btn>
                  <v-btn
                    target="juno"
                    :href="ac.buildLink('juno',release.junoLink)"
                    v-if="release.junoLink && !track.filesAudioFullDownloadProtected"
                    color="#6f9840"
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-2': 'mb-2 mr-2'"
                    outlined
                    :small="$vuetify.breakpoint.smAndUp"
                    @click="clickStats('juno')"
                  >
                    <v-icon left>mdi-ghost</v-icon>Buy on Juno
                  </v-btn>
                </v-row>
              </v-card-actions>
              <v-card-text class="pa-1 pb-4 pt-4" v-if="getReviewsForTrack(track).length > 0">
                <v-row>
                  <v-col class="pt-1 pb-1">Reviews</v-col>
                </v-row>
                <v-divider color="gray"></v-divider>
                <div class="font-weight-thin">
                  <v-simple-table class="transparent-bg mt-2">
                    <template v-slot:default>
                      <tr v-for="r in getReviewsForTrack(track)" :key="r.id" style>
                        <td class="pl-0 pb-3" style="vertical-align:top">{{r.userEmail}}</td>
                        <td class="pb-3" style="vertical-align:top">{{r.reviewText}}</td>
                        <td class="pr-0 pb-3" style="vertical-align:top">
                          <v-rating
                            :value="r.rating"
                            x-small
                            dense
                            :hidden="r.rating === 0"
                            readonly
                            style="font-size:10px"
                          ></v-rating>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-iterator :items="moreReleases" :items-per-page="5" hide-default-footer no-data-text>
      <template v-slot:default="props">
        <div class="glass3 ma-4">More releases</div>
        <v-row dense class="pa-0">
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? 2 : 4"
            v-for="(release,idx) in props.items"
            :key="release.id"
          >
            <transition name="fade" appear>
              <v-card
                :style="'transition-delay: ' + ((idx+1)/8) +'s'"
                class="translucent-bg-1 mb-0"
                dense
                :to="{name:'release',params:{id:release.id,releasename:normalizeUrl(release.name)}}"
              >
                <v-img :src="getReleaseFileUrl(release)" aspect-ratio="1"></v-img>
              </v-card>
            </transition>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-overlay v-model="waitOverlay" z-index="1000" :opacity=".9">
      <v-row align="center" justify="center" class="fill-height">
        <v-col align="center" class="accent--text" cols="12">downloading, please wait ...</v-col>
        <v-col align="center" class="accent--text" cols="12">
          <v-progress-circular
            :value="progressData"
            :size="100"
            :width="5"
            color="accent"
          >{{progressData}}</v-progress-circular>
        </v-col>
      </v-row>
    </v-overlay>
    <v-dialog v-model="imagezoom" :scrollable="true" :overlay-opacity="80" overlay-color="#000000">
      <v-img :src="imgsrc" contain @click="imagezoom=false" style="cursor:pointer"></v-img>
    </v-dialog>
  </v-container>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";
import AudioPlayer from "@/components/AudioPlayer.vue";

const {
  mapState: mapStateRelease,
  mapActions: mapActionsRelease
} = createNamespacedHelpers("release");

const {
  mapState: mapStateReleases,
  mapActions: mapActionsReleases
} = createNamespacedHelpers("releases");


export default {
  name: "Release",
  mixins: [defaultMixin],
  components: {
    AudioPlayer
  },
  data: () => ({
    questions: [
      {
        question: "Would you play this track in your live set?",
        answers: ["Yes", "No", "Maybe"],
        answer: {}
      },
      {
        question: "Would you play this track on Radio/Live-Stream?",
        answers: ["Yes", "No", "Maybe"],
        answer: {}
      }
    ],
    feedback: {},
    ratings: {},
    valid: true,
    formModels: {},
    reviewDialogs: {},
    progressData: 0,
    waitOverlay: false,
    imagezoom: false,
    imgsrc: ""
  }),
  async created() {
    await this.fetchRelease({
      id: this.$route.params.id
    });
    let artists = this.release.tracks
      ? this.release.tracks.map(t => t.artists)[0]
      : "";
    document.title =
      artists + " / " + this.release.name + " " + this.ac.siteName + " RECORDS";
    //this.setVolume();
    this.fetchCart();
    this.fetchPubReleases();
  },
  computed: {
    ...mapStateRelease(["release"]),
    ...mapStateReleases(["pubreleases"]),
    moreReleases: {
      get: function() {
        //let aritistId = this.release.tracks.map( t => t.refArtist);
        //const returnValue = this.pubreleases.filter( r => r.id !== this.release.id);

        const returnValue = this.pubreleases.filter( r => r.id !== this.release.id)
          .map(a => ({ sort: Math.random(), value: a }))
          .sort((a, b) => a.sort - b.sort)
          .map(a => a.value);

        console.log("filter more ");
        return returnValue;
      }
    }
  },
  methods: {
    getCardClass() {
      console.log("get class" + this.$vuetify.breakpoint.smAndUp);

      if (this.$vuetify.breakpoint.smAndUp) {
        return "transparent-bg pa-4 pt-0";
      } else {
        return "transparent-bg pa-0 ";
      }
    },
    getReviewsForTrack(track) {
      return this.release.reviews.filter(r => r.resourceId === track.id).reverse();
    },
    ...mapActionsRelease(["fetchRelease"]),
    ...mapActionsReleases(["fetchPubReleases"]),
    getFileUrlFromItem(id, list) {
      if (list && list.length > 0) {
        return (
          this.ac.apiBaseURL +
          "res/" +
          "tracks" +
          "/" +
          id +
          "/" +
          list[0].id +
          list[0].ext
        );
      }
    },
    getReleaseFileUrl(release) {
      if (release.filesImageArtWork) {
        let fileEntity = release.filesImageArtWork[0];
        if (fileEntity) {
          return (
            this.ac.apiBaseURL +
            "res/" +
            "releases" +
            "/" +
            release.id +
            "/" +
            fileEntity.id +
            fileEntity.ext
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    setVolume() {
      this.release.tracks.forEach(element => {
        this.$refs["audio" + element.id][0].volume = 0.8;
      });
    },
    stopOthers(id) {
      console.log("stop except : " + id);
      let thisId = id;

      this.release.tracks.forEach(track => {
        if (track.id != thisId) {
          this.$refs["audio" + track.id][0].stop();
        }
      });
    },
    async clickStats(tag) {
      try {
        const url = "cs";

        const postdata = {
          track: {
            name: tag
          }
        };

        const response = await axios.post(url, postdata);

        if (response.data.status[0] === "OK") {
          console.log("OK post clickStats");
        }
      } catch (error) {
        console.log("error post clickStats " + error);
      }
    },
    async checkAndDownload(track) {
      if (this.$refs["form" + track.id][0].validate()) {
        console.log("post review " + track.name);
        // post rating
        try {
          const url = "review";

          let qanda = JSON.parse(JSON.stringify(this.questions));

          qanda.forEach(q => {
            q.answer = q.answer[track.id];
          });

          const postdata = {
            apiCommand: "SEND_REVIEW",
            review: {
              resourceId: track.id,
              resourceName: track.name,
              rating: this.ratings[track.id],
              reviewText: this.feedback[track.id],
              questionsAnswers: qanda
            }
          };

          const response = await axios.post(url, postdata);

          if (response.data.status[0] === "OK") {
            const trackUrl =
              "pres/tracks/" +
              track.id +
              "/" +
              track.filesAudioFullDownloadProtected[0].id +
              track.filesAudioFullDownloadProtected[0].ext;
            console.log(trackUrl);

            this.waitOverlay = true;
            this.progressData = 0;
            const response = await axios.get(trackUrl, {
              responseType: "blob",
              onDownloadProgress: function(progressEvent) {
                const totalLength = progressEvent.lengthComputable
                  ? progressEvent.total
                  : progressEvent.target.getResponseHeader(
                      "x-decompressed-content-length"
                    )
                  ? progressEvent.target.getResponseHeader(
                      "x-decompressed-content-length"
                    )
                  : progressEvent.target.getResponseHeader("content-length");

                if (totalLength !== null) {
                  this.progressData = Math.round(
                    (progressEvent.loaded * 100) / totalLength
                  );
                  //console.log("progressEvent.loaded " + track.name + " : " + progressData + " " +  progressEvent.loaded);
                }
              }.bind(this)
            });

            this.progressData = 100;
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              track.filesAudioFullDownloadProtected[0].name + ".zip"
            );
            document.body.appendChild(link);
            link.click();

            this.setMessage({
              message: "Thank you for downloading " + track.name,
              dialog: true,
              type: "success",
              timeout: 10000
            });
          }
          this.reviewDialogs[track.id] = false;
          this.waitOverlay = false;
        } catch (error) {
          console.log("post review " + error);
          this.setMessage({
            message: "Quota exceeded " + track.name,
            dialog: true,
            type: "error",
            timeout: 2000
          });
          this.reviewDialogs[track.id] = false;
          this.waitOverlay = false;
        }
      }
    },
    getFileUrlFromList(list) {
      if (list && list.length > 0) {
        return (
          this.ac.apiBaseURL +
          "res/" +
          "releases" +
          "/" +
          this.$route.params.id +
          "/" +
          list[0].id +
          list[0].ext
        );
      }
    }
  }
};
</script>