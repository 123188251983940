<template>
  <v-container class="pa-0">
    <v-form>
      <v-row>
        <v-col
          cols="12"
          v-for="sField in baseSchema"
          :key="sField.name"
          :sm="sField.type == 'TextArea' || sField.type == 'List' ? 12 : 6"
        >
          <div v-if="sField.type == 'String' ">
            <v-text-field
              v-model="formData[sField.name]"
              :label="deCamel(sField.name)"
              outlined
              dense
              color="accent"
              hide-details
              :readonly="sField.name == 'id'"
              :disabled="sField.name == 'id'"
            >
              <template v-slot:append >
                <div v-if="sField.name.match(/link/ig) && formData[sField.name]">
                <v-btn
                x-small
                :href="ac.buildLink(sField.name,formData[sField.name])"
                :target="sField.name"
                icon
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
                </div>
                </template>
            </v-text-field>
          </div>
          <div v-if="sField.type == 'TextArea'">
            <v-textarea
              v-model="formData[sField.name]"
              :label="deCamel(sField.name)"
              outlined
              dense
              color="accent"
              hide-details
              :readonly="sField.name == 'id'"
              :disabled="sField.name == 'id'"
            />
          </div>
          <div v-if="sField.type == 'int'">
            <v-text-field
              v-model="formData[sField.name]"
              :label="deCamel(sField.name)"
              outlined
              dense
              color="accent"
              hide-details
              :readonly="sField.name == 'id'"
              :disabled="sField.name == 'id'"
            />
          </div>
          <div v-if="sField.type == 'BigDecimal'">
            <v-text-field
              v-model="formData[sField.name]"
              :label="deCamel(sField.name)"
              outlined
              dense
              color="accent"
              hide-details
              :readonly="sField.name == 'id'"
              :disabled="sField.name == 'id'"
            />
          </div>
          <div v-if="sField.type == 'Color'">
            <v-dialog
              :ref="sField.name"
              v-model="dialogModels[sField.name]"
              :return-value="formData[sField.name]"              
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formData[sField.name]"
                  :label="deCamel(sField.name)"
                  append-icon="mdi-color-picker"
                  outlined
                  color="accent"
                  readonly
                  hide-details
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <div>{{formData[sField.name] = formData[sField.name] ? formData[sField.name]:'#222222'}}</div>
              <v-color-picker
                v-model="formData[sField.name]"
                :label="deCamel(sField.name)"
                outlined
                class="ma-0"
                mode="hexa"
                dense
                show-swatches
              ></v-color-picker>
              <v-btn text @click="dialogModels[sField.name] = false;">OK</v-btn>
            </v-dialog>
          </div>
          <div v-if="sField.type == 'boolean' && sField.name !== 'locked'">
            <v-switch
              v-model="formData[sField.name]"
              :label="deCamel(sField.name)"
              outlined
              class="ma-0"
              dense
              color="accent"
              hide-details
            />
          </div>
          <div v-if="sField.type == 'Enum'">
            <v-select
              :items="sField.options"
              :label="sField.name"
              v-model="formData[sField.name]"
              dense
              color="accent"
              hide-details
              :menu-props="{'dark':true}"
              outlined
            ></v-select>
          </div>
          <div v-if="sField.type == 'Date'">
            <v-dialog
              :ref="sField.name"
              v-model="dialogModels[sField.name]"
              :return-value="formData[sField.name]"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formData[sField.name]"
                  :label="deCamel(sField.name)"
                  append-icon="mdi-calendar"
                  outlined
                  color="accent"
                  readonly
                  hide-details
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="formData[sField.name]" scrollable>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogModels[sField.name] = false">Cancel</v-btn>
                <v-btn text @click="dialogModels[sField.name] = false">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
          <!-- AUDIO -->
          <div
            v-if="sField.type == 'List' && sField.itemType =='files' && sField.itemSubType =='Audio'"
          >
            {{deCamel(sField.name)}}
            <v-row>
              <v-col
                v-for="fileEntity in formData[sField.name]"
                :key="fileEntity.id"
                cols="12"
                xs="12"
                sm="12"
              >
                <v-row dense>
                  <v-col class="flex-grow-1 flex-shrink-0">
                    {{fileEntity.name}}
                      <AudioPlayer
                        :heightRatio="$vuetify.breakpoint.smAndDown ? .25 :0.16"
                        :ref="'audio'+fileEntity.id"
                        :name="'trackName'"
                        :id="fileEntity.id"
                        :audioUrl="getFileUrl(fileEntity)"
                        :waveFormUrl="getFileUrl(fileEntity).replace('.wav','.png').replace('.mp3','.png')"
                        :waveFormUrl2="getFileUrl(fileEntity).replace('.wav','2.png').replace('.mp3','2.png')"
                      />
                  </v-col>
                  <v-col class="pb-0 pb-0 flex-grow-0 flex-shrink-1 d-flex align-end">
                    <v-btn @click="removeFile(sField.name,fileEntity.id)" icon class="">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                v-if="formData[sField.name] === undefined || formData[sField.name].length === 0"
              >
                <v-btn @click="openFileInput(sField.name)">
                  Add Audio
                  <v-icon right>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-file-input
                  style="display:none"
                  :rules="rules"
                  v-model="fileRefs[sField.name]"
                  :id="'fileInput'+sField.name"
                  solo
                  @change="onFileChange(sField.name)"
                  accept="audio/mp3, audio/wav"
                  placeholder="Add Audio"
                  :clearable="false"
                  outlined
                  color="accent"
                  label="Cover"
                ></v-file-input>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="sField.type == 'List' && sField.itemType =='files' && sField.itemSubType =='Image'"
          >
            {{deCamel(sField.name)}}
            <v-row>
              <draggable
                v-model="formData[sField.name]"
                @start="drag=true"
                @end="drag=false"
                style="width:100%;display:flex"
                draggable=".item"
              >
                <v-col
                  v-for="fileEntity in formData[sField.name]"
                  :key="fileEntity.id"
                  cols="2"
                  class="item"
                >
                  <v-card>
                    <v-hover v-slot:default="{ hover }" :name="fileEntity.id">
                      <v-img :src="getFileUrl(fileEntity)" aspect-ratio="1">
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-btn @click="removeFile(sField.name,fileEntity.id)" icon v-if="hover">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </v-row>
                      </v-img>
                    </v-hover>
                  </v-card>
                </v-col>

                <v-col cols="2" slot="footer">
                  <v-card>
                    <v-img :src="require('@/assets/placeholder_image02.png')" aspect-ratio="1">
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-btn icon large @click="openFileInput(sField.name)">
                          <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </v-row>
                    </v-img>
                    <v-file-input
                      style="display:none"
                      :rules="rules"
                      v-model="fileRefs[sField.name]"
                      :id="'fileInput'+sField.name"
                      solo
                      @change="onFileChange(sField.name)"
                      accept="image/png, image/jpeg"
                      placeholder="Add Cover Image"
                      :clearable="false"
                      outlined
                      color="accent"
                      label="Cover"
                    ></v-file-input>
                  </v-card>
                </v-col>
              </draggable>
            </v-row>
          </div>
          <div v-if="sField.type == 'List' && sField.itemType =='objectId'">
            <v-dialog v-model="dialogModels[sField.name]" max-width="1200">
              <template v-slot:activator="{ on }">
                <div style="cursor:pointer">
                  <v-card class="transparent-bg" outlined>
                    <v-card-title v-on="on" >{{getRefData(sField.name,formData[sField.name]).length}} {{sField.itemSubType}}s in {{schemaName}} <v-icon class="ml-5">mdi-open-in-new</v-icon></v-card-title>
                    <v-card-text>
                      <v-simple-table
                      class="transparent-bg"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-for="hf in getHeader(sField.itemSubType)" :key="hf.value" >{{hf.text}}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <draggable v-model="formData[sField.name]" tag="tbody">
                            <tr v-for="item in getRefData(sField.name,formData[sField.name]).slice(0, 80)" :key="item.id" >
                              <td v-for="hf in getHeader(sField.itemSubType)" :key="hf.value" >{{ item[hf.value] }}</td>
                              <td >
                                <v-btn :to="{path:'/admin/'+sField.itemSubType+'/'+item.id}">
                                  {{sField.itemSubType}}
                                  {{item.id}}
                                  <v-icon>mdi-arrow-top-right-thick</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </draggable>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </div>
              </template>
              <v-card class="dialog-bg">
                <v-card-title class="headline">Select {{sField.itemSubType}}s</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="searchModels[sField.name]"
                    outlined
                    color="accent"
                    dark
                    label="Search"
                    single-line
                    hide-details
                    dense
                    clearable
                  >
                    <template slot="append">
                      <v-icon v-if="!searchModels[sField.name]">mdi-magnify</v-icon>
                    </template>
                  </v-text-field>
                  <v-data-table
                    :search="searchModels[sField.name]"
                    :headers="getHeader(sField.itemSubType)"
                    :items="refObjects[sField.name]"
                    :value="getSelection(sField.name)"
                    @input="setSelection(sField.name,$event)"
                    item-key="id"
                    :mobile-breakpoint="200"
                    class="transparent-bg"
                    show-select
                  ></v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="dialogModels[sField.name]=false">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
      <!-- <v-btn @click="fupdate">fupdate</v-btn> -->
    </v-form>
  </v-container>
</template>
<script>
import { SchemasConst } from "@/schemas";
import draggable from "vuedraggable";
import AudioPlayer from "@/components/AudioPlayer.vue";

export default {
  name: "DynFormComponent",
  components: {
    draggable,
    AudioPlayer
  },
  props: {
    formData: {
      type: Object
    },
    name: {
      type: String
    },
    schemaName: {
      type: String
    },
    namespace: {
      type: String
    },
    ac: {
      type: Object
    },
    getObjectId: {
      type: Function
    },
    extractFileExtension: {
      type: Function
    },
    refObjects: {
      type: Object
    }
  },
  data: () => ({
    drag: false,
    searchModels: {},
    dialogModels: {},
    selectionModels: {},
    fileRefs: {},
    refData: {},
    schemas: SchemasConst,
    baseSchema: [],
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    isUpdated: false
  }),
  async created() {
    // prepare data
    console.log("Created " + this.$options.name);
    this.baseSchema = this.schemas[this.schemaName];

    console.log("formData " + JSON.stringify(this.formData));
  },
  computed: {
    refDataCmp: {
      get: function() {
        return this.refData;
      }
    }
  },
  mounted: function() {
    //Vue.$set(this.refData,'fu',Math.random());
    console.log("mounted" + this.$options.name);
  },
  updated: function() {
    // if (!this.isUpdated) {
    //    this.isUpdated = true;
    //   setTimeout(function name(params) {
    //     this.fupdate();
    //   }.bind(this),100);
    //   console.log("updated");
    // }
  },
  methods: {
    deCamel(str) {
      const regex = /^([a-z]+)|([A-Z][a-z]+)/gm;
      const subst = `$1$2 `;

      // The substituted value will be contained in the result variable
      let result = str.replace(regex, subst);

      result =
        result.length > 1
          ? result.substring(0, 1).toUpperCase() +
            result.substring(1, result.length)
          : result;

      return result;
    },
    fupdate() {
      console.log("forceUpdate");
      this.$forceUpdate();
    },
    isInRefs(fieldName, id) {
      this.refObjects[fieldName].find(o => o.id === id);
    },
    setSelection(fieldName, ev) {
      console.log(
        "setSelection " + fieldName + " " + JSON.stringify(ev, null, 2)
      );
      this.formData[fieldName] = ev.map(item => item.id);
    },
    getSelection(fieldName) {
      let selection = [];
      if (this.formData[fieldName]) {
        this.formData[fieldName].forEach(id => {
          selection.push({ id: id, fieldName: fieldName });
        });
      }
      return selection;
    },
    getRefData(fieldName, ids) {
      let result = ids && this.refObjects[fieldName] ? this.refObjects[fieldName].filter(d => ids.indexOf(d.id) > -1) : [];
      return result.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
    },
    getHeader(type) {
      let header = [];
      if(type === 'User')
      {
        this.schemas[type].forEach((x) => {
          if (
            x.name === "email" || 
            x.name === "artistsName" || 
            x.name === "dateSubscribed" ||
            x.name === "userType"
          ) {
            header.push({ text: x.name, value: x.name });
          }
        });
      }
      else
      {
        this.schemas[type].forEach((x, index) => {
          if (
            x.name !== "id" &&
            x.type != "List" &&
            x.type != "TextArea" &&
            index < 5
          ) {
            header.push({ text: x.name, value: x.name });
          }
        });

      }
      header.push({ text: "", value: "action" });
      return header;
      //console.log("returnValue" + JSON.stringify(this.schemas[type]));
    },
    onFileChange(propName) {
      console.log("onFileChange" + propName);
      let file = this.fileRefs[propName];
      let reader = new FileReader();
      // let formData = this.formData;

      reader.onload = async function() {
        const id = await this.getObjectId();
        console.log(id);
        const ext = await this.extractFileExtension(file.name);
        if (!this.formData[propName]) this.formData[propName] = [];

        this.formData[propName].push({
          id: id,
          url: reader.result,
          file: file,
          name: file.name,
          ext: ext,
          isprotected: propName.includes("Protected")
        });
        // this.$set(this.formData, "fu", Math.random());
        this.$forceUpdate();
      }.bind(this);

      if (file) reader.readAsDataURL(file);
    },
    openFileInput(id) {
      console.log("openfileinput : " + id);
      document.getElementById("fileInput" + id).click();
    },
    removeFile(propName, id) {
      this.formData[propName] = this.formData[propName].filter(
        i => i.id !== id
      );
      this.$forceUpdate();
      // this.$set(this.formData, "fu", Math.random());
    },
    getFileUrl(fileEntity) {
      const uri = fileEntity.isprotected ? 'pres/' : 'res/'; 
      if (!fileEntity.url) {
        return (
          this.ac.apiBaseURL +
          uri +
          this.namespace +
          "/" +
          this.formData.id +
          "/" +
          fileEntity.id +
          fileEntity.ext
        );
      } else {
        return fileEntity.url;
      }
    },
    getvModel(name) {
      return this.formData[name];
    }
  }
};
</script>