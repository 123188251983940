import axios from 'axios';

const FETCH_RELEASE_PENDING = 'FETCH_RELEASE_PENDING';
const FETCH_RELEASE_FULFILLED = 'FETCH_RELEASE_FULFILLED';
const FETCH_RELEASE_ERROR = 'FETCH_RELEASE_ERROR';


const state = {

    fetchReleasePending: false,
    updateReleasePending: false,
    release: {}
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_RELEASE_PENDING](state) { state.fetchReleasePending = true; },
    [FETCH_RELEASE_FULFILLED](state, release) {
        state.release = release;
        state.fetchReleasePending = false;
    },
    [FETCH_RELEASE_ERROR](state) { state.fetchReleasePending = false; },


};
const actions = {
    async fetchRelease({  dispatch,commit },args) {
        commit(FETCH_RELEASE_PENDING);
        console.log("FETCH_RELEASE");
        try {
            const url = 'release';

            const postdata = {
                id: args.id,
            }

            const response = await axios.post(url,postdata);

            
            if (response.data.status[0] === "OK") {
                commit(FETCH_RELEASE_FULFILLED, response.data.release);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
   
};



export default {
    state,
    mutations,
    actions,
    namespaced: true,
};