<template>
  <v-container class="pa-0">
    <v-form class="ma-4">
      <v-row no-gutters dense>
        <v-col>
          <div class="glass2 mb-2">
            Demo Submissions
            <br />
          </div>
          <div
            class="mb-4 ml-1 body-2 font-weight-light"
          >If you think you have what it takes to make it on Just Buzz Bass Recordings, please feel free to submit us a demo.</div>
          <v-form ref="signUpForm">
            <v-file-input
              v-model="file"
              show-size
              truncate-length="18"
              clearable
              append-icon="mdi-music-note-plus"
              outlined
              prepend-icon
              dense
              label="click to add mp3"
            ></v-file-input>
            <v-textarea
              label="Notes"
              v-model="notes"
              outlined
              color="accent"
              autocomplete="off"
              dense
              rows="2"
            ></v-textarea>
          </v-form>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col align="right">
          <v-btn @click="submitDemoAction">
            Send
            <v-icon right>mdi-send-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters dense class="ma-4 pb-0">
      <v-col class="glass3">Your Submissions</v-col>
    </v-row>
    <div v-for="(sub,i) in submissions" :key="sub.id" class="pb-8">
      <v-row no-gutters>
        <v-card class="" dense>
          <v-card-text class="pb-2 pt-1 ">
            <v-row dense class="pb-0 pt-0">
              <v-col
                class="flex-grow-1 flex-shrink-0"
              >{{i + 1 }}. {{sub.trackName.replace(".mp3","")}}</v-col>
              <v-col class="pa-4 pb-0 pt-0 flex-grow-0 flex-shrink-1 d-flex align-center">
                <v-chip x-small class="m-0" :color="getColor(sub.accepted)" >
                  Status: {{sub.accepted == undefined ? 'In Review' : sub.accepted ? 'Accepted' : 'Declined'}}</v-chip>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="pb-0 pt-0 caption"></v-card-text>
          <v-card-text class="pt-0 pb-0">
            <AudioPlayer
              :heightRatio="$vuetify.breakpoint.smAndDown ? .25 :0.16"
              :ref="'audio'+sub.id"
              :name="sub.trackName"
              :id="sub.id"
              :audioUrl="ac.apiBaseURL +'pres/submissions/' + sub.userId +'/' +sub.trackId +'.mp3'"
              :waveFormUrl="ac.apiBaseURL +'pres/submissions/' + sub.userId +'/' +sub.trackId + '.png'"
              :waveFormUrl2="ac.apiBaseURL +'pres/submissions/' + sub.userId +'/' +sub.trackId + '2.png'"
              @play="stopOthers(sub.id);"
            />
            <v-sheet class="pl-2">
              <v-row dense class="pb-0 pt-0">
                <v-col class="flex-grow-1 flex-shrink-0 caption">
                  Date Submitted: {{ moment(sub.dateSubmitted).format('lll')}}
                  <br />
                  <div v-if="sub.notes">{{sub.notes ? 'My Notes:':''}} {{sub.notes}}</div>
                  <div v-if="sub.reason">
                  <v-divider class="mt-2 mb-1"></v-divider>
                    {{ sub.reason}}
                    </div>
                </v-col>
                <v-col class="pa-4 pb-0 pt-0 flex-grow-0 flex-shrink-1 d-flex align-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark v-bind="attrs" v-on="on" icon>
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="doAction('notes',sub)">
                        <v-list-item-title>Edit Notes</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="doAction('delete',sub)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-sheet>
          </v-card-text>
          <v-card-text class="pb-0 pt-0 caption"></v-card-text>
        </v-card>
      </v-row>
    </div>
    <v-overlay v-model="waitOverlay" z-index="1000" :opacity=".9">
      <v-row align="center" justify="center" class="fill-height">
        <v-col align="center" class="accent--text" cols="12">
          <v-progress-circular
            :size="220"
            :width="8"
            indeterminate
            color="accent"
          >Processing your upload...</v-progress-circular>
        </v-col>
      </v-row>
    </v-overlay>
    <v-dialog
      v-model="notesDialog"
      dark
      :max-width="600"
      name="feedback"
      :overlay-opacity="0.9"
      overlay-color="#222222"
    >
      <v-card>
        <v-card-title class="font-weight-thin">Set notes for {{dialogItem.trackName}}</v-card-title>
        <v-card-text>
          <v-textarea color="accent" label="Reason" outlined v-model="dialogItem.notes"></v-textarea>

          <v-row no-gutters>
            <v-col align="right">
              <v-btn
                small
                class="mr-2"
                v-if="dialogItem.reason"
                @click="dialogItem.notes= '';doAction('submitreason',dialogItem);notesDialog = false"
              >
                Clear
                <v-icon right>mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn small class="mr-2" @click="notesDialog = false">
                Cancel
                <v-icon right>mdi-close-outline</v-icon>
              </v-btn>
              <v-btn small @click="doAction('submitnotes',dialogItem);notesDialog = false">
                Save
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";
import AudioPlayer from "@/components/AudioPlayer.vue";
import moment from "moment";

const {
  mapActions: mapActionsSubmissions,
  mapState: mapStateSubmissions
} = createNamespacedHelpers("submissions");

export default {
  name: "SignIn",
  mixins: [defaultMixin],
  components: {
    AudioPlayer
  },
  data() {
    return {
      show: false,
      notes: "",
      file: null,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match"
      },
      waitOverlay: false,
      notesDialog: false,
      dialogItem: {}
    };
  },
  async created() {
    await this.fetchSubmissions();
  },
  computed: {
    ...mapStateSubmissions(["submissions"])
  },
  methods: {
    ...mapActionsSubmissions(["fetchSubmissions", "updateSubmissions"]),
    getColor(inp) {
      return inp == undefined ? "orange" : inp ? "green" : "red";
    },

    async doAction(action, sub) {
      console.log("setState " + action + " " + sub.id);

      if (action === "delete") {
        await this.updateSubmissions({
          command: "DELETE",
          submission: sub
        });

        await this.fetchSubmissions();
      }

      if (action === "notes") {
        this.notesDialog = true;
        this.dialogItem = sub;
      }

      if (action === "submitnotes") {
        await this.updateSubmissions({
          command: "UPDATE",
          submission: sub
        });

        await this.fetchSubmissions();
      }
    },

    async submitDemoAction() {
      console.log("Submitting File");
      this.waitOverlay = true;

      console.log("Submitting File" + this.file.name + " " + this.notes);

      await this.uploadSubmission(
        this.file,
        "submission/",
        this.file.name,
        false,
        this.notes
      );

      await this.fetchSubmissions();
      this.waitOverlay = false;
    },
    stopOthers(id) {
      console.log("stop except : " + id);
      let thisId = id;

      this.submissions.forEach(sub => {
        if (sub.id != thisId) {
          this.$refs["audio" + sub.id][0].stop();
        }
      });
    }
  }
};
</script>