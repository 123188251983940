<template>
  <v-container class>
    <v-row no-gutters>
      <v-col>
        <v-btn :to="{ path: $store.state.route.from.fullPath }" class="mr-2" icon text depressed exact>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col align="right">
        <div class="font-weight-thin display-1" v-if="!isnew">
          EDIT MIX TAPE
          <br />
        </div>
        <div class="font-weight-thin display-1" v-if="isnew">
          NEW MIX TAPE
          <br />
        </div>
      </v-col>
    </v-row>
    <DynamicFormComponent
      name="mixtape"
      schemaName="MixTape"
      :formData="mixtapeData"
      namespace="mixtapes"
      :ac="ac"
      :getObjectId="getObjectId"
      :extractFileExtension="extractFileExtension"
      :refObjects="refObjects"
    />
    <v-row justify="end" class="pa-2">
      <v-btn :to="{name:'admmixtapes'}" class="ma-1">
        Cancel
        <v-icon right>mdi-close-circle</v-icon>
      </v-btn>
      <v-btn @click="dialog=true" v-if="$route.params.id" class="ma-1">
        Delete
        <v-icon right>mdi-trash-can</v-icon>
      </v-btn>
      <v-btn @click="saveMixTape(false)" class="ma-1">
        Save
        <v-icon right>mdi-content-save</v-icon>
      </v-btn>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>
        <v-card-text>MixTape {{mixtapeData.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog=false">Cancel</v-btn>
          <v-btn color="accent" text @click="saveMixTape(true);dialog=false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import defaultMixin from "@/mixins/defaultMixin";
import { createNamespacedHelpers } from "vuex";

import { SchemasConst } from "@/schemas";

import DynamicFormComponent from "@/components/DynamicFormComponent.vue";

const {
  mapActions: mapActionsMixTapes,
  mapGetters: mapGettersMixTapes
} = createNamespacedHelpers("mixtapes");

const { mapActions: mapActionsDialog } = createNamespacedHelpers("dialog");

export default {
  name: "AdmMixTape",
  mixins: [defaultMixin],
  dialog: false,
  components: {
    DynamicFormComponent
  },
  data: () => ({
    mixtapeData: {},
    dialog: false,
    isnew: false,
    rules: [
      value =>
        !value || value.size < 2000000 || "Flyer size should be less than 2 MB!"
    ],
    schemaMixTape: SchemasConst.MixTape,
    refObjects: {}
  }),
  async created() {
    await this.fetchMixTapes();
    if (this.$route.params.id) {
      const ev = await this.getMixTapeById(this.$route.params.id);
      if (ev === undefined) {
        this.mixtapeData.id = this.$route.params.id;
        this.isnew = true;
      } else {
        this.mixtapeData = this.getMixTapeById(this.$route.params.id);
      }
    }
  },
  computed: {
    ...mapGettersMixTapes(["getMixTapeById"])
  },
  methods: {
    ...mapActionsDialog(["setMessage"]),
    ...mapActionsMixTapes(["fetchMixTapes", "updateMixTape"]),
    getvModel(name) {
      return this.mixtapeData[name];
    },
    uploadFiles() {
      this.schemaMixTape.forEach(field => {
        if (field.itemType === "files") {
          if (this.mixtapeData[field.name]) {
            this.mixtapeData[field.name].forEach(file => {
              if (file.url) {
                this.uploadFile(
                  file.file,
                  "mixtapes/" + this.$route.params.id,
                  file.id + file.ext
                );
              }
            });
          }
        }
      });
    },
    async saveMixTape(deleteMixTape) {
      console.log("saveMixTape");
      let cmd = this.isnew ? "CREATE" : "UPDATE";
      cmd = deleteMixTape ? "DELETE" : cmd;

      const success = await this.updateMixTape({
        mixtape: this.mixtapeData,
        cmd: cmd
      });
      console.log(success);
      if (success) {
        this.uploadFiles();
        this.$router.push({ name: "admmixtapes" });
      }
    }
  }
};
</script>