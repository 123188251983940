import axios from 'axios';
import { appconfig } from '@/config';


const FETCH_TRACKS_PENDING = 'FETCH_TRACKS_PENDING';
const FETCH_TRACKS_FULFILLED = 'FETCH_TRACKS_FULFILLED';
const FETCH_TRACKS_ERROR = 'FETCH_TRACKS_ERROR';

const UPDATE_TRACK_PENDING = 'UPDATE_TRACK_PENDING';
const UPDATE_TRACK_FULFILLED = 'UPDATE_TRACK_FULFILLED';
const UPDATE_TRACK_ERROR = 'UPDATE_TRACK_ERROR';


const state = {

    fetchTracksPending: false,
    updateTrackPending: false,
    tracks: []
};

const mutations = {
    // process data through commit
    // CHANGE_LANGUAGE
    [FETCH_TRACKS_PENDING](state) { state.fetchTracksPending = true; },
    [FETCH_TRACKS_FULFILLED](state, tracks) {
        state.tracks = tracks;
        state.fetchTracksPending = false;
    },
    [FETCH_TRACKS_ERROR](state) { state.fetchTracksPending = false; },

    [UPDATE_TRACK_PENDING](state) { state.updateTrackPending = true; },
    [UPDATE_TRACK_FULFILLED](state) { state.updateTrackPending = false; },
    [UPDATE_TRACK_ERROR](state) { state.updateTrackPending = false; },

};
const actions = {
   async updateTrack({ commit, dispatch }, args) {
        commit(UPDATE_TRACK_PENDING);

        console.log("UPDATE_TRACK " );
        try {
            const url = 'tracks';

            const postdata = {
                apiCommand: args.cmd,
                track: args.track
            }
            const response = await axios.post(url, appconfig.serializeOmitFiles(postdata));

            if (response.data.status[0] === "OK") {
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            if (error.message.indexOf("403") > -1) {
                commit('user/SIGN_OUT_FULFILLED', null, {
                    root: true
                });
            }

            return true;
        }
    },
    async fetchTracks({ commit, dispatch }) {
        commit(FETCH_TRACKS_PENDING);
        // eslint-disable-next-line
        console.log("FETCH_TRACKS");
        try {
            const url = 'tracks';
            const response = await axios.get(url);

            if (response.data.status[0] === "OK") {
                commit(FETCH_TRACKS_FULFILLED, response.data.tracks);
                return true;
            }
        } catch (error) {
            dispatch('dialog/setMessage', { message: error, dialog: true, type: 'error' }, {
                root: true
            });

            return false;
        }
    },
};

const getters = {
    getTrackById: (state) => (id) => {
        return state.tracks.find(track => track.id === id)
    },
    getLastTrack: (state) => {
        if(state.tracks.length == 0)
        {
            return null;
        }else{

            return state.tracks[state.tracks.length -1]
        }
    }
}

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};